<template>
  <div
    class="expandable-image"
    :class="
      expanded
        ? 'expandable-image--expanded'
        : isShowToggleText
        ? 'mb-2'
        : undefined
    "
    :style="`height: ${expanded ? 'initial' : height}`"
  >
    <div class="expandable-image__body">
      <slot />
    </div>
    <div
      v-if="isShowToggleText"
      class="expandable-image__footer"
      @click="handleExpand"
    >
      <div class="expandable-image__text">
        {{
          expanded
            ? $t('CONVERSATION.HIDE_FULL_IMAGE')
            : $t('CONVERSATION.SHOW_FULL_IMAGE')
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '300px',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isShowToggleText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: this.isExpanded,
    };
  },
  methods: {
    handleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.expandable-image {
  @apply relative rounded-md overflow-hidden;

  &__footer {
    @apply absolute w-full py-3 text-center bottom-0 left-0 cursor-pointer flex justify-center;
    background-image: linear-gradient(-180deg, transparent 10%, #151718 130%);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);

    &:hover {
      .expandable-image__text {
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &__text {
    @apply text-primary dark:text-primary-dark text-xs px-2 py-1 transition-all ease-in-out duration-200 inline-block rounded-md select-none;
  }

  &--expanded {
    @apply mb-0;

    .expandable-image__footer {
      @apply bottom-2;
    }
  }

  // hide gradient overlay inside image
  .image {
    &:before {
      display: none;
    }
  }
}
</style>
