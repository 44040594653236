var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expandable-image",class:_vm.expanded
      ? 'expandable-image--expanded'
      : _vm.isShowToggleText
      ? 'mb-2'
      : undefined,style:(`height: ${_vm.expanded ? 'initial' : _vm.height}`)},[_c('div',{staticClass:"expandable-image__body"},[_vm._t("default")],2),_vm._v(" "),(_vm.isShowToggleText)?_c('div',{staticClass:"expandable-image__footer",on:{"click":_vm.handleExpand}},[_c('div',{staticClass:"expandable-image__text"},[_vm._v("\n      "+_vm._s(_vm.expanded
          ? _vm.$t('CONVERSATION.HIDE_FULL_IMAGE')
          : _vm.$t('CONVERSATION.SHOW_FULL_IMAGE'))+"\n    ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }