<template>
  <div>
    <label>
      {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.URL_BASE') }}

      <input v-model="urlBase" type="text" :placeholder="$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.URL_BASE_PLACEHOLDER')" />
    </label>

    <label>
      {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.USERNAME') }}

      <input v-model="username" type="text" :placeholder="$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.USERNAME_PLACEHOLDER')" />
    </label>

    <label>
      {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.API_KEY') }}

      <input v-model="apiKey" type="password" />
    </label>

    <div v-if="status && status.stage" class="mb-4">
      <div class="mt-3 border border-solid border-slate-200 dark:border-slate-600 rounded-md bg-white dark:bg-slate-900 p-3">
        <div class="flex">
          <img src="~dashboard/assets/images/cloud-download.svg" />

          <div class="ms-2">
            <h6 class="dark:text-slate-100 mb-0">{{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.STATUS_HEADING') }}</h6>

            <div v-if="status.stage == 'finished'">
              <div class="text-green-500 dark:text-green-500">
                {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.FINISHED') }}
              </div>
            </div>

            <div v-else-if="status.stage === 'error'">
              <div class="text-red-500 dark:text-red-500">
                {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.ERROR') }}
              </div>
            </div>

            <div v-else-if="status.stage === 'started'">
              <div class="text-slate-400 dark:text-slate-300">
                {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.STARTED') }}
              </div>
            </div>

            <div v-else class="text-slate-400 dark:text-slate-300">
              {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.STATUS_MESSAGE', { stage: currentStageName, n: currentStageNumRecords }) }}
            </div>

          </div>
        </div>

        <div v-if="showProgressBar" class="mt-3 bg-slate-300 h-2 rounded overflow-hidden">
          <div class="bg-woot-300 h-full" :style="{ width: progressPercentage + '%' }"></div>
        </div>
      </div>
    </div>

    <div class="flex content-center">
      <woot-button @click.prevent="startImport()" :disabled="importInProgress">
        {{ $t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.IMPORT') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import GorgiasImporterAPI from '../../../../api/gorgias_importer';
import AlertMixin from 'shared/mixins/alertMixin';

const GORGIAS_IMPORT_STATUS_UPDATED = 'gorgias.import.status_updated';

export default {
  mixins: [AlertMixin],
  data() {
    return {
      username: '',
      apiKey: '',
      urlBase: '',
      status: null,
    };
  },
  computed: {
    currentStageName() {
      return this.status.stage;
    },
    currentStageNumRecords() {
      return this.status['num_records'];
    },
    showProgressBar() {
      return this.status && !['error', 'finished'].includes(this.status.stage);
    },
    progressPercentage() {
      return {
        started: 0,
        labels: 1,
        contacts: 5,
        users: 20,
        conversations: 40,
        messages: 75,
        postprocessing: 90,
        finished: 100,
      }[this.status.stage] || 0;
    },
    importInProgress() {
      return this.status && this.status.stage && !['error', 'finished'].includes(this.status.stage);
    },
  },
  async mounted() {
    bus.$on(GORGIAS_IMPORT_STATUS_UPDATED, (status) => this.status = status);

    try {
      const response = await GorgiasImporterAPI.getStatus();
      if (response.status === 200) this.status = response.data;
    } catch(e) {
      this.status = null;
    }
  },
  methods: {
    async startImport() {
      try {
        const response = await GorgiasImporterAPI.startImport({
          username: this.username,
          api_key: this.apiKey,
          url_base: this.urlBase,
        });

        if (response.status === 201) this.status = response.data;
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.GORGIAS_IMPORTER.UNAUTHORIZED'));
        this.status = { stage: 'error' };
      }
    },
  },
};
</script>
