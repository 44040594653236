var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-0 mr-0 flex pt-0 pr-4 pb-4 pl-0",class:{
    'pt-4 border-b border-solid border-slate-50 dark:border-slate-700/30':
      _vm.showBorder,
  }},[(_vm.hasTitleOrSubtitle)?_c('div',{staticClass:"w-[30%] min-w-0 max-w-[30%] pr-12"},[(_vm.title)?_c('p',{staticClass:"text-base text-woot-500 dark:text-woot-500 mb-0 font-medium"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-sm mb-2"},[(_vm.subTitle)?_vm._t("subTitle",function(){return [_vm._v("\n        "+_vm._s(_vm.subTitle)+"\n      ")]}):_vm._e()],2),_vm._v(" "),(_vm.note)?_c('p',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.NOTE')))]),_vm._v("\n      "+_vm._s(_vm.note)+"\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{class:{
      'w-[80%] min-w-0 max-w-[80%]': _vm.hasTitleOrSubtitle,
      'w-full': !_vm.hasTitleOrSubtitle,
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }