import { frontendURL } from '../../../../helper/URLHelper';

const reports = accountId => ({
  parentNav: 'ai',
  routes: [
    'ai_home',
    'ai_intents',
    'ai_training',
    'ai_intents_new',
    'ai_intents_edit',
  ],
  menuItems: [
    {
      icon: 'ai-intents',
      label: 'INTENT.TITLE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/ai/intents`),
      toStateName: 'ai_intents',
      viewBox: '0 -1 20 23',
      forceLightColor: true,
    },
    {
      icon: 'ai-training',
      label: 'TRAINING_GROUND.TITLE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/ai/training`),
      toStateName: 'ai_training',
      viewBox: '0 -1 17 17',
      forceLightColor: true,
      isDisabled: true, // temporary disabled training ground page
    },
  ],
});

export default reports;
