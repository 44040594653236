import moment from 'moment';

const capitalizeName = name => {
  return (name || '').replace(/\b(\w)/g, s => s.toUpperCase());
};

const getFirstName = ({ user }) => {
  const firstName = user?.name ? user.name.split(' ').shift() : '';
  return capitalizeName(firstName);
};

const getLastName = ({ user }) => {
  if (user && user.name) {
    const lastName =
      user.name.split(' ').length > 1 ? user.name.split(' ').pop() : '';
    return capitalizeName(lastName);
  }
  return '';
};

const orderDate = order => {
  if (order?.created_at) {
    return moment(order.created_at).format('DD.MM.YYYY HH:mm');
  }
};

const orderShippingAddress = order => {
  if (order?.shipping_address) {
    return [
      order.shipping_address.address1,
      order.shipping_address.address2,
      order.shipping_address.city,
      order.shipping_address.zip,
      order.shipping_address.country,
    ]
      .filter(n => n)
      .join(', ');
  }
};

const orderTrackingNumber = order => {
  if (order?.fulfillments?.slice(-1)) {
    return order.fulfillments.slice(-1).tracking_number;
  }
};

const orderTrackingUrl = order => {
  if (order?.fulfillments?.slice(-1)) {
    return order.fulfillments.slice(-1).tracking_number;
  }
};

const orderShippingDate = order => {
  if (order?.fulfillments?.slice(-1)) {
    return order.fulfillments.slice(-1)?.created_at;
  }
};

export const getMessageVariables = ({ conversation }) => {
  if (!conversation?.shopify_data) {
    return null;
  }

  const {
    meta: { assignee, sender },
    id,
    shopify_data: { orders },
  } = conversation;
  const last_order = orders[0];

  return {
    'contact.name': capitalizeName(sender?.name || ''),
    'contact.first_name': getFirstName({ user: sender }),
    'contact.last_name': getLastName({ user: sender }),
    'contact.email': sender?.email,
    'contact.phone': sender?.phone_number,
    'contact.id': sender?.id,
    'conversation.id': id,
    'agent.name': capitalizeName(assignee?.name || ''),
    'agent.first_name': getFirstName({ user: assignee }),
    'agent.last_name': getLastName({ user: assignee }),
    'agent.email': assignee?.email ?? '',
    'order.id': last_order?.id ?? '',
    'order.name': last_order?.name ?? '',
    'order.created_at': orderDate(last_order),
    'order.tracking_url': orderTrackingUrl(last_order),
    'order.tracking_number': orderTrackingNumber(last_order),
    'order.delivery_status': last_order?.fulfillment_status ?? '',
    'order.status_url': last_order?.order_status_url ?? '',
    'order.shipping_date': orderShippingDate(last_order),
    'order.country': last_order?.shipping_address?.country ?? '',
    'order.shipping_address': orderShippingAddress(last_order),
  };
};
