var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 overflow-auto p-4"},[_c('div',{staticClass:"flex flex-row gap-4"},[_c('div',{staticClass:"w-[60%]"},[(!_vm.inboxesList.length)?_c('p',{staticClass:"flex h-full items-center flex-col justify-center"},[_vm._v("\n        "+_vm._s(_vm.$t('INBOX_MGMT.LIST.404'))+"\n        "),(_vm.isAdmin)?_c('router-link',{attrs:{"to":_vm.addAccountScoping('settings/inboxes/new')}},[_vm._v("\n          "+_vm._s(_vm.$t('SETTINGS.INBOXES.NEW_INBOX'))+"\n        ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.inboxesList.length)?_c('table',{staticClass:"woot-table"},[_c('tbody',_vm._l((_vm.inboxesList),function(item){return _c('tr',{key:item.id},[_c('td',[(item.avatar_url)?_c('img',{staticClass:"woot-thumbnail",attrs:{"src":item.avatar_url,"alt":"No Page Image"}}):_c('img',{staticClass:"woot-thumbnail",attrs:{"src":require("dashboard/assets/images/flag.svg"),"alt":"No Page Image"}})]),_vm._v(" "),_c('td',[_c('span',{staticClass:"inbox-name"},[_vm._v(_vm._s(item.name))]),_vm._v(" "),(item.channel_type === 'Channel::FacebookPage')?_c('span',[_vm._v("\n                Facebook\n              ")]):_vm._e(),_vm._v(" "),(
                  item.channel_type === 'Channel::WebWidget' &&
                  item.shopify_widget
                )?_c('span',[_vm._v("\n                Shopify\n              ")]):_vm._e(),_vm._v(" "),(
                  item.channel_type === 'Channel::WebWidget' &&
                  !item.shopify_widget
                )?_c('span',[_vm._v("\n                Website\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::TwitterProfile')?_c('span',[_vm._v("\n                Twitter\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::TwilioSms')?_c('span',[_vm._v("\n                "+_vm._s(_vm.twilioChannelName(item))+"\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Whatsapp')?_c('span',[_vm._v("\n                Whatsapp\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Sms')?_c('span',[_vm._v(" Sms ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Email')?_c('span',[_vm._v("\n                Email\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Telegram')?_c('span',[_vm._v("\n                Telegram\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Line')?_c('span',[_vm._v("Line")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Api')?_c('span',[_vm._v("\n                "+_vm._s(_vm.globalConfig.apiChannelName || 'API')+"\n              ")]):_vm._e(),_vm._v(" "),(item.channel_type === 'Channel::Imported')?_c('span',[_vm._v("\n                Imported\n              ")]):_vm._e()]),_vm._v(" "),_c('td',[(item.provider_config)?[(
                    _vm.validateObjectProp(
                      item.provider_config,
                      'dns_process_step'
                    )
                  )?[(
                      item.provider_config.dns_process_step.flow_type ===
                      'simple'
                    )?[(
                        _vm.validateObjectProp(
                          item.provider_config,
                          'postmark_sender'
                        )
                      )?[(item.provider_config.postmark_sender.confirmed)?[_c('router-link',{attrs:{"to":_vm.addAccountScoping(`settings/inboxes/${item.id}`)}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)]:[_c('div',{staticClass:"mb-2"},[_c('capsule',{attrs:{"variant":"tertiary","text":"Unfinished"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fluent-icon',{attrs:{"icon":"warning","type":"solid","size":"14","view-box":"0 0 16 16"}})]},proxy:true}],null,true)})],1),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.addAccountScoping(
                              `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                            )}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)]]:[_c('div',{staticClass:"mb-2 mt-1"},[_c('capsule',{attrs:{"variant":"tertiary","text":"Unfinished"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fluent-icon',{attrs:{"icon":"warning","type":"solid","size":"14","view-box":"0 0 16 16"}})]},proxy:true}],null,true)})],1),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.addAccountScoping(
                            `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                          )}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)]]:[(
                        _vm.validateObjectProp(
                          item.provider_config,
                          'postmark_sender'
                        )
                      )?[(
                          item.provider_config.postmark_sender.confirmed &&
                          item.provider_config.postmark_domain
                            .dkim_verified &&
                          item.provider_config.postmark_domain
                            .return_path_verified
                        )?[_c('router-link',{attrs:{"to":_vm.addAccountScoping(`settings/inboxes/${item.id}`)}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)]:[_c('div',{staticClass:"mb-2"},[_c('capsule',{attrs:{"variant":"tertiary","text":"Unfinished"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fluent-icon',{attrs:{"icon":"warning","type":"solid","size":"14","view-box":"0 0 16 16"}})]},proxy:true}],null,true)})],1),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.addAccountScoping(
                              `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                            )}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)]]:[_c('div',{staticClass:"mb-2 mt-1"},[_c('capsule',{attrs:{"variant":"tertiary","text":"Unfinished"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('fluent-icon',{attrs:{"icon":"warning","type":"solid","size":"14","view-box":"0 0 16 16"}})]},proxy:true}],null,true)})],1),_vm._v(" "),_c('router-link',{attrs:{"to":_vm.addAccountScoping(
                            `settings/inboxes/new/${item.id}/auth_email_provider/?last_step=${item.provider_config.dns_process_step.last_step}&flow_type=${item.provider_config.dns_process_step.flow_type}`
                          )}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)]]]:_vm._e()]:[_c('router-link',{attrs:{"to":_vm.addAccountScoping(`settings/inboxes/${item.id}`)}},[(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.SETTINGS')),expression:"$t('INBOX_MGMT.SETTINGS')",modifiers:{"top":true}}],attrs:{"variant":"smooth","size":"tiny","icon":"settings","color-scheme":"secondary","class-names":"grey-btn"}}):_vm._e()],1)],_vm._v(" "),(_vm.isAdmin)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INBOX_MGMT.DELETE.BUTTON_TEXT')),expression:"$t('INBOX_MGMT.DELETE.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","color-scheme":"alert","size":"tiny","class-names":"grey-btn","is-loading":_vm.loading[item.id],"icon":"dismiss-circle"},on:{"click":function($event){return _vm.openDelete(item)}}}):_vm._e()],2)])}),0)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"w-[34%]"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
          _vm.useInstallationName(
            _vm.$t('INBOX_MGMT.SIDEBAR_TXT'),
            _vm.globalConfig.installationName
          )
        ),expression:"\n          useInstallationName(\n            $t('INBOX_MGMT.SIDEBAR_TXT'),\n            globalConfig.installationName\n          )\n        "}]})])]),_vm._v(" "),(_vm.showSettings)?_c('settings',{attrs:{"show":_vm.showSettings,"on-close":_vm.closeSettings,"inbox":_vm.selectedInbox},on:{"update:show":function($event){_vm.showSettings=$event}}}):_vm._e(),_vm._v(" "),(_vm.showDeletePopup)?_c('woot-confirm-delete-modal',{attrs:{"show":_vm.showDeletePopup,"title":_vm.$t('INBOX_MGMT.DELETE.CONFIRM.TITLE'),"message":_vm.confirmDeleteMessage,"confirm-text":_vm.deleteConfirmText,"reject-text":_vm.deleteRejectText,"confirm-value":_vm.selectedInbox.name,"confirm-place-holder-text":_vm.confirmPlaceHolderText},on:{"update:show":function($event){_vm.showDeletePopup=$event},"on-confirm":_vm.confirmDeletion,"on-close":_vm.closeDelete}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }