import types from 'dashboard/store/mutation-types';
import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';

export const state = {
  colorScheme: LocalStorage.get(LOCAL_STORAGE_KEYS.COLOR_SCHEME) || 'auto',
};

export const getters = {
  getColorScheme(_state) {
    return _state.colorScheme;
  },
};

export const actions = {
  setColorScheme({ commit }, scheme) {
    LocalStorage.set(LOCAL_STORAGE_KEYS.COLOR_SCHEME, scheme);
    commit(types.SET_COLOR_SCHEME, scheme);
  },
};

export const mutations = {
  [types.SET_COLOR_SCHEME](_state, scheme) {
    _state.colorScheme = scheme;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
