<template>
  <div
    class="text-sm bg-white dark:bg-[#151718] border m-0 border-[#E4E3E6] dark:border-[#2F3336] overflow-hidden"
    :class="stacked ? 'rounded-none border-x-0 border-b-0' : 'rounded-lg mb-3'"
  >
    <button class="shopifyAccordion" @click="$emit('click')">
      <div class="flex justify-between mb-0.5">
        <div
          class="flex items-center text-primary text-sm dark:text-stategrey mb-0 py-0 pr-2 pl-0"
        >
          <div class="mr-3" v-tooltip.bottom-start="`Click to copy`" @click.stop="onCopy(title.substr(1))">
            {{ title }}
          </div>
          <div v-if="!isOpen">
            <div class="tag-wrapper">
              <div v-if="fulfillmentStatus !== null" class="tag-wrapper__item">
                <div
                  class="tag-wrapper__label"
                  :class="
                    fulfillmentStatus === 'unfulfilled'
                      ? 'tag-wrapper__label--warning'
                      : 'tag-wrapper__label--primary'
                  "
                >
                  {{ fulfillmentStatus }}
                </div>
              </div>

              <div v-if="cancelled" class="tag-wrapper__item">
                <div class="tag-wrapper__label tag-wrapper__label--warning">
                  {{ $t('SHOPIFY_PANEL.CANCELLED') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <slot name="button" />
        <div class="flex justify-end w-3 text-primary dark:text-stategrey">
          <fluent-icon
            v-if="isOpen"
            size="16"
            icon="chevron-up-new"
            type="outline"
            view-box="0 0 16 16"
          />
          <fluent-icon
            v-else
            size="16"
            icon="chevron-down-new"
            type="outline"
            view-box="0 0 16 16"
          />
        </div>
      </div>
    </button>
    <div
      v-if="isOpen"
      :class="compact ? 'p-0' : 'p-4'"
      class="border-t border-solid border-[#E4E3E6] dark:border-[#2F3336]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    fulfillmentStatus: {
      type: String,
      default: null,
    },
    paymentStatus: {
      type: String,
      default: null,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    cancelled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onCopy(attributeValue) {
      await copyTextToClipboard(attributeValue);
      this.showAlert('Copied to clipboard successfully');
    },
  },
};
</script>

<style lang="scss" scoped>
.shopifyAccordion {
  @apply flex items-center select-none w-full m-0 justify-between pt-3 pb-2.5 px-4 bg-transparent cursor-pointer hover:bg-neutral-subtle hover:dark:bg-[#25292B] transition-all ease-in-out duration-200 text-sm;
}

.tag-wrapper {
  @apply flex flex-wrap;

  &__item {
    @apply flex items-center mr-2;
  }

  &__label {
    @apply bg-[#E4E3E6] dark:bg-[#4C5155] text-primary dark:text-stategrey text-[13px] pl-2 pr-2 border-0 rounded-[2px] select-none leading-normal;

    &--warning, &--cancelled {
      @apply dark:text-stategrey text-[#746D2D] bg-yellow-100 dark:bg-[#403D20] #{!important};
    }
  }
}
</style>
