<template>
  <div
    class="flex items-center rounded-lg border border-neutral-high dark:border-neutral-dark-high border-1 p-3 mb-2"
  >
    <Icon
      class="handle cursor-grabbing pr-1 mr-3 text-primary dark:text-primary-dark"
      size="20"
      view-box="-2 -2 20 20"
      icon="reorder"
      type="vertical"
      :icons="iconLib"
    />

    <Toggle :value="item.active" @input="toggleActive" />

    <span
      class="ml-2.5 flex-1 text-sm text-primary dark:text-primary-dark"
      :class="{ 'opacity-50': !item.active }"
    >
      {{ title }}
    </span>

    <span
      class="bg-neutral-medium dark:bg-neutral-dark-medium text-secondary dark:text-secondary-dark text-xs font-medium me-2 px-1 py-0.5 rounded-md"
      :class="{ 'opacity-50': !item.active }"
    >
      <span v-if="item.resource_type === 'Article'">{{
        $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.HELP_ARTICLE')
      }}</span>
      <span v-else>Flow</span>
    </span>

    <a
      href="#delete"
      class="text-error hover:text-error-medium transition-all ease-in-out duration-200"
      @click.prevent="destroy"
    >
      <Icon
        class="ml-5"
        size="16"
        icon="delete"
        type="outline"
        :icons="iconLib"
      />
    </a>
  </div>
</template>

<script>
import Toggle from '../../../../../../dashboard/components/ui/Switch.vue';
import Icon from '../../../../../../shared/components/FluentIcon/Icon.vue';
import icons from '../../../../../../shared/components/FluentIcon/icons.json';

export default {
  components: {
    Toggle,
    Icon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    iconLib() {
      return icons;
    },
    title() {
      return this.item.resource_type === 'Article'
        ? this.item.resource.title
        : this.item.resource.name;
    },
  },
  methods: {
    toggleActive() {
      this.$store.dispatch('inboxChatResources/toggleActive', {
        inboxChatResource: this.item,
      });
    },
    destroy() {
      this.$store.dispatch('inboxChatResources/destroy', {
        inboxChatResource: this.item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-button {
  background-color: #90879b;

  &.active {
    background-color: #1bdc74;
  }
}
</style>
