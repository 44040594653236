/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import intents from './intents/intents.route';
import training from './training/training.route';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/ai'),
      name: 'ai_home',
      roles: ['administrator'],
      redirect: () => {
        return frontendURL('accounts/:accountId/ai/intents');
      },
    },
    ...intents.routes,
    ...training.routes,
  ],
};
