export default {
  methods: {
    scrollbarToFollowActiveItem(index) {
      this.$nextTick(() => {
        const element = this.$refs['conversationCard' + index]?.[0];
        if (element) {
          const container = this.$refs.activeConversation;
          const containerHeight = container.clientHeight;
          const elementHeight = element.$el.clientHeight;
          const elementOffsetTop = element.$el.offsetTop;
          const containerScrollTop = container.scrollTop;

          const topMargin = 117 + elementHeight / 2;

          // Calculate the scroll position to keep the active item visible when moving down
          const downScrollPosition =
            elementOffsetTop - containerHeight + elementHeight / 2;

          // Calculate the scroll position to keep the active item visible when moving up
          // Include the top margin
          const upScrollPosition =
            elementOffsetTop - elementHeight / 2 - topMargin;

          let scrollPosition;
          if (containerScrollTop > upScrollPosition) {
            // If scrolling up and the active item is above the visible area, adjust the scroll position
            scrollPosition = upScrollPosition;
          } else if (
            containerScrollTop + containerHeight <
            elementOffsetTop + elementHeight
          ) {
            // If scrolling down and the active item is below the visible area, adjust the scroll position
            scrollPosition = downScrollPosition;
          } else {
            // No need to adjust the scroll position
            scrollPosition = containerScrollTop;
          }

          // Ensure the scroll position is within the container's bounds
          const maxScrollTop = container.scrollHeight - container.clientHeight;
          container.scrollTop = Math.min(
            Math.max(scrollPosition, 0),
            maxScrollTop
          );
        }
      });
    },
  },
};
