/* global axios */
import ApiClient from './ApiClient';

class ReamazeImporterAPI extends ApiClient {
  constructor() {
    super('reamaze_importer', { accountScoped: true });
  }

  startImport(params) {
    return axios.post(`${this.url}`, params);
  }

  getStatus() {
    return axios.get(`${this.url}/status`)
  }
}

export default new ReamazeImporterAPI();
