<template>
  <div class="widget-body-container">
    <template v-if="config.isDefaultScreen">
      <div class="team-avail">
        <div class="team-avail__inner">
          <div class="team-avail__head">
            {{
              config.isOnline
                ? $t('INBOX_MGMT.WIDGET_BUILDER.BODY.TEAM_AVAILABILITY.ONLINE')
                : $t('INBOX_MGMT.WIDGET_BUILDER.BODY.TEAM_AVAILABILITY.OFFLINE')
            }}
          </div>
          <div class="team-avail__msg">
            {{ config.replyTime }}
          </div>
          <div class="team-avail__thumb">
            <grouped-avatars :users="availableAgents" />
          </div>
        </div>
        <button class="team-avail__button" :style="{ color: config.color }">
          <span class="team-avail__button-text">
            {{
              $t(
                'INBOX_MGMT.WIDGET_BUILDER.BODY.TEAM_AVAILABILITY.START_CONVERSATION'
              )
            }}
          </span>
          <fluent-icon icon="arrow-right-new" size="16" view-box="0 0 16 16" />
        </button>
      </div>

      <order-tracking
        class="mt-3"
        contained-in="preview"
        :color="config.color"
      />

      <resource-list contained-in="preview" :color="config.color" />
    </template>

    <template v-else>
      <div class="conversation-content">
        <div class="conversation-wrap">
          <div class="message-wrap">
            <div class="user-message-wrap">
              <div class="user-message">
                <div class="message-wrap">
                  <div
                    class="chat-bubble user"
                    :style="{ background: config.color }"
                  >
                    <p>
                      {{ $t('INBOX_MGMT.WIDGET_BUILDER.BODY.USER_MESSAGE') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="agent-message-wrap">
            <div class="agent-message">
              <div class="avatar-wrap" />
              <div class="message-wrap">
                <div class="chat-bubble agent">
                  <div class="message-content">
                    <p>
                      {{ $t('INBOX_MGMT.WIDGET_BUILDER.BODY.AGENT_MESSAGE') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GroupedAvatars from '../../../../widget/components/GroupedAvatars.vue';
import ResourceList from '../../../../widget/views/ResourceList.vue';
import OrderTracking from '../../../../widget/components/OrderTracking.vue';

export default {
  name: 'WidgetBody',
  components: {
    GroupedAvatars,
    ResourceList,
    OrderTracking,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getStatusText() {
      return this.config.isOnline
        ? this.$t('INBOX_MGMT.WIDGET_BUILDER.BODY.TEAM_AVAILABILITY.ONLINE')
        : this.$t('INBOX_MGMT.WIDGET_BUILDER.BODY.TEAM_AVAILABILITY.OFFLINE');
    },
    getWidgetBodyClass() {
      return {
        'with-chat-view': !this.config.isDefaultScreen,
        'with-heading-or-title':
          this.config.isDefaultScreen &&
          (this.config.welcomeHeading || this.config.welcomeTagline),
        'with-heading-or-title-without-logo':
          this.config.isDefaultScreen &&
          (this.config.welcomeHeading || this.config.welcomeTagline) &&
          !this.config.logo,
        'without-heading-and-title':
          this.config.isDefaultScreen &&
          !this.config.welcomeHeading &&
          !this.config.welcomeTagline,
      };
    },
    availableAgents() {
      return [
        {
          availability_status: 'online',
          avatar_url: '',
          id: 42,
          name: 'John',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.widget-body-container {
  @apply px-3 pt-7;

  .team-avail {
    @apply px-5 py-4 shadow-sm rounded-md bg-neutral;

    &__thumb {
      @apply absolute top-1/2 right-0 translate-y-[-150%];
    }

    &__inner {
      @apply relative;
    }

    &__head {
      @apply text-sm font-semibold text-primary;
    }

    &__msg {
      @apply text-sm mt-1 text-secondary;
    }

    &__button {
      @apply inline-flex text-sm font-semibold rounded-md py-1 mt-2 px-2 -ml-2 leading-6 text-slate-800 justify-between items-center hover:bg-slate-25 transition-all ease-in-out duration-200;
    }
    &__button-text {
      @apply pr-2 text-sm;
    }
  }

  .conversation-content {
    @apply px-2 pb-4;

    .conversation-wrap {
      .user-message {
        @apply items-end flex flex-row justify-end mb-1 ml-auto mt-0 text-right;
      }

      .message-wrap {
        max-width: 100%;

        .chat-bubble {
          @apply rounded-[1.25rem] shadow-medium text-neutral inline-block text-[0.5rem] leading-6 text-left py-[0.8125rem] px-[1.09375rem];

          p {
            @apply m-0;
          }

          &.user {
            @apply rounded-br-[0.1875rem] bg-accent;
          }

          &.agent {
            @apply rounded-bl-[0.1875rem] bg-neutral text-primary;
          }
        }
      }
    }
  }
}
</style>
