import posthog from 'posthog-js';
import { mapGetters } from 'vuex';

export const initializePosthog = function() {
  return posthog.init(window.analyticsConfig?.token, {
    api_host: `https://${window.analyticsConfig?.host}`,
    bootstrap: window.analyticsConfig?.bootstrap,
  });
};

export default {
  install(Vue, { client }) {
    Vue.mixin({
      computed: {
        ...mapGetters({
          $featureFlags: 'getFeatureFlags',
        }),
      },
    });

    Vue.prototype.$initFeatureFlags = function(user, accountId) {
      client.onFeatureFlags(() => {
        const flags = client.featureFlags.getFlagVariants();
        const payloads = client.featureFlags.getFlagPayloads();
        Object.keys(payloads).forEach(key => {
          if (flags[key]) flags[key] = payloads[key];
        });

        this.$store.commit('setFeatureFlags', flags);
      });

      client.identify(user.id, {
        user_id: user.id,
        email: user.email,
        name: user.name,
      });
  
      const account = user.accounts.find(a => a.id === accountId);
      if (account) {
        client.group('account', account.id, {
          id: account.id,
          name: account.name,
        });
      }
  
      return client;
    };
  },
};
