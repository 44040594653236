<template>
  <div></div>
</template>

<script>
import appInstallationMixin from 'dashboard/mixins/appInstallationMixin';
import AccountAPI from 'dashboard/api/account';

export default {
  mixins: [appInstallationMixin],
  methods: {
    async initiateAuth() {
      if (this.authStarted || !this.lastAdministeredAccount?.id) return;

      try {
        this.authStarted = true;
        const response = await AccountAPI.createAircallAuthURL(this.lastAdministeredAccount.id);
        window.location.href = response.data.aircall_auth_url;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
