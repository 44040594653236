<template>
  <div class="onboarding-step">
    <div class="onboarding-step__head">
      <h2 class="onboarding-step__head-title !mt-0">
        <span>Welcome to Commslayer</span>
      </h2>
      <p class="onboarding-step__head-desc !mt-0">
        Let's setup the app to get your inbox going
      </p>
    </div>
    <div class="onboarding-step__status">
      <!-- Progress status -->
      <div class="onboard-progress">
        <div
          class="onboard-progress__body"
          :style="{ width: calculateProgress + '%' }"
        />
      </div>
      <div class="onboard-progress__footer">
        {{ completedSteps }} of {{ steps.length }} completed
      </div>
    </div>

    <!-- Step items -->
    <div v-for="(step, index) in steps" :key="index" class="onboard-step">
      <div
        :class="{
          highlighted: step.done,
          active: !step.done && $route.path === step.link,
        }"
        class="onboard-step__item"
        @click="redirectTo(step.link, step.done)"
      >
        <div class="onboard-step__head">
          <icon
            class="onboard-step__head-icon"
            size="16"
            :icon="step.iconName"
            view-box="0 0 16 16"
            :icons="iconLib"
          />
        </div>
        <div class="onboard-step__body">
          <p class="onboard-step__body-title !mt-0">
            {{ step.name }}
          </p>
          <p class="onboard-step__body-desc !mt-0">
            {{ step.description }}
          </p>
        </div>
        <div
          v-if="step.done"
          class="onboard-step__footer onboard-step__footer--done"
        >
          <fluent-icon
            icon="checkmark-circle"
            type="solid"
            size="20"
            view-box="0 0 16 16"
          />
        </div>

        <div v-else class="onboard-step__footer">
          <fluent-icon
            icon="chevron-right"
            type="outline"
            size="20"
            view-box="0 0 24 24"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/icons.json';

export default {
  name: 'OnboardingSteps',
  components: {
    Icon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
  computed: {
    calculateProgress() {
      const completedSteps = this.steps.filter(step => step.done).length;
      const totalSteps = this.steps.length;
      return (completedSteps / totalSteps) * 100;
    },
    completedSteps() {
      return this.steps.filter(step => step.done).length;
    },
    iconLib() {
      return icons;
    },
  },
  methods: {
    redirectTo(link, status) {
      if (status) {
        return;
      }

      if (this.$route.path !== link) {
        this.$router.push({ path: link });
      }

      this.$root.$emit('onboarding-modal.hide');
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-step {
  &__head {
    @apply mt-2 mb-2;
  }

  &__head-title {
    @apply text-primary dark:text-primary-dark text-2xl font-semibold;
  }

  &__head-desc {
    @apply text-secondary dark:text-secondary-dark text-sm;
  }

  &__status {
    @apply flex items-center my-3 mb-3;
  }
}

.onboard-progress {
  @apply bg-secondary-medium dark:bg-primary-dark rounded h-[10px] relative flex-grow mr-3 overflow-hidden;

  // progress bar active
  &__body {
    @apply bg-accent dark:bg-accent-dark h-full;
  }

  &__footer {
    @apply text-sm text-secondary dark:text-secondary-dark;
  }
}

.onboard-step {
  &__item {
    @apply relative flex items-center rounded-lg p-4 mb-4 cursor-pointer transition duration-200 ease-in-out hover:bg-neutral-subtle dark:hover:bg-primary border border-secondary-low dark:border-secondary-dark-low;

    // items with done status
    &.highlighted {
      @apply border-[1px] border-accent dark:border-accent-dark hover:bg-transparent cursor-default;
    }

    &.active {
      @apply bg-neutral-subtle dark:bg-primary;

      &.highlighted {
        @apply bg-transparent hover:bg-transparent;
      }
    }
  }

  &__head {
    @apply flex flex-row items-start p-[10px] gap-[10px] w-[36px] h-[36px] rounded-[8px] mr-[15px] relative bg-accent-low dark:bg-accent-dark-low;

    &-icon {
      @apply text-accent dark:text-accent-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }

  &__body {
    @apply pr-10;

    &-title {
      @apply font-semibold mb-1 text-primary dark:text-primary-dark;
    }

    &-desc {
      @apply text-sm text-secondary dark:text-secondary-dark;
    }
  }

  &__footer {
    @apply absolute top-1/2 right-0 -translate-y-1/2 mr-3 text-secondary dark:text-secondary-dark;

    // done icon color
    &--done {
      @apply text-accent dark:text-accent-dark;
    }
  }
}
</style>
