import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      authStarted: false,
    };
  },
  computed: {
    ...mapGetters({
      'accounts': 'getUserAccounts',
      'currentUser': 'getCurrentUser',
    }),
    lastAdministeredAccount() {
      const administeredAccounts = this.accounts.filter(account => {
        return account.role === 'administrator';
      });

      if (administeredAccounts.length === 0) return;

      return administeredAccounts.reduce((a, v) => {
        return (moment(v.active_at) || 0) > (moment(administeredAccounts[0].active_at) || 0) ? v : a;
      });
    },
  },
  created() {
    if (this.lastAdministeredAccount?.id) {
      this.initiateAuth();
    } else {
      this.$router.replace('/');
    }
  },
};
