/* global axios */

import ApiClient from './ApiClient';

class Workflows extends ApiClient {
  constructor() {
    super('workflows', { accountScoped: true });
  }
}

export default new Workflows();


