<template>
  <div class="mt-8 w-full mx-auto">
    <table
      v-for="(item, index) in shortcuts"
      :key="index"
      class="shortcut-table"
    >
      <tr>
        <td width="37%">
          <div
            v-for="(key, idx) in item.combination"
            :key="idx"
            class="flex gap-2 items-center justify-end"
          >
            <key-icon
              v-if="key.primary"
              :icon-key="key.primary"
              class="!me-0"
            />
            <key-icon
              v-if="key.secondary"
              :icon-key="key.secondary"
              class="!me-0"
            />
          </div>
        </td>
        <td width="63%">
          <div class="text-sm text-primary dark:text-primary-dark font-medium">
            {{ item.description }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import KeyIcon from 'dashboard/components/KeyIcon';

export default {
  components: {
    KeyIcon,
  },
  props: {
    shortcuts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.shortcut-table {
  @apply p-0 mb-0 w-full;

  td {
    @apply pb-2 pr-2 align-middle text-xs;
  }
}
</style>
