<template>
  <modal
    :show="show"
    class="rounded-lg flex flex-col"
    size=""
    :on-close="onClose"
  >
    <div class="p-4 flex flex-col h-full">
      <h5 class="pb-3 text-primary dark:text-primary-dark">
        {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.HEADER') }}
      </h5>

      <div class="py-6 flex-1">
        <input
          v-model="search"
          type="text"
          :placeholder="$t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.QUICK_SEARCH')"
        />

        <div v-if="itemType === 'article' && recentlyUsedArticles.length > 0">
          <div class="text-lg text-black-600 mb-3">
            {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.RECENTLY_USED') }}
          </div>

          <add-item-modal-article
            v-for="article in recentlyUsedArticles"
            :key="article.id"
            :article="article"
            :selected="articleSelected(article)"
            :disabled="maxItemsReached && !articleSelected(article)"
            @toggleSelected="toggleArticleSelected(article)"
          />
        </div>

        <div
          v-else-if="
            itemType === 'workflow' && recentlyUsedWorkflows.length > 0
          "
        >
          <div class="text-lg text-black-600 mb-3">
            {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.RECENTLY_USED') }}
          </div>

          <add-item-modal-workflow
            v-for="workflow in recentlyUsedWorkflows"
            :key="workflow.id"
            :workflow="workflow"
            :selected="workflowSelected(workflow)"
            :disabled="maxItemsReached && !workflowSelected(workflow)"
            @toggleSelected="toggleWorkflowSelected(workflow)"
          />
        </div>

        <div>
          <a
            href="#workflows"
            class="text-sm text-blue-400 inline-block mt-5 mb-3 mr-2"
            :class="{ 'border-b-2 border-blue-800': itemType == 'workflow' }"
            @click.prevent="itemType = 'workflow'"
          >
            {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.FLOWS') }}

            <span
              class="bg-black-100 text-black-800 text-sm rounded-xs px-1 py-0.25 ml-0.5"
            >
              {{ matchingWorkflows.length }}
            </span>
          </a>

          <a
            href="#articles"
            class="text-sm text-blue-400 inline-block mt-5 mb-3"
            :class="{ 'border-b-2 border-blue-800': itemType == 'article' }"
            @click.prevent="itemType = 'article'"
          >
            {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.HELP_ARTICLES') }}

            <span
              class="bg-black-100 text-black-800 text-sm rounded-xs px-1 py-0.25 ml-0.5"
            >
              {{ matchingArticles.length }}
            </span>
          </a>
        </div>

        <div v-if="itemType === 'article'">
          <select v-if="allPortals.length > 1" v-model="selectedPortalId">
            <option
              v-for="portal in allPortals"
              :key="portal.id"
              :value="portal.id"
            >
              {{ portal.name }}
            </option>
          </select>

          <add-item-modal-article
            v-for="article in matchingArticles"
            :key="article.id"
            :article="article"
            :selected="articleSelected(article)"
            :disabled="maxItemsReached && !articleSelected(article)"
            @toggleSelected="toggleArticleSelected(article)"
          />
        </div>

        <div v-else>
          <add-item-modal-workflow
            v-for="workflow in matchingWorkflows"
            :key="workflow.id"
            :workflow="workflow"
            :selected="workflowSelected(workflow)"
            :disabled="maxItemsReached && !workflowSelected(workflow)"
            @toggleSelected="toggleWorkflowSelected(workflow)"
          />
        </div>
      </div>

      <div class="flex justify-end items-center py-3 mt-7">
        <span v-if="maxItemsReached" class="text-red-800 mr-3">
          {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.CANNOT_ADD_MORE') }}
        </span>

        <a href="#cancel" class="mr-6" @click.prevent="onClose">
          {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.CANCEL') }}
        </a>

        <a
          href="#add"
          class="button rounded-lg px-2 py-0"
          @click.prevent="addItems"
        >
          {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.ADD') }}
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
const MAX_RECENT_ITEMS = 3;

import { mapGetters } from 'vuex';

import Modal from './../../../../../components/Modal';
import AddItemModalArticle from './AddItemModalArticle.vue';
import AddItemModalWorkflow from './AddItemModalWorkflow.vue';
import Cookies from 'js-cookie';

export default {
  components: {
    Modal,
    AddItemModalArticle,
    AddItemModalWorkflow,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    maxItems: {
      type: Number,
      required: true,
    },
    inboxId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedPortalId: undefined,
      selectedArticles: [],
      selectedWorkflows: [],
      search: '',
      itemType: 'article',
    };
  },
  watch: {
    selectedPortalId: 'fetchArticles',
  },
  created() {
    this.$store.dispatch('portals/index').then(() => {
      if (this.allPortals.length > 0)
        this.selectedPortalId = this.allPortals[0].id;
    });

    this.$store.dispatch('workflows/get');
  },
  computed: {
    ...mapGetters({
      allPortals: 'portals/allPortals',
      allArticles: 'articles/allArticles',
      allWorkflows: 'workflows/getWorkflows',
      resources: 'inboxChatResources/getResources',
    }),
    articlesInUse() {
      return this.resources
        .filter(resource => resource.resource_type === 'Article')
        .map(resource => {
          return resource.resource;
        });
    },
    workflowsInUse() {
      return this.resources
        .filter(resource => resource.resource_type === 'Workflow')
        .map(resource => {
          return resource.resource;
        });
    },
    matchingArticles() {
      const availableArticles = this.allArticles.filter(article => {
        return (
          this.articlesInUse.find(
            articleInUse => article.id === articleInUse.id
          ) === undefined
        );
      });

      if (this.search) {
        return availableArticles.filter(article =>
          this.searchMatches(article.title)
        );
      }

      return availableArticles;
    },
    matchingWorkflows() {
      const availableWorkflows = this.allWorkflows.filter(workflow => {
        return (
          this.workflowsInUse.find(
            workflowInUse => workflow.id === workflowInUse.id
          ) === undefined
        );
      });

      if (this.search) {
        return availableWorkflows.filter(workflow =>
          this.searchMatches(workflow.name)
        );
      }

      return availableWorkflows;
    },
    numSelectedItems() {
      return this.selectedArticles.length + this.selectedWorkflows.length;
    },
    maxItemsReached() {
      return (
        this.selectedArticles.length + this.selectedWorkflows.length >=
        this.maxItems
      );
    },
    recentlyUsedArticles() {
      try {
        const ids = JSON.parse(
          Cookies.get('recently_used_article_ids') || '[]'
        );
        return this.matchingArticles.filter(
          article => ids.indexOf(article.id) !== -1
        );
      } catch (error) {
        return [];
      }
    },
    recentlyUsedWorkflows() {
      try {
        const ids = JSON.parse(
          Cookies.get('recently_used_workflow_ids') || '[]'
        );
        return this.matchingWorkflows.filter(
          workflow => ids.indexOf(workflow.id) !== -1
        );
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('closeModal');
    },
    addItems() {
      this.$store.dispatch('inboxChatResources/createResources', {
        inboxId: this.inboxId,
        articles: this.selectedArticles,
        workflows: this.selectedWorkflows,
      });

      this.storeRecentlyUsed(this.selectedArticles, this.selectedWorkflows);

      this.$emit('closeModal');
      this.selectedArticles = [];
      this.selectedWorkflows = [];
      this.search = '';
    },
    toggleArticleSelected(article) {
      const selectedArticle = this.selectedArticles.find(
        a => a.id === article.id
      );

      if (!selectedArticle) {
        if (this.maxItemsReached) return;
        this.selectedArticles.push(article);
      } else {
        this.selectedArticles = this.selectedArticles.filter(
          a => a.id !== article.id
        );
      }
    },
    toggleWorkflowSelected(workflow) {
      const selectedWorkflow = this.selectedWorkflows.find(
        w => w.id === workflow.id
      );

      if (!selectedWorkflow) {
        if (this.maxItemsReached) return;
        this.selectedWorkflows.push(workflow);
      } else {
        this.selectedWorkflows = this.selectedWorkflows.filter(
          w => w.id !== workflow.id
        );
      }
    },
    articleSelected(article) {
      return this.selectedArticles.find(a => a.id === article.id) !== undefined;
    },
    workflowSelected(workflow) {
      return (
        this.selectedWorkflows.find(w => w.id === workflow.id) !== undefined
      );
    },
    fetchArticles() {
      if (!this.selectedPortalId) return;

      this.$store.dispatch('articles/index', {
        portalSlug: this.allPortals.find(
          portal => portal.id === this.selectedPortalId
        ).slug,
      });
    },
    searchMatches(str) {
      const terms = this.search.toLocaleLowerCase().split(/\s+/g);
      return terms.find(term => str.toLocaleLowerCase().includes(term));
    },
    storeRecentlyUsed(articles, workflows) {
      const articleIds = articles.map(article => article.id);
      const workflowIds = workflows.map(workflow => workflow.id);
      let recentArticleIds;
      let recentWorkflowIds;

      try {
        recentArticleIds = JSON.parse(
          Cookies.get('recently_used_article_ids') || '[]'
        );
      } catch (error) {
        recentArticleIds = [];
      }

      recentArticleIds = recentArticleIds.concat(articleIds);
      recentArticleIds.splice(0, recentArticleIds.length - MAX_RECENT_ITEMS);
      Cookies.set(
        'recently_used_article_ids',
        JSON.stringify(recentArticleIds)
      );

      try {
        recentWorkflowIds = JSON.parse(
          Cookies.get('recently_used_workflow_ids') || '[]'
        );
      } catch (error) {
        recentWorkflowIds = [];
      }

      recentWorkflowIds = recentWorkflowIds.concat(workflowIds);
      recentWorkflowIds.splice(0, recentWorkflowIds.length - MAX_RECENT_ITEMS);
      Cookies.set(
        'recently_used_workflow_ids',
        JSON.stringify(recentWorkflowIds)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  border-radius: 8px;
  min-height: 60%;
  max-height: 90%;
  max-width: 33.75rem;
}
</style>
