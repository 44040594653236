<template>
  <div class="track-order">
    <div class="track-order-inner">
      <button
        class="track-order-button"
        :style="{ color: computedColor }"
        @click="buildOrderTrackingForm"
      >
        <span class="track-order-button-text">
          {{ $t('TRACK_ORDER') }}
        </span>
        <fluent-icon icon="arrow-right-new" size="16" view-box="0 0 16 16" />
      </button>
      <div class="track-order-shipping-icon">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.4635 4.25635C19.4635 4.13229 19.4266 4.01103 19.3575 3.90798C19.2884 3.80492 19.1903 3.72472 19.0755 3.67757L10.2378 0.0469277C10.0854 -0.0156426 9.91456 -0.0156426 9.76222 0.0469277L0.924443 3.67757C0.809684 3.72471 0.71153 3.80491 0.642454 3.90797C0.573378 4.01102 0.536497 4.13229 0.536499 4.25635V15.7436C0.536497 15.8677 0.573378 15.989 0.642454 16.092C0.71153 16.1951 0.809684 16.2753 0.924443 16.3224L9.76222 19.9531C9.91429 20.0157 10.0849 20.0156 10.237 19.953C10.2445 19.9505 9.90992 20.0878 19.0755 16.3224C19.1903 16.2753 19.2884 16.1951 19.3575 16.092C19.4266 15.989 19.4635 15.8677 19.4635 15.7436V4.25635ZM9.99999 7.26316L6.7436 5.92541L13.781 2.85545L17.2552 4.28267L9.99999 7.26316ZM1.78793 5.24248L4.59744 6.39666V9.65288C4.59744 9.99844 4.8776 10.2786 5.22315 10.2786C5.5687 10.2786 5.84887 9.99844 5.84887 9.65288V6.91076L9.37427 8.35902V18.4408L1.78793 15.3242V5.24248ZM9.99999 1.30215L12.1691 2.19325L5.13172 5.26321L2.74481 4.28264L9.99999 1.30215ZM10.6257 8.35902L18.212 5.24248V15.3242L10.6257 18.4408V8.35902Z"
            fill="#5646AD"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
const WIDGET = 'widget';

import { mapGetters } from 'vuex';
import { getContrastingTextColor } from '@chatwoot/utils';
import configMixin from 'widget/mixins/configMixin';
import FluentIcon from 'shared/components/FluentIcon/Index.vue';
export default {
  name: 'OrderTracking',
  components: {
    FluentIcon,
  },
  mixins: [configMixin],
  props: {
    color: {
      type: String,
      required: false,
    },
    containedIn: {
      type: String,
      required: false,
      default: WIDGET,
    },
  },
  computed: {
    ...mapGetters({
      widgetColor: 'appConfig/getWidgetColor',
    }),
    textColor() {
      return getContrastingTextColor(this.widgetColor);
    },
    computedColor() {
      if (this.containedIn === WIDGET) return this.widgetColor;

      return this.color;
    },
  },
  methods: {
    buildOrderTrackingForm() {
      this.$store.dispatch('conversation/buildOrderTrackingForm');
      this.$emit('start-conversation');
    },
  },
};
</script>

<style scoped lang="scss">
.track-order {
  @apply px-5 py-3 shadow-sm rounded-md bg-neutral;
}
.track-order-inner {
  @apply flex items-center justify-between text-sm font-semibold text-slate-700;
}
.track-order-button {
  @apply inline-flex text-sm rounded-md py-1 px-2 -ml-2 leading-6 text-slate-800 justify-between items-center hover:bg-neutral-subtle relative transition-all ease-in-out duration-300;
}
.track-order-button-text {
  @apply pr-2 text-sm;
}
.track-order-shipping-icon {
  @apply w-8 aspect-square bg-violet-200 rounded-md flex items-center justify-center flex-none;
}
</style>
