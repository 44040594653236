<template>
  <div class="flex items-center justify-center p-8">
    <h6 class="block text-xs text-center w-100 text-[#868E96]">
      <span class="mr-1">{{ message }}</span>
      <span class="spinner" />
    </h6>
  </div>
</template>
<script>
export default {
  props: {
    message: { type: String, default: '' },
  },
};
</script>
