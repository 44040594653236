<template>
  <div class="dropdown-wrap">
    <div class="mb-2 flex-shrink-0 flex-grow-0 flex-auto max-h-8 relative">
      <input
        ref="searchbar"
        v-model="searchQuery"
        type="text"
        class="search-input"
        :placeholder="inputPlaceholder"
        @input="debouncedHandleInput"
      />
    </div>
    <div class="flex justify-start items-start flex-auto">
      <div
        v-if="isLoading"
        class="w-full flex justify-center items-center py-4"
      >
        <woot-loading-state size="small" />
      </div>
      <div v-else class="w-full max-h-[10rem]">
        <woot-dropdown-menu>
          <div class="h-[159px] overflow-auto">
            <woot-dropdown-item v-for="option in options" :key="option.id">
              <woot-button
                class="multiselect-dropdown--item"
                :variant="isActive(option) ? 'hollow' : 'clear'"
                color-scheme="secondary"
                :class="{ active: isActive(option) }"
                @click="() => onclick(option)"
              >
                <div class="flex items-center">
                  <div class="flex items-center justify-between w-full min-w-0">
                    <span
                      class="leading-4 my-0 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
                      :title="option.title"
                    >
                      {{ option.title }}
                    </span>
                    <fluent-icon v-if="isActive(option)" icon="checkmark" />
                  </div>
                </div>
              </woot-button>
            </woot-dropdown-item>
          </div>
          <!-- slot for action button -->
          <template #actionButton>
            <woot-button
              class="button w-full text-left !border-0 flex items-center"
              variant="hollow"
              icon-size="small"
              @click="handleActionButton"
            >
              <fluent-icon icon="add" class="relative -top-[1px]" />
              Add new intent
            </woot-button>
          </template>
        </woot-dropdown-menu>
        <h4
          v-if="noResult"
          class="w-full justify-center items-center flex text-slate-500 dark:text-slate-300 py-2 px-2.5 overflow-hidden whitespace-nowrap text-ellipsis text-sm"
        >
          {{ noSearchResult }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import { debounce } from '@chatwoot/utils';

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    inputPlaceholder: {
      type: String,
      default: 'Search',
    },
    noSearchResult: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: '',
      debouncedHandleInput: () => {},
    };
  },

  computed: {
    noResult() {
      return this.options.length === 0;
    },
  },

  created() {
    this.debouncedHandleInput = debounce(this.handleInput, 300);
  },

  mounted() {
    this.focusInput();
  },

  methods: {
    onclick(option) {
      this.$emit('click', option);
    },
    focusInput() {
      this.$refs.searchbar.focus();
    },
    isActive(option) {
      return this.selectedItems.some(item => item && option.id === item.id);
    },
    handleInput(e) {
      this.searchQuery = e.target.value;
      this.$emit('search', this.searchQuery);
    },
    handleActionButton() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wrap {
  @apply w-full flex flex-col h-[15rem];
}

.search-input {
  @apply m-0 w-full border border-solid border-transparent h-8 text-sm text-slate-700 dark:text-slate-100 rounded-md focus:border-woot-500 bg-slate-50 dark:bg-slate-900;
}

.multiselect-dropdown--item {
  @apply justify-between w-full;

  &.active {
    @apply bg-slate-25 dark:bg-slate-700 border-slate-50 dark:border-slate-900 font-medium;
  }

  &:focus {
    @apply bg-slate-25 dark:bg-slate-700;
  }

  &:hover {
    @apply bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-100;
  }
}
</style>
