<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <div class="flex flex-col h-[calc(100%-100px)]">
      <div class="w-[400px] px-2 m-auto">
        <dns-card
          :title="$t('INBOX_MGMT.ADD.AUTH_EMAIL.FORWARD.TITLE')"
          :desc="
            $t('INBOX_MGMT.ADD.AUTH_EMAIL.FORWARD.DESC', {
              email: currentInbox.email,
            })
          "
        >
          <template #content>
            <div class="flex items-center w-full mb-6">
              <div class="w-[80%]">
                <input
                  type="text"
                  :value="currentInbox.forward_to_email"
                  class="mb-0"
                  disabled
                />
              </div>
              <div class="w-[20%]">
                <woot-button
                  variant="clear"
                  @click="handleCopy(currentInbox.forward_to_email)"
                >
                  {{ $t('INBOX_MGMT.COPY.TEXT') }}
                </woot-button>
              </div>
            </div>
          </template>
          <template #action>
            <woot-button is-expanded @click="gotoFinish">
              {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.DONE') }}
            </woot-button>
          </template>
        </dns-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DnsCard from './components/DnsCard.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import router from '../../../index';

export default {
  components: {
    DnsCard,
  },
  mixins: [alertMixin],
  computed: {
    ...mapGetters({
      inbox: 'inboxes/getInbox',
    }),
    inboxId() {
      return this.$route.params.inbox_id;
    },
    currentInbox() {
      return this.inbox(this.inboxId);
    },
  },
  methods: {
    async handleCopy(val) {
      await copyTextToClipboard(val);
      this.showAlert(this.$t('INBOX_MGMT.COPY.SUCCESS'));
    },
    gotoFinish() {
      router.replace({
        name: 'settings_inboxes_email_provider_finish',
        params: {
          page: 'new',
          inbox_id: this.currentInbox.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .button .button__content {
    text-align: center !important;
  }
}
</style>
