<template>
  <div
    class="border border-solid border-1 border-black-200 rounded-lg flex items-center px-3 py-2 mb-1"
    :class="{ 'border-black-800': selected, 'bg-black-100': selected }"
    @click="$emit('toggleSelected')"
  >
    <input
      type="checkbox"
      class="mr-3 my-0"
      :checked="selected"
      :disabled="disabled"
    />

    <div class="flex-1">
      {{ workflow.name }}
    </div>

    <span
      class="bg-black-100 text-black-800 text-xs font-medium me-2 px-1 py-0 rounded-md dark:bg-black-700 dark:text-black-300"
    >
      {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.FLOW') }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  input[type="checkbox"] {
    width: 1.125rem;
    height: 1.125rem;
  }
</style>
