/* global axios */
import ApiClient from '../ApiClient';

class InboxChatResourcesApi extends ApiClient {
  constructor() {
    super('inbox_chat_resources', { accountScoped: true });
  }

  resourceUrl({ id, inbox_id}) {
    return this.url + '/' + id + '?inbox_id=' + inbox_id;
  }

  get({ inboxId }) {
    return axios.get(this.url, {
      params: {
        inbox_id: inboxId,
      },
    });
  }

  createBulk({ inboxId, articleIds, workflowIds }) {
    return axios.post(this.url + '/create_bulk', {
      inbox_id: inboxId,
      article_ids: articleIds,
      workflow_ids: workflowIds,
    });
  }

  update(inboxChatResource) {
    return axios.patch(this.resourceUrl(inboxChatResource), {
      inbox_chat_resource: inboxChatResource,
    });
  }

  destroy({ inbox_id, id }) {
    return axios.delete(this.resourceUrl({ inbox_id, id }));
  }
}

export default new InboxChatResourcesApi();
