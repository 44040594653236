<template>
  <div class="inbox-name">
    <div class="inbox-name__icon">
      <fluent-icon
        class="inbox-name__icon-item"
        :icon="computedInboxClass"
        :size="isMessengerIcon ? 14 : 12"
        :view-box="isMessengerIcon ? '-4 -4 24 24' : '0 0 24 24'"
      />
    </div>
    <div class="inbox-name__text">
      {{ formattedInboxName }}
    </div>
  </div>
</template>
<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      const {
        phone_number: phoneNumber,
        channel_type: type,
        is_meta_comments_inbox: metaComment,
      } = this.inbox;
      const classByType = getInboxClassByType(type, phoneNumber, metaComment);
      return classByType;
    },
    formattedInboxName() {
      return this.capitalizeFirstLetter(this.inbox?.name) || '';
    },
    // somehow messenger icon not aligned properly, need to adjust it
    // TODO: need proper svg icon
    isMessengerIcon() {
      return this.computedInboxClass === 'brand-messenger';
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (!string) return string;
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.inbox-name {
  @apply inline-flex items-center py-0.5 px-0 leading-3 font-medium bg-none my-0 mx-2.5 relative;

  &__icon-item,
  &__text {
    @apply text-secondary dark:text-secondary-dark;
  }

  &__icon-item {
    @apply mr-1 rtl:ml-1 rtl:mr-0;
  }

  &__text {
    @apply text-xs;
  }
}
</style>
