<template>
  <div
    class="flex items-center justify-center px-8 mt-0.5 mb-2 h-[180px] w-full bg-neutral-subtle dark:bg-neutral-dark-subtle text-center"
  >
    <p class="mb-0 text-sm text-secondary dark:text-secondary-dark">
      {{ $t('CONVERSATION.POST_UNAVAILABLE') }}
    </p>
  </div>
</template>

<script>
export default {};
</script>
