<template>
  <div :class="calloutClass">
    <div class="custom-callout__head">{{ title }}</div>
    <div v-dompurify-html="desc" class="custom-callout__body" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    calloutClass() {
      let baseClass = ['custom-callout'];

      if (this.isDanger) {
        baseClass.push('custom-callout--danger');
      }

      return baseClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-callout {
  @apply p-3 bg-success bg-opacity-[0.2] border border-success-medium dark:border-success-dark-medium;

  &__head {
    @apply text-primary dark:text-primary-dark font-bold;
  }
  &__body {
    @apply text-primary dark:text-primary-dark;
  }

  &--danger {
    @apply bg-error bg-opacity-[0.2] border-error-medium dark:border-error-dark-medium;

    .custom-callout__head,
    .custom-callout__body {
      @apply text-error-medium dark:text-error-dark-medium;
    }
  }
}
</style>
