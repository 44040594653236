<template>
  <div class="flex-1 overflow-auto p-4">
    <div class="flex flex-col h-[calc(100%-100px)]">
      <div class="w-[400px] px-2 m-auto">
        <dns-card>
          <template #content>
            <div class="mb-6 text-center">
              <div class="text-error dark:text-error-dark mb-4">
                <fluent-icon
                  icon="close-circle"
                  type="solid"
                  size="48"
                  view-box="0 0 24 24"
                  class="mx-auto"
                />
              </div>
              <div
                class="text-xl text-primary dark:text-primary-dark mb-2 font-medium"
              >
                {{ $t('SHOP_MGMT.ERROR_TITLE') }}
              </div>
              <div class="text-sm text-secondary dark:text-secondary mb-4">
                {{ errorMessage }}
              </div>
            </div>
          </template>
          <template #action>
            <div class="flex items-center justify-center gap-2.5">
              <router-link :to="shopListUrl">
                <woot-button is-expanded>
                  {{ $t('SHOP_MGMT.BACK_TO_LIST') }}
                </woot-button>
              </router-link>
            </div>
          </template>
        </dns-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DnsCard from '../inbox/components/DnsCard.vue';
import { frontendURL } from 'dashboard/helper/URLHelper.js';

export default {
  name: 'ShopsStatus',
  components: {
    DnsCard,
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    shopListUrl() {
      return frontendURL(`accounts/${this.accountId}/settings/shops/list`);
    },
    errorMessage() {
      return this.$route.query.alert;
    },
  },
};
</script>
