export default {
  methods: {
    currency_symbol(currency_code) {
      const currencies = {
        AED: 'AED',
        AFN: '؋',
        ALL: 'Lek',
        AMD: 'AMD',
        ANG: 'ƒ',
        AOA: 'AOA',
        ARS: '$',
        AUD: '$',
        AWG: 'ƒ',
        AZN: 'ман',
        BAM: 'KM',
        BBD: '$',
        BDT: 'BDT',
        BGN: 'лв',
        BHD: 'BHD',
        BIF: 'BIF',
        BMD: '$',
        BND: '$',
        BOB: '$b',
        BRL: 'R$',
        BSD: '$',
        BTN: 'BTN',
        BWP: 'P',
        BYR: 'p.',
        BZD: 'BZ$',
        CAD: '$',
        CDF: 'CDF',
        CHF: 'CHF',
        CLP: '$',
        CNY: '¥',
        COP: '$',
        CRC: '₡',
        CUC: 'CUC',
        CUP: '₱',
        CVE: 'CVE',
        CZK: 'Kč',
        DJF: 'DJF',
        DKK: 'kr',
        DOP: 'RD$',
        DZD: 'DZD',
        EGP: '£',
        ERN: 'ERN',
        ETB: 'ETB',
        EUR: '€',
        FJD: '$',
        FKP: '£',
        GBP: '£',
        GEL: 'GEL',
        GGP: '£',
        GHS: '¢',
        GIP: '£',
        GMD: 'GMD',
        GNF: 'GNF',
        GTQ: 'Q',
        GYD: '$',
        HKD: '$',
        HNL: 'L',
        HRK: 'kn',
        HTG: 'HTG',
        HUF: 'Ft',
        IDR: 'Rp',
        ILS: '₪',
        IMP: '£',
        INR: '₹',
        IQD: 'IQD',
        IRR: '﷼',
        ISK: 'kr',
        JEP: '£',
        JMD: 'J$',
        JOD: 'JOD',
        JPY: '¥',
        KES: 'KES',
        KGS: 'лв',
        KHR: '៛',
        KMF: 'KMF',
        KPW: '₩',
        KRW: '₩',
        KWD: 'KWD',
        KYD: '$',
        KZT: 'лв',
        LAK: '₭',
        LBP: '£',
        LKR: '₨',
        LRD: '$',
        LSL: 'LSL',
        LYD: 'LYD',
        MAD: 'MAD',
        MDL: 'MDL',
        MGA: 'MGA',
        MKD: 'ден',
        MMK: 'MMK',
        MNT: '₮',
        MOP: 'MOP',
        MRO: 'MRO',
        MUR: '₨',
        MVR: 'MVR',
        MWK: 'MWK',
        MXN: '$',
        MYR: 'RM',
        MZN: 'MT',
        NAD: '$',
        NGN: '₦',
        NIO: 'C$',
        NOK: 'kr',
        NPR: '₨',
        NZD: '$',
        OMR: '﷼',
        PAB: 'B/.',
        PEN: 'S/.',
        PGK: 'PGK',
        PHP: '₱',
        PKR: '₨',
        PLN: 'zł',
        PRB: 'PRB',
        PYG: 'Gs',
        QAR: '﷼',
        RON: 'lei',
        RSD: 'Дин.',
        RUB: 'руб',
        RWF: 'RWF',
        SAR: '﷼',
        SBD: '$',
        SCR: '₨',
        SDG: 'SDG',
        SEK: 'kr',
        SGD: '$',
        SHP: '£',
        SLL: 'SLL',
        SOS: 'S',
        SRD: '$',
        SSP: 'SSP',
        STD: 'STD',
        SYP: '£',
        SZL: 'SZL',
        THB: '฿',
        TJS: 'TJS',
        TMT: 'TMT',
        TND: 'TND',
        TOP: 'TOP',
        TRY: '₺',
        TTD: 'TT$',
        TWD: 'NT$',
        TZS: 'TZS',
        UAH: '₴',
        UGX: 'UGX',
        USD: '$',
        UYU: '$U',
        UZS: 'лв',
        VEF: 'Bs',
        VND: '₫',
        VUV: 'VUV',
        WST: 'WST',
        XAF: 'XAF',
        XCD: '$',
        XOF: 'XOF',
        XPF: 'XPF',
        YER: '﷼',
        ZAR: 'R',
        ZMW: 'ZMW',
      };
      let symbol;
      if (Object.prototype.hasOwnProperty.call(currencies, currency_code)) {
        symbol = currencies[currency_code];
      } else {
        symbol = currency_code;
      }

      return symbol;
    },

    // format money based on country
    // eg: 10,000.55
    moneyFormat(number, currency_code) {
      let realNumber = Number(number);

      const currencyToLocale = {
        USD: 'en-US',
        EUR: 'de-DE',
        JPY: 'ja-JP',
        GBP: 'en-GB',
        CNY: 'zh-CN',
        IDR: 'id-ID',
        // add more if needed
      };

      const locale = currencyToLocale[currency_code] || 'en-US';

      return realNumber.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
