import types from '../mutation-types';
import shopContactAgentSalesApi from '../../api/shopContactAgentSales';

const state = {
  records: [],
  syncedAt: null,
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getAgentRevenueReport: (_state) => {
    return _state.records;
  },
  getSyncedAt: (_state) => {
    return _state.syncedAt;
  },
  getUIFlags: (_state) => {
    return _state.uiFlags;
  },
};

export const actions = {
  fetchReportForAccount: async function fetchAgentRevenueReport({ commit }, { from, to }) {
    try {
      commit(types.SET_SHOP_CONTACT_AGENT_SALES_UI_FLAG, { isFetching: true });
      const response = await shopContactAgentSalesApi.getAgentRevenueReport({ from, to });
      commit(types.SET_SHOP_CONTACT_AGENT_SALES_REVENUE_REPORT, { agentRevenueReport: response.data });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SHOP_CONTACT_AGENT_SALES_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_SHOP_CONTACT_AGENT_SALES](_state, { shopId, shopContactAgentSales }) {
    _state.records = Object.assign({}, _state.records, { [shopId]: shopContactAgentSales });
  },
  [types.SET_SHOP_CONTACT_AGENT_SALES_REVENUE_REPORT](_state, { agentRevenueReport }) {
    _state.records = agentRevenueReport.report;
    _state.lastSynchronizedAt = agentRevenueReport.shop_contact_agent_sales_synced_at;
  },
  [types.SET_SHOP_CONTACT_AGENT_SALES_UI_FLAG](_state, uiFlag) {
    _state.uiFlags = { ..._state.uiFlags, ...uiFlag };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
