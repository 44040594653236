<template>
  <div>
    <div
      v-for="shipment in shipments"
      :key="shipment.tracking_number"
      class="mb-1"
    >
      <div v-if="shipment.tracking_number" class="address-line mb-1">
        <div class="address-line__address">
          <div class="address-line__icon">
            <fluent-icon icon="document-text-link" size="16" />
          </div>
          <div class="leading-normal">
            <a :href="shipment.tracking_url" target="_blank">
              {{ shipment.tracking_number }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="address-line">
      <div class="address-line__address">
        <div class="address-line__icon">
          <fluent-icon icon="location" size="16" />
        </div>
        <div class="leading-normal">
          {{ shippingAddress.address1 || '-' }}<br />
          {{ shippingAddress.address2 || '-' }}<br />
          {{ shippingAddress.province || '-' }}<br />
          {{ shippingAddress.province_code || '-' }},
          {{ shippingAddress.city || '-' }}<br />
          {{ shippingAddress.country || '-' }}
        </div>
      </div>
      <div class="address-line__actions" @click="handleCopyAddress">
        <fluent-icon icon="clipboard" size="15" />
      </div>
    </div>
  </div>
</template>
<script>
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    shippingAddress: {
      type: Object,
      default: () => ({}),
    },
    shipments: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async handleCopyAddress(e) {
      e.preventDefault();
      const addressText = Object.values(this.shippingAddress)
        .filter(value => value !== null && value !== undefined)
        .join(', ');
      await copyTextToClipboard(addressText);
      this.showAlert('Copied to clipboard successfully');
    },
  },
};
</script>

<style lang="scss" scoped>
.address-line {
  @apply flex gap-x-4 -mx-4 px-4;

  &__address {
    @apply text-primary dark:text-stategrey relative pl-6 grow;
  }
  &__icon {
    @apply absolute left-0 text-primary dark:text-stategrey w-[24px] h-[24px];

    svg {
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
  &__actions {
    @apply relative cursor-pointer rounded-[4px] border border-solid w-[32px] h-[32px] transition-all ease-in-out duration-200 border-accent text-accent;

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#25292B];
    }
  }
}
</style>
