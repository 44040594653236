<template>
  <div class="overflow-auto" :class="compact ? 'py-0 px-0' : 'py-2 px-2'">
    <div class="items-center flex justify-between mb-1">
      <span class="text-[#868E96] text-[13px] leading-none">
        {{ title }}
      </span>
      <slot name="button" />
    </div>
    <div
      v-if="value"
      class="break-words text-primary dark:text-stategrey text-sm leading-normal"
    >
      <slot>
        {{ value }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    icon: { type: String, default: '' },
    emoji: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    compact: { type: Boolean, default: false },
  },
};
</script>
