<template>
  <div class="h-auto overflow-auto flex flex-col p-4">
    <woot-modal-header class="!p-0 !pb-3">
      <h2 class="!font-medium">{{ $t('WORKFLOW_MGMT.ADD.TITLE') }}</h2>
    </woot-modal-header>
    <form class="flex flex-col w-full !p-0" @submit.prevent="addNewWorkflow()">
      <div class="w-full mb-7">
        <label :class="{ error: $v.name.$error }">
          <div class="mb-1">{{ $t('WORKFLOW_MGMT.FORM.NAME.LABEL') }}</div>
          <input
            v-model.trim="name"
            type="text"
            class="mb-0"
            @input="$v.name.$touch"
          />
        </label>
        <div class="text-secondary dark:text-secondary-dark text-xs mt-2">
          {{ $t('WORKFLOW_MGMT.ADD.DESC') }}
        </div>
      </div>

      <div class="flex flex-row justify-end gap-2 w-full">
        <button class="button clear" @click.prevent="onClose(undefined)">
          {{ $t('WORKFLOW_MGMT.FORM.CANCEL') }}
        </button>

        <woot-submit-button
          :disabled="$v.name.$invalid || addWorkflow.showLoading"
          :button-text="$t('WORKFLOW_MGMT.FORM.SUBMIT')"
          :loading="addWorkflow.showLoading"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [alertMixin],

  data() {
    return {
      name: '',
      addWorkflow: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },
  methods: {
    onClose(workflowId) {
      this.$emit('close', workflowId);
    },
    resetForm() {
      this.name = '';
      this.$v.name.$reset();
    },
    addNewWorkflow() {
      // Show loading on button
      this.addWorkflow.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('workflows/create', {
          name: this.name,
        })
        .then(response => {
          const createdWorkflowId = response.data.id;

          // Reset Form, Show success message
          this.addWorkflow.showLoading = false;
          this.showAlert(this.$t('WORKFLOW_MGMT.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
          this.onClose(createdWorkflowId);
        })
        .catch(error => {
          this.addWorkflow.showLoading = false;
          const errorMessage =
            error?.message || this.$t('WORKFLOW_MGMT.ADD.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    @apply hidden;
  }

  .ProseMirror-woot-style {
    @apply min-h-[12.5rem];

    p {
      @apply text-base;
    }
  }
}
</style>
