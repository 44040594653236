<template>
  <div class="contact-info">
    <div class="text-left rtl:text-right">
      <!-- Contact Head Wrapper -->
      <div class="contact-head">
        <div class="contact-head__left">
          <thumbnail
            class="contact-head__thumb"
            :src="contact.thumbnail"
            size="48px"
            :username="contact.name"
            :status="contact.availability_status"
          />
          <!-- Contact Name -->
          <div class="contact-name">
            <div class="contact-name__head">
              <div
                v-tooltip.bottom-start="`Click to copy`"
                class="contact-name__head-text"
                @click="onCopy(fullName)"
              >
                {{ fullName }}
              </div>

              <!-- Contact Link -->
              <a v-if="hasCustomerData" class="contact-name__head-icon">
                <a
                  :href="shopifyCustomerLink"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <img :src="iconShopify" alt="Shopify" />
                </a>
                <a
                  :href="klaviyoCustomerLink"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <img :src="iconKlaviyo" alt="Klaviyo" width="14" />
                </a>
              </a>
            </div>
            <div class="contact-name__foot">
              <!-- Total Spent -->
              <div class="contact-name__foot-left">
                {{ hasCustomerData ? totalSpent : 'No orders' }}
              </div>

              <span class="px-1">&bull;</span>

              <!-- Previous Conversation -->
              <div class="contact-name__foot-right">
                {{
                  `${
                    getPreviousConversation.count > 0
                      ? getPreviousConversation.count
                      : ''
                  } ${getPreviousConversation.text}`
                }}
              </div>
            </div>
          </div>
        </div>

        <!-- popover -->
        <div ref="popoverTrigger" class="contact-head__icon-wrapper">
          <div
            class="contact-head__icon-content"
            :class="
              showPopover ? 'contact-head__icon-content--active' : undefined
            "
            @click="handlePopover"
          >
            <fluent-icon
              icon="kebab"
              type="outline"
              size="26"
              view-box="0 0 26 26"
            />
          </div>
          <!-- Contact Popover -->
          <div
            v-if="showPopover"
            ref="popoverWrapper"
            class="contact-popover"
            :class="showPopover ? 'contact-popover--active' : undefined"
          >
            <button class="contact-popover__item" @click="toggleEditModal">
              <div class="contact-popover__text">Edit</div>
            </button>
            <button
              class="contact-popover__item"
              :disabled="uiFlags.isMerging"
              @click="openMergeModal"
            >
              <div class="contact-popover__text">Merge Contact</div>
            </button>
            <button
              v-if="isAdmin"
              class="contact-popover__item"
              :disabled="uiFlags.isDeleting"
              @click="toggleDeleteModal"
            >
              <div class="contact-popover__text contact-popover__text-danger">
                Delete Contact
              </div>
            </button>
          </div>
        </div>
      </div>

      <!-- Contact Row -->
      <div class="cell cell--ellipsis mb-4">
        <div class="cell__item">Email</div>
        <div class="cell__item">
          <template v-if="contact.email">
            <a
              class="cell__link cell__link--result"
              :href="`mailto:${contact.email}`"
            >
              {{ contact.email }}
            </a>
          </template>
          <template v-else>
            <div class="cell__link cell__link--black">-</div>
          </template>
        </div>
      </div>
      <div class="cell mb-4">
        <div class="cell__item">Phone</div>
        <div class="cell__item">
          <template v-if="contact.phone_number">
            <a
              class="cell__link cell__link--result"
              :href="`tel:${contact.phone_number}`"
            >
              {{ contact.phone_number }}
            </a>
          </template>
          <template v-else>
            <div class="cell__link cell__link--black">-</div>
          </template>
        </div>
      </div>
    </div>

    <!-- MODAL -->
    <edit-contact
      v-if="showEditModal"
      :show="showEditModal"
      :contact="contact"
      @cancel="toggleEditModal"
    />
    <contact-merge-modal
      v-if="showMergeModal"
      :primary-contact="contact"
      :show="showMergeModal"
      @close="toggleMergeModal"
    />
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import timeMixin from 'dashboard/mixins/time';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

import EditContact from './EditContact.vue';
import ContactMergeModal from 'dashboard/modules/contact/ContactMergeModal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import {
  isAConversationRoute,
  getConversationDashboardRoute,
} from '../../../../helper/routeHelpers';
import ShopifyIcon from '/public/dashboard/images/shopify/shopify-icon.svg';
import KlaviyoIcon from '/public/dashboard/images/shopify/klaviyo-icon.svg';
import { KLAVIYO } from 'shared/constants/links';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  components: {
    EditContact,
    Thumbnail,
    ContactMergeModal,
  },
  mixins: [alertMixin, adminMixin, timeMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
    previousConversations: {
      type: Number,
      default: 0,
    },
    totalSpent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditModal: false,
      showMergeModal: false,
      showDeleteModal: false,
      iconShopify: ShopifyIcon,
      iconKlaviyo: KlaviyoIcon,
      showPopover: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    contactProfileLink() {
      return `/app/accounts/${this.$route.params.accountId}/contacts/${this.contact.id}`;
    },
    confirmDeleteMessage() {
      return ` ${this.contact.name}?`;
    },
    fullName() {
      if (this.hasCustomerData) {
        return this.customer.first_name && this.customer.last_name
          ? `${this.customer.first_name} ${this.customer.last_name}`
          : this.customer.email;
      }
      return this.contact.name;
    },
    shopifyCustomerLink() {
      return `https://${this.customer.shop}/admin/customers/${this.customer.id}`;
    },
    klaviyoCustomerLink() {
      return `${KLAVIYO.PROFILE}${this.contact.email}`;
    },
    getPreviousConversation() {
      const hasPreviousConversations = this.previousConversations > 0;
      if (!hasPreviousConversations) {
        return {
          count: 0,
          text: 'No conversations',
        };
      }

      return {
        count: this.previousConversations,
        text:
          this.previousConversations === 1 ? 'conversation' : 'conversations',
      };
    },
    hasCustomerData() {
      return Boolean(this.customer && Object.keys(this.customer).length);
    },
  },
  mounted() {
    this.editModalListener = this.$root.$on(
      'conversation.show-contact-edit-modal',
      this.toggleEditModal
    );
    window.addEventListener('mouseup', this.onOutsideClick);
  },
  beforeDestroy() {
    this.$root.$off(
      'conversation.show-contact-edit-modal',
      this.toggleDeleteModal
    );
    window.removeEventListener('mouseup', this.onOutsideClick);
  },
  methods: {
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
      this.closePopover();
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
      this.closePopover();
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
      this.closePopover();
    },
    confirmDeletion() {
      this.deleteContact(this.contact);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showEditModal = false;
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('contacts/delete', id);
        this.$emit('panel-close');
        this.showAlert(this.$t('DELETE_CONTACT.API.SUCCESS_MESSAGE'));

        if (isAConversationRoute(this.$route.name)) {
          this.$router.push({
            name: getConversationDashboardRoute(this.$route.name),
          });
        } else if (this.$route.name !== 'contacts_dashboard') {
          this.$router.push({
            name: 'contacts_dashboard',
          });
        }
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_CONTACT.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
    handlePopover() {
      this.showPopover = !this.showPopover;
    },
    closePopover() {
      this.showPopover = false;
    },
    onOutsideClick(e) {
      if (
        this.showPopover &&
        e.target !== this.$refs.popoverWrapper &&
        !this.$refs.popoverWrapper.contains(e.target) &&
        !this.$refs.popoverTrigger.contains(e.target)
      ) {
        this.closePopover();
      }
    },
    async onCopy(attributeValue) {
      await copyTextToClipboard(attributeValue);
      this.showAlert('Copied to clipboard successfully');
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info {
  @apply relative px-6 pt-6 bg-white dark:bg-[#151718];
}

.contact-head {
  @apply flex items-center relative mb-10 z-[1];

  &__left {
    @apply flex w-full;
  }

  &__thumb {
    @apply mr-4;
  }

  &__icon-wrapper {
    @apply absolute top-[50%] right-[-3px] w-[24px] h-[24px] -translate-y-[50%];
  }

  &__icon-content {
    @apply z-10 relative w-[24px] h-[24px] border border-transparent rounded-[2px] cursor-pointer select-none;

    &:hover {
      @apply bg-neutral-subtle border-[#E4E3E6] dark:bg-[#25292B] dark:border-[#2F3336];
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-[45%] -translate-y-[48%];
    }

    &--active {
      @apply bg-white border-[#E4E3E6] dark:bg-[#25292B] dark:border-[#2F3336] rounded-b-none border-b-white dark:border-b-[#25292B];

      &:hover {
        @apply bg-white border-[#E4E3E6] dark:bg-[#25292B] dark:border-[#2F3336] rounded-b-none border-b-white dark:border-b-[#25292B];
      }
    }
  }
}

.contact-name {
  @apply lg:w-[calc(100%-75px)] xl:w-[calc(100%-90px)];

  &__head {
    @apply mb-1 flex items-start w-full;
  }

  &__head-text {
    @apply leading-tight text-primary dark:text-stategrey text-base font-semibold cursor-pointer hover:underline whitespace-nowrap overflow-hidden overflow-ellipsis;
  }

  &__head-icon {
    @apply flex items-center px-[6px];

    a {
      @apply mr-[7px] inline-block p-[1px] rounded-[2px] border border-transparent relative w-[20px] h-[20px] select-none;

      img {
        @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      }

      &:hover {
        @apply bg-neutral-subtle border-[#E4E3E6] dark:bg-[#25292B] dark:border-[#2F3336];
      }

      &:last-child {
        @apply mr-0;
      }
    }
  }

  &__foot {
    @apply text-[#868E96] text-sm flex items-center text-ellipsis overflow-hidden whitespace-nowrap w-full pr-4;
  }

  &__foot-right {
    @apply whitespace-nowrap overflow-hidden overflow-ellipsis w-full;
  }
}

.contact-popover {
  @apply cursor-default w-[245px] absolute top-[23px] right-0 bg-neutral rounded-lg shadow-dropdown select-none dark:bg-[#25292B] overflow-hidden border dark:border-[#2F3336] border-[#E4E3E6];

  &__item {
    @apply px-4 py-3 w-full cursor-pointer text-left;

    &:first-child {
      @apply rounded-t-lg;
    }

    &:last-child {
      @apply rounded-b-lg;
    }

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#383d40];
    }
  }

  &__text {
    @apply text-sm text-primary block dark:text-stategrey;

    &-danger {
      @apply text-[#CD2B31] dark:text-[#CD2B31];
    }
  }

  &--active {
    @apply rounded-tr-none;

    .contact-popover__item {
      &:first-child {
        @apply rounded-tr-none;
      }
    }
  }
}

.cell {
  @apply flex items-center justify-between;

  &__item {
    @apply basis-[35%] text-sm text-[#868E96] leading-tight;

    &:last-child {
      @apply text-right basis-[65%] pl-4;
    }
  }

  &__link {
    @apply text-primary dark:text-stategrey font-normal text-xs inline-block;

    &:hover {
      @apply text-accent underline;
    }
  }

  &__link--black {
    @apply text-primary;

    &:hover {
      @apply text-primary dark:text-stategrey;
    }
  }

  &__link--result {
    @apply text-sm;
  }

  &--ellipsis {
    .cell__link--result {
      @apply whitespace-nowrap overflow-hidden overflow-ellipsis
      xl:max-w-[200px] 2xl:max-w-[200px] sm:max-w-[147px] md:max-w-[155px] lg:max-w-[158px];
    }
  }
}

::v-deep {
  .user-thumbnail-box,
  .avatar-container {
    @apply sm:w-[32px] sm:h-[32px] md:w-[36px] md:h-[36px] lg:w-[36px] lg:h-[36px] xl:w-[48px] xl:h-[48px] #{!important};
  }
}
</style>
