<template>
  <woot-button
    icon="wand"
    color-scheme="secondary"
    variant="smooth"
    size="small"
    v-tooltip.top-end="'Write AI draft'"
    @click="AISuggest"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/aiMixin';
import aiMixin from 'dashboard/mixins/isAdmin';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import { buildHotKeys } from 'shared/helpers/KeyboardHelpers';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import AISuggestAPI from '../../api/aiSuggest';

export default {
  components: {},
  mixins: [aiMixin, eventListenerMixins, adminMixin, uiSettingsMixin],
  props: {
    isPrivateNote: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    showAIAssistanceModal: false,
    showAICtaModal: false,
    aiOption: '',
    initialMessage: '',
  }),
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      isAChatwootInstance: 'globalConfig/isAChatwootInstance',
    }),
  },

  mounted() {
    this.initialMessage = this.draftMessage;
  },

  methods: {
    onKeyDownHandler(event) {
      const keyPattern = buildHotKeys(event);
      const shouldRevertTheContent =
        ['meta+z', 'ctrl+z'].includes(keyPattern) && !!this.initialMessage;
      if (shouldRevertTheContent) {
        this.$emit('replace-text', this.initialMessage);
        this.initialMessage = '';
      }
    },
    async AISuggest() {
      this.initialMessage = this.draftMessage;

      const response = await AISuggestAPI.create({
        text: this.initialMessage,
        conversationId: this.conversationId,
      });
      const suggestion = response.data.suggestion;

      this.insertText(suggestion);
    },

    insertText(message) {
      this.$emit('replace-text', message);
    },
  },
};
</script>
