<template>
  <div :class="capsuleClass">
    <div class="capsule__icon">
      <slot name="icon" />
    </div>
    <div class="capsule__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Capsule',
  props: {
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    capsuleClass() {
      let baseClass = ['capsule'];

      if (this.variant === 'secondary') {
        baseClass.push('capsule--secondary');
      }

      if (this.variant === 'tertiary') {
        baseClass.push('capsule--tertiary');
      }
      return baseClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.capsule {
  @apply p-1 px-2 bg-accent-low dark:bg-accent-dark-low rounded-lg inline-flex items-center justify-center;

  &__text {
    @apply text-accent text-xs leading-none font-medium;
  }

  &--secondary {
    @apply bg-neutral-medium dark:bg-neutral-dark-medium;

    .capsule__text,
    .capsule__icon {
      @apply text-primary dark:text-primary-dark;
    }
  }

  &--tertiary {
    @apply bg-warning-subtle dark:bg-warning-dark-subtle;

    .capsule__text,
    .capsule__icon {
      @apply text-warning dark:text-warning-dark;
    }
  }
}
</style>
