<template>
  <div class="training">
    <div class="training__item">
      <!-- Chat -->
      <training-chat :messages="messages" />
      <training-editor @send="handleSend" />
    </div>
    <div class="training__item">
      <div class="text-sm mb-2 font-medium">
        {{ $t('SIDEBAR.TRAINING_GROUND.SEARCH_TITLE') }}
      </div>
      <!-- Search conversation -->
      <SearchView
        is-on-page
        is-message-only
        is-fluid-width
        :show-back-button="false"
        @click-message="content => getMessageContent(content)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrainingEditor from '../components/TrainingEditor.vue';
import TrainingChat from '../components/TrainingChat.vue';
import SearchView from 'dashboard/modules/search/components/SearchView.vue';
export default {
  components: {
    TrainingEditor,
    TrainingChat,
    SearchView,
  },
  props: {},
  data() {
    return {
      messages: [
        {
          id: '1',
          content: "Hello\n\nHow are you?\n\nLet's catch up soon!",
          created_at: 1722933904,
          message_type: 1,
          message_label: 'Product issue',
          sender: {
            id: '1',
            email: '',
            name: 'John',
            available_name: 'John',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '2',
          content:
            "Reminder\n\nDon't forget the meeting tomorrow at 10 AM.\n\nPlease confirm your attendance.",
          created_at: 1722934200,
          message_type: 0,
          sender: {
            id: '2',
            email: '',
            name: 'Alice',
            available_name: 'Alice',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '3',
          content:
            'Project Update\n\nThe new feature has been deployed.\n\nCheck it out when you have time.',
          created_at: 1722934500,
          message_type: 0,
          sender: {
            id: '3',
            email: '',
            name: 'Bob',
            available_name: 'Bob',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '4',
          content:
            "Lunch Plans\n\nAre we still on for lunch?\n\nI'm thinking about trying the new place downtown.",
          created_at: 1722934800,
          message_type: 1,
          message_label: 'Shipping delay',
          sender: {
            id: '1',
            email: '',
            name: 'John',
            available_name: 'John',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '5',
          content:
            "Invitation\n\nYou're invited to the annual company retreat.\n\nPlease RSVP by Friday.",
          created_at: 1722935100,
          message_type: 1,
          message_label: 'Order inquiry',
          sender: {
            id: '4',
            email: '',
            name: 'Eve',
            available_name: 'Eve',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '6',
          content:
            "Meeting Notes\n\nHere are the notes from today's meeting.\n\nLet me know if anything is missing.",
          created_at: 1722935400,
          message_type: 1,
          message_label: 'Product feedback',
          sender: {
            id: '2',
            email: '',
            name: 'Alice',
            available_name: 'Alice',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '7',
          content:
            "Code Review\n\nCan you review my latest PR?\n\nI'd appreciate your feedback.",
          created_at: 1722935700,
          message_type: 1,
          message_label: 'Order inquiry',
          sender: {
            id: '3',
            email: '',
            name: 'Bob',
            available_name: 'Bob',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '8',
          content:
            'Holiday Greetings\n\nWishing you a wonderful holiday season!\n\nLooking forward to seeing you in the new year.',
          created_at: 1722936000,
          message_type: 0,
          sender: {
            id: '4',
            email: '',
            name: 'Eve',
            available_name: 'Eve',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '9',
          content:
            'Team Outing\n\nAre you coming to the team outing this weekend?\n\nIt should be a lot of fun!',
          created_at: 1722936300,
          message_type: 1,
          message_label: 'Payment problem',
          sender: {
            id: '1',
            email: '',
            name: 'John',
            available_name: 'John',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
        {
          id: '10',
          content:
            "Feedback Request\n\nI'd love your input on the latest design mockups.\n\nCan we discuss this later today?",
          created_at: 1722936600,
          message_type: 0,
          sender: {
            id: '2',
            email: '',
            name: 'Alice',
            available_name: 'Alice',
            avatar_url: '',
            type: 'user',
            thumbnail: '',
          },
        },
      ],
      selectedMessage: {},
    };
  },
  computed: {
    ...mapGetters({
      messageRecords: 'conversationSearch/getMessageRecords',
    }),
    messageData() {
      return this.messageRecords.map(message => ({
        ...message,
        type: 'message',
      }));
    },
  },
  mounted() {},
  methods: {
    handleSend(val) {
      this.messages.push(val);
    },
    getMessageContent(messageId) {
      this.selectedMessage = this.messageData.filter(
        item => item.id === messageId
      );

      this.messages.push(this.selectedMessage[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.training {
  @apply flex flex-row flex-shrink min-w-0 overflow-auto h-full bg-neutral dark:bg-neutral-dark;

  &__item {
    @apply w-[60%] border-r border-secondary-medium dark:border-neutral-dark-medium bg-neutral-subtle dark:bg-neutral-dark-subtle;
  }

  &__item:last-child {
    @apply w-[40%] p-4 bg-neutral dark:bg-primary h-full overflow-y-auto;
  }
}
</style>
