<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <li
      class="submenu-item"
      :class="{
        'submenu-item-active': isActive,
        'submenu-item-truncated': shouldTruncate,
      }"
      @click="navigate"
    >
      <a :href="href" class="submenu-item-link">
        <span v-if="icon" class="submenu-item-inner">
          <fluent-icon
            class="submenu-item-icon"
            :class="{
              'subtitle-item-icon-active': isActive,
            }"
            :icon="icon"
            :size="isMessengerIcon ? 16 : 14"
            :viewBox="isMessengerIcon ? '-4 -4 24 24' : '0 0 24 24'"
          />
        </span>

        <span
          v-if="labelColor"
          class="submenu-item-label"
          :style="{ backgroundColor: labelColor }"
        />
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
        >
          <span
            :title="menuTitle"
            class="subtitle-item-text"
            :class="{
              'submenu-item-text-active': isActive,
              'submenu-item-text-truncated': shouldTruncate,
            }"
          >
            {{ label }}
          </span>
          <span
            v-if="showChildCount"
            class="bg-slate-50 dark:bg-slate-700 rounded text-xxs font-medium mx-1 py-0 px-1"
            :class="
              isCountZero
                ? `text-slate-300 dark:text-slate-700`
                : `text-slate-700 dark:text-slate-50`
            "
          >
            {{ childItemCount }}
          </span>
        </div>
        <span
          v-if="warningIcon"
          class="inline-flex rounded-sm mr-1 p-[1px] bg-tertiary dark:bg-primary"
        >
          <fluent-icon
            v-tooltip.top-end="$t('SIDEBAR.REAUTHORIZE')"
            class="text-xxs text-warning"
            :icon="warningIcon"
            size="12"
          />
        </span>
      </a>
    </li>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    isCountZero() {
      return this.childItemCount === 0;
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
    // somehow messenger icon not aligned properly, need to adjust it
    // TODO: need proper svg icon
    isMessengerIcon() {
      return this.icon === 'brand-messenger';
    },
  },
};
</script>

<style lang="scss" scoped>
.submenu-item {
  @apply font-medium h-7 my-1 hover:bg-violet-700 hover:text-slate-100 flex items-center px-2 rounded-md dark:hover:bg-slate-700;
}
.submenu-item-active {
  @apply bg-violet-700 dark:bg-violet-700;
}
.submenu-item-truncated {
  @apply text-ellipsis overflow-hidden whitespace-nowrap max-w-full;
}
.submenu-item-inner {
  @apply inline-flex items-center justify-center rounded-sm mr-1.5 rtl:mr-0 rtl:ml-1.5 w-[18px] h-[16px] relative;
}
.submenu-item-icon {
  @apply text-xxs text-primary-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
.submenu-item-icon-active {
  @apply text-primary-dark;
}
.subtitle-item-label {
  @apply inline-flex rounded-sm bg-slate-100 h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900;
}
.submenu-item-link {
  @apply inline-flex text-left max-w-full w-full items-center text-slate-200 dark:text-slate-200;
}
.submenu-item-text {
  @apply text-sm text-slate-200 dark:text-slate-200;
}
.submenu-item-text-active {
  @apply text-slate-100 dark:text-slate-100;
}
.submenu-item-text-truncated {
  @apply text-ellipsis overflow-hidden whitespace-nowrap max-w-full;
}

::v-deep {
  .submenu-item-icon,
  .submenu-item-icon.dark,
  .submenu-item-icon.dark-os {
    path {
      // if first path have color there is border outside.
      // icon that affected: `brand-meta`, `brand-mail`, `shopify-globe`
      &:first-child {
        @apply stroke-none;
      }
      @apply stroke-primary-dark;
    }
  }
}
</style>
