<template>
  <div
    class="rounded-md px-4 py-2 bg-neutral dark:bg-neutral-dark border border-neutral-medium dark:border-neutral-dark-medium"
  >
    <div class="flex flex-row">
      <div class="flex-1">
        <h6 class="mb-1 text-sm text-primary dark:text-primary-dark">
          {{ heading }}
        </h6>

        <p class="flex-1 text-secondary text-xs mb-0 dark:text-secondary-dark">
          {{ subheading }}
        </p>
      </div>

      <a
        href="#toggle"
        class="self-center !text-accent"
        @click.prevent="toggle()"
      >
        <Icon
          size="20"
          :icon="isExpanded ? 'subtract' : 'add'"
          type="regular"
          :icons="iconLib"
        />
      </a>
    </div>

    <div class="inner" :class="{ 'pt-7': isExpanded }">
      <div v-show="isExpanded">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../../../../../shared/components/FluentIcon/Icon.vue';
import icons from '../../../../../../shared/components/FluentIcon/icons.json';

export default {
  components: {
    Icon,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.expanded,
    };
  },
  computed: {
    iconLib() {
      return icons;
    },
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped></style>
