import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import Index from './Index';
import Status from './Status';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/shops'),
      component: SettingsContent,
      props: {
        headerTitle: 'SHOP_MGMT.HEADER',
        icon: 'link',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'shops_wrapper',
          roles: ['administrator'],
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'shops_list',
          roles: ['administrator'],
          component: Index,
        },
        {
          path: 'status',
          name: 'shops_status',
          roles: ['administrator'],
          component: Status,
        },
      ],
    },
  ],
};
