<template>
  <div>
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.DESC')"
    />
    <!-- Form -->
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <!-- Store name -->
      <div class="w-full flex-shrink-0 flex-grow-0 mb-6">
        <label>
          <div class="text-sm mb-1 text-primary dark:text-primary-dark">
            {{ $t('INBOX_MGMT.ADD.SHOPIFY.CONNECTED_SHOP') }}
          </div>
          <select v-model="shopId" class="mb-0">
            <option
              v-for="shop in account.shops"
              :key="shop.shopify_domain"
              :value="shop.id"
            >
              {{ shop.shopify_domain }}
            </option>
          </select>
        </label>
      </div>

      <!-- Email address -->
      <div class="w-full flex-shrink-0 flex-grow-0 mb-6">
        <label
          :class="$v.email.$error || isUsePublicDomain ? 'error' : undefined"
        >
          <div class="mb-1text-sm text-primary dark:text-primary-dark">
            {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.LABEL') }}
          </div>
          <input
            v-model.trim="email"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.PLACEHOLDER')"
            class="mb-0"
            @blur="$v.email.$touch"
          />
          <div
            v-if="isUsePublicDomain"
            class="text-sm mt-1 text-error dark:text-error-dark"
          >
            {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.ERROR_PUBLIC_DOMAIN') }}
          </div>
          <div
            v-if="$v.email.$error"
            class="text-sm mt-1 text-error dark:text-error-dark"
          >
            {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.ERROR') }}
          </div>
        </label>
        <div
          v-dompurify-html="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.EMAIL.SUBTITLE')"
          class="mt-1 text-sm text-secondary dark:text-secondary-dark"
        />
      </div>

      <!-- Inbox name -->
      <div class="w-full flex-shrink-0 flex-grow-0 mb-6">
        <label :class="{ error: $v.channelName.$error }">
          <div class="mb-1text-sm text-primary dark:text-primary-dark">
            {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.LABEL') }}
          </div>
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            class="mb-0"
            @blur="$v.channelName.$touch"
          />
          <div
            v-if="$v.channelName.$error"
            class="text-sm mt-1 text-error dark:text-error-dark"
          >
            {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.ERROR') }}
          </div>
        </label>
        <div class="text-sm mt-1 text-secondary dark:text-secondary-dark">
          {{ $t('INBOX_MGMT.ADD.EMAIL_CHANNEL.CHANNEL_NAME.SUBTITLE') }}
        </div>
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required, email } from 'vuelidate/lib/validators';
import router from '../../../../../index';
import PageHeader from '../../../SettingsSubPageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      shopId: '',
      channelName: '',
      email: '',
      alertMessage: '',
      publicDomain: [
        'gmail.com',
        'yahoo.com',
        'outlook.com',
        'icloud.com',
        'aol.com',
        'protonmail.com',
        'zoho.com',
        'mail.com',
        'gmx.com',
      ],
      isUsePublicDomain: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
  },
  validations: {
    channelName: { required },
    email: { required, email },
    shopId: { required },
  },
  watch: {
    email(val) {
      const emailDomain = val.split('@')[1];
      if (this.publicDomain.includes(emailDomain)) {
        this.isUsePublicDomain = true;
      } else {
        this.isUsePublicDomain = false;
      }
    },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid || this.isUsePublicDomain) {
        return;
      }

      try {
        const emailChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.channelName,
            channel: {
              type: 'email',
              email: this.email,
              shop_id: this.shopId,
            },
          }
        );
        router.replace({
          name: 'settings_inboxes_email_provider_auth',
          params: {
            page: 'new',
            inbox_id: emailChannel.id,
          },
          query: {
            last_step: 1,
            flow_type: '',
          },
        });
        await this.$store.dispatch('inboxes/setDnsProcessStep', {
          inboxId: emailChannel.id,
          lastStep: 1,
          flowType: '',
        });
      } catch (error) {
        const errorMessage = error?.message;
        this.alertMessage =
          errorMessage ||
          this.$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.API.ERROR_MESSAGE');
        this.showAlert(this.alertMessage);
      }
    },
  },
};
</script>
