<template>
  <div
    class="border border-neutral-high dark:border-neutral-dark-high rounded-lg flex items-center px-3 py-2 mb-2"
    :class="{
      'border-neutral-subtle dark:border-neutral-dark-subtle': selected,
      'bg-neutral-high dark:bg-neutral-dark-high': selected,
    }"
    @click="$emit('toggleSelected')"
  >
    <input
      type="checkbox"
      class="mr-3 my-0"
      :checked="selected"
      :disabled="disabled"
    />

    <div class="flex-1">
      {{ article.title }}
    </div>

    <span
      class="bg-neutral-medium dark:bg-neutral-dark-medium text-secondary dark:text-secondary-dark text-xs font-medium me-2 px-1 py-0.5 rounded-md"
    >
      {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_MODAL.HELP_ARTICLE') }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  width: 1.125rem;
  height: 1.125rem;
}
</style>
