<template>
  <div
    class="bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-300 border-[#E4E3E6] dark:border-[#2F3336] border-l rtl:border-l-0 rtl:border-r contact--panel overflow-y-auto"
  >
    <new-contact-info
      :contact="contact"
      :customer="customerData"
      :previous-conversations="prevConvCount"
      :total-spent="getTotalSpent"
    />
    <shopify-panel :chat="currentChat" />
    <div class="border-t border-[#E4E3E6] dark:border-[#2F3336] px-6 py-4">
      <NewConversationAction
        :conversation-id="conversationId"
        :inbox-id="inboxId"
      />
      <new-conversation-participant
        class="mb-4"
        :conversation-id="conversationId"
        :inbox-id="inboxId"
      />
      <new-label-box :conversation-id="conversationId" />
    </div>
    <div class="border-t border-[#E4E3E6] dark:border-[#2F3336] px-6 py-4">
      <div class="text-[#868E96] text-sm">Macros</div>
      <woot-feature-toggle feature-key="macros">
        <macros-list :conversation-id="conversationId" />
      </woot-feature-toggle>
    </div>
    <draggable
      :list="conversationSidebarItems"
      :disabled="!dragEnabled"
      animation="200"
      class="list-group"
      ghost-class="ghost"
      handle=".drag-handle"
      @start="dragging = true"
      @end="onDragEnd"
    >
      <transition-group>
        <div
          v-for="element in conversationSidebarItems"
          :key="element.name"
          class="bg-white dark:bg-gray-800"
        >
          <div v-if="element.name === 'orders'">
            <accordion-item
              v-if="hasCustomerData"
              title="Orders"
              :counter="orderCount"
              is-has-counter
              :is-open="isContactSidebarItemOpen('is_orders_open')"
              @click="value => toggleSidebarUIState('is_orders_open', value)"
            >
              <!-- KPI -->
              <div class="mb-4">
                <div class="flex gap-x-2">
                  <div class="kpi">
                    <div class="kpi__headline">
                      {{ orderCount }}
                    </div>
                    <div class="kpi__tagline">Total orders</div>
                  </div>
                  <div class="kpi">
                    <div class="kpi__headline">
                      {{ getTotalSpent }}
                    </div>
                    <div class="kpi__tagline">Order total</div>
                  </div>
                </div>
              </div>

              <div v-for="(order, index) in orderData" :key="index">
                <shopify-accordion-item
                  :title="order.name"
                  :fulfillment-status="
                    order.fulfillment_status || 'unfulfilled'
                  "
                  :payment-status="order.financial_status"
                  :is-open="
                    isContactSidebarItemOpen(`shopify_order_${order.id}`)
                  "
                  @click="
                    value =>
                      toggleSidebarUIState(`shopify_order_${order.id}`, value)
                  "
                  :cancelled="!!order.cancelled_at"
                >
                  <shopify-order-info
                    :order="order"
                    :link="customerData.shop"
                  />
                </shopify-accordion-item>
              </div>
            </accordion-item>
          </div>

          <div v-else-if="element.name === 'klaviyo_data'">
            <accordion-item
              v-if="hasKlaviyoData"
              :title="$t('KLAVIYO.KLAVIYO')"
              :is-open="isContactSidebarItemOpen('is_klaviyo_data_open')"
              icon="klaviyo-icon"
              @click="
                value => toggleSidebarUIState('is_klaviyo_data_open', value)
              "
            >
              <klaviyo-panel :chat="currentChat" />
            </accordion-item>
          </div>

          <div v-else-if="element.name === 'previous_conversation'">
            <accordion-item
              title="Conversations"
              :counter="prevConvCount"
              is-has-counter
              :is-open="isContactSidebarItemOpen('is_conversations_open')"
              @click="
                value => toggleSidebarUIState('is_conversations_open', value)
              "
            >
              <contact-conversations
                :contact-id="contact.id"
                :conversation-id="conversationId"
              />
            </accordion-item>
          </div>
          <div v-else-if="element.name === 'conversation_info'">
            <accordion-item
              title="Info"
              :is-open="isContactSidebarItemOpen('is_conversation_info_open')"
              @click="
                value =>
                  toggleSidebarUIState('is_conversation_info_open', value)
              "
            >
              <conversation-info
                :conversation-attributes="conversationAdditionalAttributes"
                :contact-attributes="contactAdditionalAttributes"
              />
            </accordion-item>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordionItem from 'dashboard/components/Accordion/AccordionItem.vue';
import ContactConversations from './ContactConversations.vue';
import ConversationInfo from './ConversationInfo.vue';
import draggable from 'vuedraggable';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import MacrosList from './Macros/List.vue';
import ShopifyPanel from './ShopifyPanel.vue';
import KlaviyoPanel from './KlaviyoPanel.vue';
import ShopifyOrderInfo from './shopify/ShopifyOrderInfo.vue';
import ShopifyAccordionItem from 'dashboard/components/Accordion/ShopifyAccordionItem';
import shopifyMixin from 'dashboard/mixins/shopifyMixin';

import NewContactInfo from './contact/NewContactInfo.vue';
import NewConversationAction from './NewConversationAction.vue';
import NewConversationParticipant from './NewConversationParticipant.vue';
import NewLabelBox from './labels/NewLabelBox.vue';

export default {
  components: {
    AccordionItem,
    ContactConversations,
    ConversationInfo,
    draggable,
    MacrosList,
    ShopifyPanel,
    ShopifyOrderInfo,
    ShopifyAccordionItem,
    NewContactInfo,
    NewConversationAction,
    NewConversationParticipant,
    NewLabelBox,
    KlaviyoPanel,
  },
  mixins: [uiSettingsMixin, shopifyMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      dragEnabled: true,
      conversationSidebarItems: [],
      dragging: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      uiSettings: 'getUISettings',
    }),
    orderCount() {
      return this.currentChat?.shopify_data
        ? this.currentChat?.shopify_data?.orders?.length
        : 0;
    },
    conversationAdditionalAttributes() {
      return this.currentConversationMetaData.additional_attributes || {};
    },
    channelType() {
      return this.currentChat?.meta?.channel;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contactAdditionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    contactId() {
      return this.currentChat?.meta?.sender?.id;
    },
    currentConversationMetaData() {
      return this.$store.getters[
        'conversationMetadata/getConversationMetadata'
      ](this.conversationId);
    },
    customerData() {
      return this.currentChat?.shopify_data?.customer || {};
    },
    orderData() {
      return this.currentChat?.shopify_data?.orders || {};
    },
    conversations() {
      return this.$store.getters['contactConversations/getContactConversation'](
        this.contactId
      );
    },
    previousConversations() {
      return this.conversations.filter(
        conversation => conversation.id !== Number(this.conversationId)
      );
    },
    prevConvCount() {
      return this.previousConversations
        ? this.previousConversations?.length
        : 0;
    },
    getTotalSpent() {
      const currency = this.customerData.currency || 'USD';
      const totalSpent = this.customerData.total_spent || 0;

      return `${this.currency_symbol(currency)}${this.moneyFormat(
        totalSpent,
        currency
      )}`;
    },
    hasCustomerData() {
      return Boolean(
        this.customerData && Object.keys(this.customerData).length
      );
    },
    hasKlaviyoData() {
      return !!this.currentChat.klaviyo_data;
    },
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId && newConversationId !== prevConversationId) {
        this.getContactDetails();
      }
    },
    contactId() {
      this.getContactDetails();
    },
  },
  mounted() {
    this.conversationSidebarItems = this.conversationSidebarItemsOrder;
    this.getContactDetails();
    this.$store.dispatch('attributes/get', 0);

    bus.$on('fetch_shopify_stats', () => {
      this.$store.dispatch('shopifyStats/get', this.conversationFilters);
    });
    this.$store.dispatch('contactConversations/get', this.contactId);
  },
  methods: {
    getContactDetails() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    getAttributesByModel() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    openTranscriptModal() {
      this.showTranscriptModal = true;
    },

    onDragEnd() {
      this.dragging = false;
      this.updateUISettings({
        conversation_sidebar_items_order: this.conversationSidebarItems,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .contact--profile {
    @apply pb-3 border-b border-solid border-slate-75 dark:border-slate-700;
  }
  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      @apply box-border pl-6;
    }
    .multiselect__element {
      span {
        @apply w-full;
      }
    }
  }
}

.kpi {
  @apply flex flex-col basis-1 grow bg-neutral-subtle dark:bg-neutral-dark rounded-lg px-3 py-2;

  &__headline {
    @apply text-xl text-primary dark:text-stategrey;
  }

  &__tagline {
    @apply text-[13px] text-[#868E96];
  }
}
</style>
