<template>
  <div class="relative bg-white dark:bg-slate-900">
    <div class="cell mb-4">
      <div class="cell__item">
        {{ $t('CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS') }}
      </div>
      <div class="cell__item">
        <div class="cell__link" @click="onOpenDropdown">
          <div class="flex justify-end items-center gap-2">
            <thumbnail-group
              v-if="isUserWatching"
              :more-thumbnails-text="moreThumbnailsText"
              :show-more-thumbnails-count="showMoreThumbs"
              :users-list="thumbnailList"
            />
            <div v-else>
              {{ $t('CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-on-clickaway="
        () => {
          onCloseDropdown();
        }
      "
      :class="{ 'dropdown-pane--open': showDropDown }"
      class="dropdown-pane"
    >
      <div class="flex justify-between items-center mb-1">
        <h4
          class="text-sm m-0 overflow-hidden whitespace-nowrap text-ellipsis text-slate-800 dark:text-slate-100"
        >
          {{ $t('CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS') }}
        </h4>
        <woot-button
          icon="dismiss"
          size="tiny"
          color-scheme="secondary"
          variant="clear"
          @click="onCloseDropdown"
        />
      </div>
      <multiselect-dropdown-items
        :options="agentsList"
        :selected-items="selectedWatchers"
        :has-thumbnail="true"
        @click="onClickItem"
      />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import agentMixin from 'dashboard/mixins/agentMixin';
import ThumbnailGroup from 'dashboard/components/widgets/ThumbnailGroup.vue';
import MultiselectDropdownItems from 'shared/components/ui/MultiselectDropdownItems.vue';

export default {
  components: {
    ThumbnailGroup,
    MultiselectDropdownItems,
  },
  mixins: [alertMixin, agentMixin, clickaway],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      selectedWatchers: [],
      showDropDown: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    watchersFromStore() {
      return this.$store.getters['conversationWatchers/getByConversationId'](
        this.conversationId
      );
    },
    watchersList: {
      get() {
        return this.selectedWatchers;
      },
      set(participants) {
        this.selectedWatchers = [...participants];
        const userIds = participants.map(el => el.id);
        this.updateParticipant(userIds);
      },
    },
    isUserWatching() {
      return this.selectedWatchers.some(
        watcher => watcher.id === this.currentUser.id
      );
    },
    thumbnailList() {
      return this.selectedWatchers.slice(0, 4);
    },
    moreAgentCount() {
      const maxThumbnailCount = 4;
      return this.watchersList.length - maxThumbnailCount;
    },
    moreThumbnailsText() {
      if (this.moreAgentCount > 1) {
        return this.$t('CONVERSATION_PARTICIPANTS.REMANING_PARTICIPANTS_TEXT', {
          count: this.moreAgentCount,
        });
      }
      return this.$t('CONVERSATION_PARTICIPANTS.REMANING_PARTICIPANT_TEXT', {
        count: 1,
      });
    },
    showMoreThumbs() {
      return this.moreAgentCount > 0;
    },
    totalWatchersText() {
      if (this.selectedWatchers.length > 1) {
        return this.$t('CONVERSATION_PARTICIPANTS.TOTAL_PARTICIPANTS_TEXT', {
          count: this.selectedWatchers.length,
        });
      }
      return this.$t('CONVERSATION_PARTICIPANTS.TOTAL_PARTICIPANT_TEXT', {
        count: 1,
      });
    },
  },
  watch: {
    conversationId() {
      this.fetchParticipants();
    },
    watchersFromStore(participants = []) {
      this.selectedWatchers = [...participants];
    },
  },
  mounted() {
    this.fetchParticipants();
    this.$store.dispatch('agents/get');
  },
  methods: {
    fetchParticipants() {
      const conversationId = this.conversationId;
      this.$store.dispatch('conversationWatchers/show', { conversationId });
    },
    async updateParticipant(userIds) {
      const conversationId = this.conversationId;
      let alertMessage = this.$t(
        'CONVERSATION_PARTICIPANTS.API.SUCCESS_MESSAGE'
      );

      try {
        await this.$store.dispatch('conversationWatchers/update', {
          conversationId,
          userIds,
        });
      } catch (error) {
        alertMessage =
          error?.message ||
          this.$t('CONVERSATION_PARTICIPANTS.API.ERROR_MESSAGE');
      } finally {
        this.showAlert(alertMessage);
      }
      this.fetchParticipants();
    },
    onOpenDropdown() {
      this.showDropDown = true;
    },
    onCloseDropdown() {
      this.showDropDown = false;
    },
    onClickItem(agent) {
      const isAgentSelected = this.watchersList.some(
        participant => participant.id === agent.id
      );

      if (isAgentSelected) {
        const updatedList = this.watchersList.filter(
          participant => participant.id !== agent.id
        );

        this.watchersList = [...updatedList];
      } else {
        this.watchersList = [...this.watchersList, agent];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-pane {
  @apply box-border top-[2rem] w-full;
}

.cell {
  @apply flex items-center justify-between;

  &__item {
    @apply basis-[40%] text-sm text-[#868E96] leading-tight;

    &:last-child {
      @apply text-right basis-[60%];
    }
  }

  &__link {
    @apply text-accent text-xs transition-all ease-in-out duration-200 cursor-pointer font-normal inline-block;

    &:hover {
      @apply text-primary dark:text-stategrey underline;
    }
  }
}
</style>
