import { render, staticRenderFns } from "./NewContactInfo.vue?vue&type=template&id=945a50d6&scoped=true&"
import script from "./NewContactInfo.vue?vue&type=script&lang=js&"
export * from "./NewContactInfo.vue?vue&type=script&lang=js&"
import style0 from "./NewContactInfo.vue?vue&type=style&index=0&id=945a50d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "945a50d6",
  null
  
)

export default component.exports