import Vue from 'vue';
import types from '../mutation-types';
import InboxChatResourcesApi from '../../api/inbox/inboxChatResources';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import inboxChatResources from '../../api/inbox/inboxChatResources';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getResources(_state) {
    return _state.records;
  },
  getActiveResources(_state) {
    return _state.records.filter(resource => resource.active);
  },
};

export const actions = {
  get: async function getInboxChatResources({ commit }, inboxId) {
    commit(types.SET_INBOX_CHAT_RESOURCES_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxChatResourcesApi.get({ inboxId: inboxId });
      commit(types.SET_INBOX_CHAT_RESOURCES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_INBOX_CHAT_RESOURCES_UI_FLAG, { isFetching: false });
    }
  },
  toggleActive: async function toggleInboxChatResourceActive({ commit }, { inboxChatResource }) {
    const payload = Object.assign({}, inboxChatResource);
    delete payload.resource;
    payload.active = !payload.active;

    try {
      const response = await InboxChatResourcesApi.update(payload);
      commit(types.SET_INBOX_CHAT_RESOURCE, response.data);
    } catch (error) {
      // Ignore error
    }
  },
  destroy: async function destroyInboxChatResource({ commit }, { inboxChatResource }) {
    try {
      const response = await InboxChatResourcesApi.destroy(inboxChatResource);
      commit(types.DESTROY_INBOX_CHAT_RESOURCE, inboxChatResource.id);
    } catch (error) {
      // Ignore error
    }
  },
  updateSequence: async function updateSequenceForAll({ commit, state }) {
    try {
      state.records.forEach(async (inboxChatResource, i) => {
        if (inboxChatResource.sequence_number !== i) {
          const payload = {
            id: inboxChatResource.id,
            inbox_id: inboxChatResource.inbox_id,
            sequence_number: i
          };

          const response = await InboxChatResourcesApi.update(payload);
          commit(types.SET_INBOX_CHAT_RESOURCE, response.data);
        }
      });
    } catch (error) {
      // Ignore error
    }
  },
  createResources: async function createInboxChatResources({ commit }, { inboxId, articles, workflows }) {
    const articleIds = Object.assign([], articles).map(article => article.id);
    const workflowIds = Object.assign([], workflows).map(workflow => workflow.id);

    try {
      const response = await InboxChatResourcesApi.createBulk({ inboxId, articleIds, workflowIds });
      commit(types.ADD_INBOX_CHAT_RESOURCES, { resources: response.data });
    } catch (error) {
      // Ignore error
    }
  },
};

export const mutations = {
  [types.ADD_INBOX_CHAT_RESOURCES]: (_state, { resources }) => {
    resources.forEach(resource => _state.records.push(resource));
  },
  [types.SET_INBOX_CHAT_RESOURCES_UI_FLAG](_state, uiFlags) {
    state.uiFlags = { ..._state.uiFlags, ...uiFlags };
  },
  [types.SET_INBOX_CHAT_RESOURCES]: MutationHelpers.set,
  [types.SET_INBOX_CHAT_RESOURCE]: MutationHelpers.update,
  [types.DESTROY_INBOX_CHAT_RESOURCE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
