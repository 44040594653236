<template>
  <div
    ref="popoverTrigger"
    class="popover"
    :class="{ 'popover--active': showPopover }"
  >
    <button class="popover__icon" @click="handlePopover">
      <fluent-icon
        :icon="iconName"
        type="outline"
        :size="iconSize"
        :view-box="iconViewBox"
      />
    </button>
    <div v-if="showPopover" ref="popoverWrapper" class="popover__dropdown">
      <button
        v-for="(item, index) in menuItems"
        :key="index"
        class="popover__dropdown-item"
        @click="handleMenuClick(item.name)"
      >
        <div
          class="popover__dropdown-text"
          :class="{ 'popover__dropdown-text--danger': item.type === 'danger' }"
        >
          {{ item.name }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuItems: {
      type: Array,
      default: () => [
        {
          name: '',
          type: '',
        },
      ],
    },
    iconName: {
      type: String,
      default: 'kebab',
    },
    iconViewBox: {
      type: String,
      default: '0 0 26 26',
    },
    iconSize: {
      type: String,
      default: '26',
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  mounted() {
    window.addEventListener('mouseup', this.onOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('mouseup', this.onOutsideClick);
  },
  methods: {
    handlePopover() {
      this.showPopover = !this.showPopover;
    },
    closePopover() {
      this.showPopover = false;
    },
    onOutsideClick(e) {
      if (
        this.showPopover &&
        e.target !== this.$refs.popoverWrapper &&
        !this.$refs.popoverWrapper.contains(e.target) &&
        !this.$refs.popoverTrigger.contains(e.target)
      ) {
        this.closePopover();
      }
    },
    handleMenuClick(name) {
      this.closePopover();
      this.$emit('click', name);
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  @apply relative select-none;

  &__icon {
    @apply z-10 relative w-[32px] h-[32px] border border-solid border-transparent rounded-[2px] cursor-pointer;

    &:hover {
      @apply bg-neutral-subtle dark:bg-neutral-dark-subtle border-secondary-medium dark:border-neutral-dark-medium;
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-[45%] -translate-y-[48%];
    }
  }

  &__dropdown {
    @apply cursor-default w-[245px] absolute top-[31px] right-0 bg-neutral rounded-lg shadow-dropdown select-none dark:bg-neutral-dark-subtle overflow-hidden border border-secondary-medium dark:border-neutral-dark-medium p-1 z-[11];

    &-item {
      @apply px-4 py-3 w-full cursor-pointer text-left rounded-[4px] capitalize;

      &:hover {
        @apply bg-neutral-subtle dark:bg-neutral-dark-medium;
      }
    }

    &-text {
      @apply text-sm text-primary block dark:text-stategrey;

      &--danger {
        @apply text-error-medium dark:text-error-dark-medium;
      }
    }
  }

  &--active {
    .popover__icon {
      @apply relative z-[12] bg-white dark:bg-neutral-dark-subtle border-secondary-medium dark:border-neutral-dark-medium border-b-transparent rounded-b-none;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 1px);
        height: 1px;
        @apply bg-white dark:bg-neutral-dark-subtle;
      }
    }

    .popover__dropdown {
      @apply rounded-tr-none;
    }
  }
}
</style>
