<template>
  <div
    v-if="resources.length > 0"
    class="popular-link"
    :class="{
      'px-3 pt-3': !containedInPreview,
      'px-0 pt-3': containedInPreview,
    }"
  >
    <div class="popular-link__inner">
      <div class="popular-link__title">
        {{ $t('RESOURCE_LIST.POPULAR_LINKS') }}
      </div>
      <ul :class="{ 'ml-0 mb-0': containedInPreview }">
        <li
          v-for="(resource, index) in resources"
          :key="resource.id"
          class="popular-link__list"
          @mouseover="handleMouseOver(index)"
          @mouseleave="handleMouseLeave(index)"
        >
          <div
            class="popular-link__item"
            @click.prevent="onResourceClicked(resource)"
          >
            <span ref="listText" class="flex-1 popular-link__item-text">{{
              resourceTitle(resource)
            }}</span>
            <Icon
              ref="listIcon"
              class="popular-link__icon"
              size="16"
              icon="arrow-right-new"
              view-box="0 0 16 16"
              :icons="iconLib"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '../../shared/components/FluentIcon/Icon.vue';
import icons from '../../shared/components/FluentIcon/icons.json';
import {
  PRIMARY,
  SECONDARY,
  ACCENT,
} from '../../../javascript/widget/constants/colors.js';

const WIDGET = 'widget';
const PREVIEW = 'preview';

export default {
  components: {
    Icon,
  },
  props: {
    containedIn: {
      type: String,
      default: WIDGET,
    },
    color: {
      type: String,
      default: ACCENT,
    },
  },
  computed: {
    ...mapGetters({
      resources: 'inboxChatResources/getActiveResources',
      widgetColor: 'appConfig/getWidgetColor',
    }),
    iconLib() {
      return icons;
    },
    containedInPreview() {
      return this.containedIn === PREVIEW;
    },
    computedColor() {
      if (this.containedIn === WIDGET) return this.widgetColor;
      return this.color;
    },
  },
  created() {
    if (this.containedIn === WIDGET) {
      this.$store.dispatch(
        'inboxChatResources/get',
        window.chatwootWebChannel.websiteToken
      );
    }
  },
  methods: {
    resourceTitle(resource) {
      if (resource.resource_type === 'Article') {
        return resource.resource.title;
      }
      return resource.resource.name;
    },
    onResourceClicked(resource) {
      if (resource.resource_type === 'Article') {
        this.$emit('view-article', resource.resource.api_url);
      } else if (resource.resource_type === 'Workflow') {
        this.$emit('start-flow', resource.resource.id);
      }
    },
    handleMouseOver(index) {
      const text = this.$refs.listText[index];
      const icon = this.$refs.listIcon[index];
      if (text && icon) {
        text.style.color = this.computedColor;
        this.$nextTick(() => {
          icon.$el.style.color = this.computedColor;
        });
      }
    },
    handleMouseLeave(index) {
      const text = this.$refs.listText[index];
      const icon = this.$refs.listIcon[index];
      if (text && icon) {
        text.style.color = PRIMARY;
        this.$nextTick(() => {
          icon.$el.style.color = SECONDARY;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-link {
  @apply w-full;

  &__inner {
    @apply shadow-sm
    rounded-md
    bg-neutral
    overflow-hidden;
  }

  &__title {
    @apply text-sm
    font-semibold
    px-5
    py-4
    text-primary;
  }

  &__list {
    @apply border-t
    border-solid
    border-lightgray
    flex
    content-center
    cursor-pointer
    px-5
    py-3
    transition-all
    ease-in-out
    duration-200;

    &:hover {
      @apply bg-neutral-subtle;
    }
  }

  &__item {
    @apply flex
    items-center
    justify-between
    w-full
    text-sm;
  }

  &__item-text {
    @apply text-primary
    transition-all
    ease-in-out
    duration-200;
  }

  &__icon {
    @apply ml-3
    transition-all
    ease-in-out
    duration-200
    text-secondary;
  }
}
</style>
