<template>
  <div>
    <ul
      ref="dropdownMenu"
      class="dropdown menu vertical"
      :class="[placement && `dropdown--${placement}`]"
    >
      <!-- slot for items -->
      <slot />
    </ul>
    <!-- slot for action button -->
    <div v-if="hasActionButton" class="action-button">
      <slot name="actionButton" />
    </div>
  </div>
</template>
<script>
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import {
  hasPressedArrowUpKey,
  hasPressedArrowDownKey,
} from 'shared/helpers/KeyboardHelpers';
export default {
  name: 'WootDropdownMenu',
  componentName: 'WootDropdownMenu',

  mixins: [eventListenerMixins],

  props: {
    placement: {
      type: String,
      default: 'top',
    },
  },
  computed: {
    hasActionButton() {
      return !!this.$slots.actionButton;
    },
  },
  methods: {
    dropdownMenuButtons() {
      return this.$refs.dropdownMenu.querySelectorAll(
        'ul.dropdown li.dropdown-menu__item .button'
      );
    },
    activeElementIndex() {
      const menuButtons = this.dropdownMenuButtons();
      const focusedButton = this.$refs.dropdownMenu.querySelector(
        'ul.dropdown li.dropdown-menu__item .button:focus'
      );
      const activeIndex = [...menuButtons].indexOf(focusedButton);
      return activeIndex;
    },
    handleKeyEvents(e) {
      const menuButtons = this.dropdownMenuButtons();
      const lastElementIndex = menuButtons.length - 1;

      if (menuButtons.length === 0) return;

      if (hasPressedArrowUpKey(e)) {
        const activeIndex = this.activeElementIndex();

        if (activeIndex >= 1) {
          menuButtons[activeIndex - 1].focus();
        } else {
          menuButtons[lastElementIndex].focus();
        }
      }
      if (hasPressedArrowDownKey(e)) {
        const activeIndex = this.activeElementIndex();

        if (activeIndex === lastElementIndex) {
          menuButtons[0].focus();
        } else {
          menuButtons[activeIndex + 1].focus();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  @apply sticky bottom-0 left-0 z-10 bg-white dark:bg-[#26292b] pt-1 pb-0 border-t border-neutral-high dark:border-neutral-dark-high;
}
</style>
