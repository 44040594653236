import MessageApi from '../../../../api/inbox/message';
import { throwErrorMessage } from 'dashboard/store/utils/api';

export default {
  async likeComment(_, { conversationId, messageId }) {
    try {
      const data = await MessageApi.likeComment(conversationId, messageId);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    }
  },

  async unlikeComment(_, { conversationId, messageId }) {
    try {
      const data = await MessageApi.unlikeComment(conversationId, messageId);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    }
  },

  async hideComment(_, { conversationId, messageId }) {
    try {
      const data = await MessageApi.hideComment(conversationId, messageId);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    }
  },

  async unhideComment(_, { conversationId, messageId }) {
    try {
      const data = await MessageApi.unhideComment(conversationId, messageId);
      return data;
    } catch (error) {
      throwErrorMessage(error);
    }
  },
};
