var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":"","active-class":"active"},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('li',{staticClass:"submenu-item",class:{
      'submenu-item-active': isActive,
      'submenu-item-truncated': _vm.shouldTruncate,
    },on:{"click":navigate}},[_c('a',{staticClass:"submenu-item-link",attrs:{"href":href}},[(_vm.icon)?_c('span',{staticClass:"submenu-item-inner"},[_c('fluent-icon',{staticClass:"submenu-item-icon",class:{
            'subtitle-item-icon-active': isActive,
          },attrs:{"icon":_vm.icon,"size":_vm.isMessengerIcon ? 16 : 14,"viewBox":_vm.isMessengerIcon ? '-4 -4 24 24' : '0 0 24 24'}})],1):_vm._e(),_vm._v(" "),(_vm.labelColor)?_c('span',{staticClass:"submenu-item-label",style:({ backgroundColor: _vm.labelColor })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"},[_c('span',{staticClass:"subtitle-item-text",class:{
            'submenu-item-text-active': isActive,
            'submenu-item-text-truncated': _vm.shouldTruncate,
          },attrs:{"title":_vm.menuTitle}},[_vm._v("\n          "+_vm._s(_vm.label)+"\n        ")]),_vm._v(" "),(_vm.showChildCount)?_c('span',{staticClass:"bg-slate-50 dark:bg-slate-700 rounded text-xxs font-medium mx-1 py-0 px-1",class:_vm.isCountZero
              ? `text-slate-300 dark:text-slate-700`
              : `text-slate-700 dark:text-slate-50`},[_vm._v("\n          "+_vm._s(_vm.childItemCount)+"\n        ")]):_vm._e()]),_vm._v(" "),(_vm.warningIcon)?_c('span',{staticClass:"inline-flex rounded-sm mr-1 p-[1px] bg-tertiary dark:bg-primary"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('SIDEBAR.REAUTHORIZE')),expression:"$t('SIDEBAR.REAUTHORIZE')",modifiers:{"top-end":true}}],staticClass:"text-xxs text-warning",attrs:{"icon":_vm.warningIcon,"size":"12"}})],1):_vm._e()])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }