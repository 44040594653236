const {
  blue,
  blueDark,
  green,
  greenDark,
  yellow,
  yellowDark,
  slate,
  slateDark,
  red,
  redDark,
  violet,
  violetDark,
} = require('@radix-ui/colors');
const defaultTheme = require('tailwindcss/defaultTheme');
module.exports = {
  darkMode: 'class',
  content: [
    './app/javascript/widget/**/*.vue',
    './app/javascript/v3/**/*.vue',
    './app/javascript/dashboard/**/*.vue',
    './app/javascript/portal/**/*.vue',
    './app/javascript/shared/**/*.vue',
    './app/javascript/survey/**/*.vue',
    './app/views/**/*.html.erb',
  ],
  theme: {
    fontSize: {
      ...defaultTheme.fontSize,
      xxs: '0.625rem',
    },
    colors: {
      primary: {
        DEFAULT: '#151718',
        medium: '#1C1E20',
        dark: {
          DEFAULT: '#D7DBDF',
          hover: '#25292B',
        },
      },
      secondary: {
        DEFAULT: '#868E96',
        medium: '#DEE2E6',
        low: '#E4E3E6',
        dark: {
          DEFAULT: '#868E96',
          medium: '#25292B',
          low: '#4C5155',
        },
      },
      tertiary: {
        DEFAULT: '#140428',
        subtle: '#f9f7fa',
        faint: '#e6e4f3',
        low: '#CBC3D6',
        medium: '#5646AD',
        deep: '#443592',
        high: '#2D174A',
        dark: {
          DEFAULT: '#140428',
          subtle: '#060805',
          faint: '#1f1f2e',
          low: '#CBC3D6',
          medium: '#5646AD',
          deep: '#443592',
          high: '#2D174A',
        },
      },
      accent: {
        DEFAULT: '#1f93ff',
        low: '#DFEFFE',
        medium: '#cee7fe',
        hover: '#1f93ff',
        dark: {
          DEFAULT: '#1f93ff',
          hover: '#D7DBDF',
          low: '#173461',
          medium: '#cee7fe',
        },
      },
      success: {
        DEFAULT: '#1BDC74',
        medium: '#09974b',
        dark: {
          DEFAULT: '#1BDC74',
          medium: '#09974b',
        },
      },
      neutral: {
        DEFAULT: '#ffffff',
        light: '#F2F2F2',
        subtle: '#F8F9FA',
        low: '#F3F3F5',
        medium: '#E8E8E8',
        high: '#E4E3E6',
        dark: {
          DEFAULT: '#25292B',
          light: '#1C1E20',
          subtle: '#25292B',
          medium: '#2F3336',
          high: '#697074',
        },
      },
      error: {
        DEFAULT: '#f2555a',
        medium: '#CD2B31',
        dark: {
          DEFAULT: '#f2555a',
          medium: '#fb797d',
        },
      },
      warning: {
        DEFAULT: '#F09A0B',
        subtle: '#F9F5E1',
        faint: '#fffbd1',
        low: '#fef2a4',
        high: '#bd8019',
        dark: {
          DEFAULT: '#F09A0B',
          subtle: '#F9F5E1',
          faint: '#594a05',
          low: '#7e6604',
          high: '#bd8019',
        },
      },
      transparent: 'transparent',
      white: '#fff',
      white_transparent: 'rgba(255, 255, 255, 0.9)',
      black_transparent: 'rgba(0, 0, 0, 0.9)',
      modal: 'rgba(0, 0, 0, 0.4)',
      current: 'currentColor',
      navPanelBg: '#140428',
      navPanelLines: '#2D174A',
      magnolia: '#f9f7fa',
      lightgray: '#f1f0f2',
      purplelink: '#5646AD',
      spacegrey: '#140428',
      doctor: '#f9f9fa',
      powderviola: '#CBC3D6',
      stategrey: '#D7DBDF',
      woot: {
        25: blue.blue2,
        50: blue.blue3,
        75: blue.blue4,
        100: blue.blue5,
        200: blue.blue7,
        300: blue.blue8,
        400: blueDark.blue11,
        500: blueDark.blue10,
        600: blueDark.blue9,
        700: blueDark.blue8,
        800: blueDark.blue6,
        900: blueDark.blue2,
      },
      green: {
        50: greenDark.green12,
        100: green.green6,
        200: green.green7,
        300: green.green8,
        400: greenDark.green10,
        500: greenDark.green9,
        600: green.green10,
        700: green.green11,
        800: greenDark.green7,
        900: greenDark.green6,
      },
      yellow: {
        50: yellow.yellow2,
        100: yellow.yellow3,
        200: yellow.yellow5,
        300: yellowDark.yellow10,
        400: yellowDark.yellow9,
        500: yellowDark.yellow11,
        600: yellow.yellow8,
        700: yellowDark.yellow7,
        800: yellowDark.yellow2,
        900: yellowDark.yellow1,
      },
      slate: {
        25: slate.slate2,
        50: slate.slate3,
        75: slate.slate4,
        100: slate.slate5,
        200: slate.slate7,
        300: slate.slate8,
        400: slateDark.slate11,
        500: slateDark.slate10,
        600: slate.slate11,
        700: slateDark.slate8,
        800: slateDark.slate4,
        900: slateDark.slate1,
      },
      black: {
        50: slate.slate2,
        100: slateDark.slate12,
        200: slate.slate7,
        300: slate.slate8,
        400: slateDark.slate11,
        500: slate.slate9,
        600: slateDark.slate9,
        700: slateDark.slate8,
        800: slateDark.slate7,
        900: slateDark.slate2,
      },
      red: {
        50: redDark.red12,
        100: red.red6,
        200: red.red8,
        300: redDark.red11,
        400: redDark.red10,
        500: red.red9,
        600: red.red10,
        700: red.red11,
        800: redDark.red8,
        900: red.red12,
      },
      violet: {
        50: violet.violet1,
        100: violetDark.violet12,
        200: violet.violet6,
        300: violet.violet8,
        400: violet.violet11,
        500: violet.violet9,
        600: violetDark.violet8,
        700: violetDark.violet7,
        800: violetDark.violet6,
        900: violet.violet12,
      },
      body: slateDark.slate7,
    },
    keyframes: {
      ...defaultTheme.keyframes,
      wiggle: {
        '0%': { transform: 'translateX(0)' },
        '15%': { transform: 'translateX(0.375rem)' },
        '30%': { transform: 'translateX(-0.375rem)' },
        '45%': { transform: 'translateX(0.375rem)' },
        '60%': { transform: 'translateX(-0.375rem)' },
        '75%': { transform: 'translateX(0.375rem)' },
        '90%': { transform: 'translateX(-0.375rem)' },
        '100%': { transform: 'translateX(0)' },
      },
      'loader-pulse': {
        '0%': { opacity: 0.4 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0.4 },
      },
    },
    animation: {
      ...defaultTheme.animation,
      wiggle: 'wiggle 0.5s ease-in-out',
      'loader-pulse': 'loader-pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    },
    extend: {
      screens: {
        '3xl': '1920px', // You can adjust the size to whatever you need
      },
      boxShadow: {
        '2xl': '0px 2px 0px 0px rgba(31, 148, 255, 0.16)',
        dropdown: [
          '0px 127px 36px 0px rgba(0, 0, 0, 0)',
          '0px 81px 33px 0px rgba(0, 0, 0, 0.01)',
          '0px 46px 27px 0px rgba(0, 0, 0, 0.04)',
          '0px 20px 20px 0px rgba(0, 0, 0, 0.06)',
          '0px 5px 11px 0px rgba(0, 0, 0, 0.07)',
        ],
        card: [
          '0px 125px 35px 0px rgba(0, 0, 0, 0.00)',
          '0px 80px 32px 0px rgba(0, 0, 0, 0.00)',
          '0px 45px 27px 0px rgba(0, 0, 0, 0.02)',
          '0px 20px 20px 0px rgba(0, 0, 0, 0.03)',
          '0px 5px 11px 0px rgba(0, 0, 0, 0.03)',
        ],
        widget: [
          '0px 274px 77px 0px rgba(0, 0, 0, 0.00)',
          '0px 175px 70px 0px rgba(0, 0, 0, 0.01)',
          '0px 98px 59px 0px rgba(0, 0, 0, 0.03)',
          '0px 44px 44px 0px rgba(0, 0, 0, 0.04)',
          '0px 11px 24px 0px rgba(0, 0, 0, 0.05)',
        ],
        bubble: [
          '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
          '0px 1px 3px 0px rgba(0, 0, 0, 0.06)',
        ],
        focus: '0 0 0 1px #1f93ff, 0 0 2px 2px #cee7fe',
        medium:
          '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      },
    },
  },
  plugins: [
    // eslint-disable-next-line
    require('@tailwindcss/typography'),
  ],
};
