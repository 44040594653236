<template>
  <woot-modal
    :show="show"
    size="small !rounded-md"
    :on-close="() => $emit('close')"
  >
    <div class="h-auto overflow-auto">
      <h6
        class="border-b border-solid border-neutral-medium dark:border-neutral-dark-medium px-4 py-4 mb-2 text-primary dark:text-primary-dark"
      >
        {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
      </h6>

      <div class="px-4">
        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.OPEN_COMMAND_MENU') }}</span>

          <div class="flex flex-row">
            <key-icon :icon-key="isUseMacOs ? 'meta' : 'ctrl'" />
            <key-icon icon-key="k" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.NAVIGATE') }}</span>

          <div class="flex flex-row">
            <key-icon icon-key="up" />
            <key-icon icon-key="down" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.CHANGE_TAB') }}</span>

          <div class="flex flex-row">
            <key-icon icon-key="left" />
            <key-icon icon-key="right" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.REPLY') }}</span>

          <div class="flex flex-row">
            <key-icon icon-key="return" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{
            $t('KEYBOARD_SHORTCUTS.TITLE.DEACTIVATE_TEXT_EDITOR')
          }}</span>

          <div class="flex flex-row">
            <key-icon icon-key="esc" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.RESOLVE_CONVERSATION') }}</span>

          <div class="flex flex-row">
            <key-icon icon-key="e" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.SNOOZE_CONVERSATION') }}</span>

          <div class="flex flex-row">
            <key-icon icon-key="h" />
          </div>
        </div>

        <div class="shortcut-row">
          <span>{{ $t('KEYBOARD_SHORTCUTS.TITLE.SEND') }}</span>

          <div class="flex flex-row">
            <key-icon :icon-key="isUseMacOs ? 'meta' : 'ctrl'" />
            <key-icon icon-key="return" />
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import KeyIcon from 'dashboard/components/KeyIcon';
import { isMacOs } from 'shared/helpers/osCheck';

export default {
  components: {
    KeyIcon,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUseMacOs() {
      return isMacOs();
    },
  },
};
</script>
<style lang="scss" scoped>
.shortcut-row {
  @apply flex flex-row justify-between my-4;
}
</style>
