/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Shops extends CacheEnabledApiClient {
  constructor() {
    super('shops', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'shop';
  }

}

export default new Shops();
