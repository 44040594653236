import { mapGetters } from 'vuex';

export default {
  created() {
    this.$store.dispatch('agents/get');
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('portals/index');
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      labels: 'labels/getLabels',
      agents: 'agents/getAgents',
      portals: 'portals/allPortals',
    }),
    stepsComplete() {
      return this.onboardingSteps.filter(step => step.done).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    onboardingSteps() {
      return [
        {
          name: 'Connect your email',
          link: this.addAccountScoping('settings/inboxes/new'),
          description: 'Sync your email to simplify managing customer conversations.',
          done: this.hasEmailInbox,
          iconName: 'onboarding-email',
        },
        {
          name: 'Connect social media',
          link: this.addAccountScoping('settings/inboxes/new/facebook'),
          description: 'Link your social accounts for easy message management.',
          done: this.hasFacebookPage,
          iconName: 'onboarding-social-media',
        },
        {
          name: 'Install chat widget',
          link: this.addAccountScoping('settings/inboxes/new/shopify'),
          description: 'Place our chat widget on your storefront for instant customer connections.',
          done: this.hasWebWidget,
          iconName: 'onboarding-chat-widget',
        },
        {
          name: 'Invite your team',
          link: this.addAccountScoping('settings/agents/list'),
          description: 'Get your team onboard for a team-powered support experience.',
          done: this.agents.length > 1,
          iconName: 'onboarding-invite-team',
        },
        {
          name: 'Bonus: Set up labels & templates',
          link: this.addAccountScoping('settings/labels/list'),
          description: 'Keep your inbox tidy and responses swift with labels and templates.',
          done: this.labels.length > 0,
          iconName: 'onboarding-setup-label',
        },
        {
          name: 'Bonus: Craft your first help article',
          link: this.addAccountScoping('portals/all'),
          description: 'Help your customers with informative self-service articles.',
          done: this.portals.length > 0,
          iconName: 'onboarding-article',
        },
      ];
    },
    hasFacebookPage() {
      return this.inboxes.some(inbox => inbox.channel_type === 'Channel::FacebookPage');
    },

    hasWebWidget() {
      return this.inboxes.some(inbox => inbox.channel_type === 'Channel::WebWidget');
    },

    hasEmailInbox() {
      return this.inboxes.some(inbox => inbox.channel_type === 'Channel::Email');
    },
  },
};
