<template>
  <div
    class="bg-neutral dark:bg-neutral-dark rounded-md border-1 border-secondary p-2 cursor cursor-pointer sticky mt-2 bottom-0 left-0"
    @click="showModal"
  >
    <div class="font-medium text-primary dark:text-primary-dark">
      {{ $t('SIDEBAR.ONBOARDING.GETTING_STARTED') }}
    </div>
    <div class="text-secondary mb-2 text-xs">
      {{
        $t('SIDEBAR.ONBOARDING.PROGRESS_MESSAGE', { stepsComplete, totalSteps })
      }}
    </div>

    <div
      class="bg-secondary-medium dark:bg-primary-dark h-2 rounded overflow-hidden"
    >
      <div
        class="bg-accent dark:bg-accent-dark h-full"
        :style="{ width: progressPercentage + '%' }"
      />
    </div>

    <a
      href="#dismiss"
      class="text-secondary absolute top-1 right-1"
      @click.stop.prevent="dismiss"
    >
      <Icon icon="dismiss" type="outline" size="16" :icons="iconLib" />
    </a>
  </div>
</template>

<script>
import onboardingMixin from 'dashboard/mixins/onboardingMixin';
import accountMixin from 'dashboard/mixins/account';
import icons from 'shared/components/FluentIcon/dashboard-icons.json';
import Icon from 'shared/components/FluentIcon/Icon';

export default {
  components: {
    Icon,
  },
  mixins: [accountMixin, onboardingMixin],
  computed: {
    progressPercentage() {
      return (this.stepsComplete / this.totalSteps) * 100;
    },
    iconLib() {
      return icons;
    },
  },
  methods: {
    showModal() {
      this.$root.$emit('onboarding-modal.show');
    },
    dismiss() {
      this.$store.dispatch('accounts/dismissOnboarding');
    },
  },
};
</script>

<style lang="scss" scoped></style>
