<template>
  <div class="w-full mb-6">
    <div class="text-xl text-primary dark:text-primary-dark mb-1 font-medium">
      {{ headerTitle }}
    </div>
    <div
      v-dompurify-html="headerContent"
      class="text-sm w-full text-secondary dark:text-secondary-dark"
    />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: { type: String, default: '' },
    headerContent: { type: String, default: '' },
  },
};
</script>
