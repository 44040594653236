<template>
  <div class="blocklist">
    <div class="blocklist__head" :style="`color: ${status.color}`">
      <fluent-icon
        :icon="status.icon"
        type="solid"
        size="16"
        :view-box="isVerified ? '0 0 16 16' : '0 0 24 24'"
      />
    </div>
    <div class="blocklist__body">
      <div class="blocklist__title">{{ title }}</div>
      <div class="blocklist__status" :style="`color: ${status.color}`">
        {{
          isLoading
            ? $t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.VERIFYING')
            : status.text
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { INACTIVE, VERIFIED, UNVERIFIED } from '../constant';

export default {
  name: 'BlockList',
  props: {
    title: {
      type: String,
      default: 'DKIM',
    },
    isInactive: {
      type: Boolean,
      default: true,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      let status = {};
      if (this.isVerified) {
        status = {
          icon: 'checkmark-circle',
          color: VERIFIED,
          text: this.$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.VERIFIED'),
        };
      } else {
        status = {
          icon: 'close-circle',
          color: this.isInactive ? INACTIVE : UNVERIFIED,
          text: this.isInactive
            ? this.$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.INACTIVE')
            : this.$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.UNVERIFIED'),
        };
      }

      return status;
    },
  },
};
</script>

<style lang="scss" scoped>
.blocklist {
  @apply flex items-center gap-2;

  &__head {
    @apply text-secondary dark:text-secondary-dark font-medium;
  }

  &__title {
    @apply text-sm text-primary dark:text-primary-dark;
  }

  &__status {
    @apply text-sm text-secondary dark:text-secondary-dark;
  }
}
</style>
