<template>
  <div
    class="flex flex-col items-center justify-center h-full m-auto w-[355px]"
  >
    <img
      class="m-4 w-[196px] hidden dark:block"
      :src="imgSrcDark"
      alt="No Chat dark"
    />
    <img class="m-4 w-[196px] block dark:hidden" :src="imgSrc" alt="No Chat" />
    <span
      v-dompurify-html="message"
      class="text-sm text-primary dark:text-primary-dark font-medium text-center"
    />
    <!-- Cmd bar, keyboard shortcuts placeholder -->
    <feature-placeholder :shortcuts="shortcuts" />
  </div>
</template>

<script>
import FeaturePlaceholder from './FeaturePlaceholder.vue';
import darkImage from 'dashboard/assets/images/no-chat-dark.svg';
import lightImage from 'dashboard/assets/images/no-chat.svg';

export default {
  components: { FeaturePlaceholder },
  props: {
    message: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      default: () => lightImage,
    },
    imgSrcDark: {
      type: String,
      default: () => darkImage,
    },
    shortcuts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
