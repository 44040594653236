<template>
  <div v-if="refunds">
    <div
      v-for="refund in refunds"
      :key="refund.processed_at"
      class="mb-2 text-primary text-sm dark:text-stategrey"
    >
      <div v-for="item in refund.refund_line_items" :key="item.line_item_id">
        <div class="refund-article">
          <p class="refund-article__name">
            {{ item.name }}
          </p>
          <p class="refund-article__info">SKU: {{ item.sku }}</p>
          <p class="refund-article__pricing">
          <span class="refund-article__count">
            {{ getCurrency }}{{ item.price }} x {{ item.quantity }}
          </span>
            <span>
            {{ getCurrency }}{{ moneyFormat(item.subtotal, getCurrency) }}
          </span>
          </p>
        </div>
      </div>
      <p class="refund-article__pricing">
        <span class="text-[13px] text-[#868E96] mb-1">
          Processed At
        </span>
        <span class="text-[13px] text-[#868E96] mb-1">
          Refunded
        </span>
      </p>
      <p class="refund-article__pricing">
        <span>
          {{ refund.processed_at }}
        </span>
        <span>
            {{ getCurrency }}{{ moneyFormat(refund.total_refund, getCurrency) }}
        </span>
      </p>

    </div>
  </div>
</template>
<script>
import shopifyMixin from 'dashboard/mixins/shopifyMixin';

export default {
  mixins: [shopifyMixin],
  props: {
    refunds: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: '',
    },
  },
  computed: {
    getCurrency() {
      return `${this.currency_symbol(this.currency)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.refund-article {
  @apply border-solid border-slate-100 dark:border-slate-800 pb-3 px-4 -mx-4;

  &__name {
    @apply text-primary dark:text-stategrey mb-0 text-sm leading-[20px];
  }
  &__info {
    @apply text-[#868E96] text-[13px] mb-0;
  }
  &__count {
    @apply text-[#868E96] text-sm mb-0 leading-[20px];
  }
  &__pricing {
    @apply flex place-content-between text-sm mb-0;
  }
}
</style>
