<template>
  <div class="-mt-px text-sm">
    <button
      class="flex items-center select-none w-full bg-white dark:bg-[#151718] border border-l-0 border-r-0 border-solid m-0 border-[#E4E3E6] dark:border-[rgb(47,51,54)] cursor-grab justify-between py-4 px-5 drag-handle transition-all ease-in-out duration-200 hover:bg-neutral-subtle hover:dark:bg-[#25292B] font-semibold"
      @click="$emit('click')"
    >
      <div class="flex items-center justify-between mb-0.5">
        <div
          class="text-primary text-sm dark:text-stategrey mb-0 py-0 pr-2 pl-0"
        >
          {{ title }}
        </div>

        <emoji-or-icon
          class="inline-block w-5"
          :icon="icon"
          :emoji="emoji"
          view-box="0 0 20 20"
        />

        <div
          v-if="isHasCounter"
          class="px-2 py-1 bg-[#e4e3e6] text-[13px] dark:bg-[#383d40] rounded-[2px]"
        >
          {{ counter }}
        </div>
      </div>
      <div class="flex flex-row">
        <slot name="button" />
        <div class="flex justify-end w-3 text-primary dark:text-stategrey">
          <fluent-icon
            v-if="isOpen"
            size="16"
            icon="chevron-up-new"
            type="outline"
            view-box="0 0 16 16"
          />
          <fluent-icon
            v-else
            size="16"
            icon="chevron-down-new"
            type="outline"
            view-box="0 0 16 16"
          />
        </div>
      </div>
    </button>
    <div
      v-if="isOpen"
      class="bg-white dark:bg-slate-900"
      :class="compact ? 'px-2' : 'px-5 py-4'"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';

export default {
  components: {
    EmojiOrIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    counter: {
      type: Number,
      default: 0,
    },
    isHasCounter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
