<template>
  <div ref="chatContainer" class="training-chat">
    <div v-if="messages.length === 0" class="training-chat__empty">
      <div class="training-chat__empty-img">
        <img
          class="m-4 w-[315px] hidden dark:block"
          :src="emptyDarkImage"
          alt="No Chat dark"
        />
        <img
          class="m-4 w-[315px] block dark:hidden"
          :src="emptyLightImage"
          alt="No Chat"
        />
      </div>
      <div class="training-chat__empty-text">
        {{ $t('SIDEBAR.TRAINING_GROUND.EMPTY_TEXT') }}
      </div>
    </div>
    <div ref="chatMessage" class="training-chat__messages">
      <div
        class="training-chat__spacer"
        :style="{ height: spacerHeight + 'px' }"
      />
      <div v-for="(item, index) in messages" :key="index">
        <training-bubble
          :content="item.content"
          :created-at="item.created_at"
          :thumbnail="item.sender.thumbnail"
          :name="item.sender.name"
          :type="item.message_type"
          :label="item.message_label"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TrainingBubble from './TrainingBubble.vue';
import darkImage from 'dashboard/assets/images/ai-training-empty-dark.svg';
import lightImage from 'dashboard/assets/images/ai-training-empty-light.svg';

export default {
  components: {
    TrainingBubble,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      spacerHeight: 0,
    };
  },
  computed: {
    emptyDarkImage() {
      return darkImage;
    },
    emptyLightImage() {
      return lightImage;
    },
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(() => {
          this.updateSpacerHeight();
          this.scrollToBottom();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.initializeChat();
    window.addEventListener('resize', this.updateSpacerHeight);
  },
  updated() {
    this.updateSpacerHeight();
  },
  activated() {
    this.initializeChat();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSpacerHeight);
  },
  methods: {
    initializeChat() {
      this.$nextTick(() => {
        this.updateSpacerHeight();
        this.scrollToBottom();
      });
    },
    updateSpacerHeight() {
      const chatContainer = this.$refs.chatContainer;
      const chatMessage = this.$refs.chatMessage;

      if (!chatContainer || !chatMessage) return;

      const containerHeight = chatContainer.clientHeight;
      const messagesHeight = chatMessage.scrollHeight - this.spacerHeight;

      if (messagesHeight < containerHeight) {
        this.spacerHeight = containerHeight - (messagesHeight + 24); // 24 is bubble's margin bottom
      } else {
        this.spacerHeight = 0;
      }
    },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.training-chat {
  @apply relative flex flex-col h-[calc(100%-310px)] overflow-y-auto pt-6;

  &__empty {
    @apply flex items-center flex-col justify-center w-full h-full absolute top-0 left-0;

    &-img {
      @apply mb-2;
    }

    &-text {
      @apply text-sm text-primary dark:text-primary-dark font-medium text-center;
    }

    &__messages {
      @apply flex flex-col;
    }
  }
}
</style>
