<template>
  <div class="flex flex-row gap-8 flex-shrink min-w-0 p-6 overflow-auto h-full">
    <div class="w-[60%]">
      <template v-if="fetchedIntents.length > 0">
        <div class="flex items-center gap-4 mb-6 w-full px-4">
          <div class="w-[60%] text-sm uppercase font-medium">
            {{ $t('SIDEBAR.INTENT.TABLE.TITLE') }}
          </div>
          <div
            class="w-[40%] flex items-center text-sm uppercase font-medium sm:justify-start 2xl:justify-center"
          >
            {{ $t('SIDEBAR.INTENT.TABLE.LAST_EDIT') }}
          </div>
        </div>
        <div
          class="pb-4"
          :class="{
            'min-h-[calc(100vh-225px)] max-h-[calc(100vh-225px)] overflow-y-auto px-4 !pb-10':
              pagination.totalPages > 1,
          }"
        >
          <intent-item
            v-for="intent in paginatedIntents"
            :key="intent.id"
            class="mb-2"
            :question="intent.title"
            :respond="intent.description"
            :created-at="intent.created_at"
            :modified-at="intent.updated_at"
            :respond-char-limit="100"
          >
            <!-- Toggle switch component -->
            <template #switch>
              <toggle
                :value="intent.enabled"
                size="medium"
                @input="value => handleToggleChange(intent.id, value)"
              />
            </template>
            <template #menu>
              <!-- Popover (dropdown menu) -->
              <popover
                :menu-items="menuItems"
                @click="name => handleMenuClick(intent.id, name)"
              />
            </template>
          </intent-item>
        </div>
        <div
          class="flex items-center justify-center"
          :class="{ 'h-[100px]': pagination.totalPages > 1 }"
        >
          <pagination :pagination="pagination" @pageChange="handlePageChange" />
        </div>
      </template>
      <template v-else>
        <div class="flex items-center justify-center w-full h-[155px]">
          <p>{{ $t('SIDEBAR.INTENT.NOT_FOUND') }}</p>
        </div>
      </template>
    </div>
    <div class="w-[34%]">
      <span v-dompurify-html="$t('SIDEBAR.INTENT.INTRO')" />
    </div>
  </div>
</template>

<script>
import Toggle from 'dashboard/components/ui/Switch.vue';
import IntentItem from '../components/IntentItem.vue';
import Pagination from '../components/Pagination.vue';
import Popover from '../components/Popover.vue';
import accountMixin from 'dashboard/mixins/account';
import { mapActions, mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    IntentItem,
    Toggle,
    Pagination,
    Popover,
  },
  mixins: [accountMixin, alertMixin],
  data() {
    return {
      menuItems: [
        { name: 'edit', type: '' },
        { name: 'delete', type: 'danger' },
      ],
      pagination: {
        currentPage: 1,
        limit: 10, // total item per page
        total: 0, // total data (intents)
        totalPages: 1,
      },
    };
  },
  computed: {
    ...mapGetters('intents', ['fetchedIntents']),
    paginatedIntents() {
      const start = (this.pagination.currentPage - 1) * this.pagination.limit;
      const end = start + this.pagination.limit;
      return this.fetchedIntents.slice(start, end);
    },
  },
  watch: {
    fetchedIntents() {
      // if intents length change, update pagination
      this.updatePagination();
    },
  },
  mounted() {
    this.fetchIntents();
  },
  methods: {
    ...mapActions('intents', {
      fetchIntents: 'get',
      fetchIntentById: 'getById',
      updateIntentStatus: 'update',
      deleteIntent: 'delete',
    }),
    async handleMenuClick(intentId, name) {
      if (name === 'edit') {
        await this.fetchIntentById(intentId);
        this.$router.push({
          name: 'ai_intents_edit',
          params: { intent_id: intentId },
        });
      } else if (name === 'delete') {
        await this.deleteIntent(intentId);
        this.showAlert('Successfully deleted intent');
      }
    },
    handleToggleChange(id, val) {
      const intent = this.fetchedIntents?.find(int => int.id === id) || [];
      if (intent) {
        this.updateIntentStatus({
          id: intent.id,
          enabled: val,
        });
      }
    },
    updatePagination() {
      // Set the total number of items to paginate
      this.pagination.total = this.fetchedIntents.length;

      // Calculate the total number of pages needed
      // Rounds up to ensure partial pages are counted as full pages
      this.pagination.totalPages = Math.ceil(
        this.pagination.total / this.pagination.limit
      );

      // Check if the current page number is greater than the total number of pages
      if (this.pagination.currentPage > this.pagination.totalPages) {
        // Adjust current page to last valid page or first page if no pages exist
        this.pagination.currentPage = Math.max(1, this.pagination.totalPages);
      }
    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.intro {
  @apply flex items-center gap-4 mb-10 w-full uppercase;

  &__left {
    @apply w-[60%];
  }

  &__right {
    @apply w-[40%] justify-end gap-4;
  }
}

.intents-pagintation {
  @apply min-h-[calc(100vh-195px)] max-h-[calc(100vh-195px)] overflow-y-auto px-4 pb-4;
}
</style>
