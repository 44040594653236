<template>
  <div>
    <ExpandableBox
      :expanded="expanded"
      :heading="$t('CHAT_WIDGET_SETTINGS.QUICK_LINKS_AND_FLOWS')"
      :subheading="$t('CHAT_WIDGET_SETTINGS.SELECT_HINT')"
    >
      <draggable
        tag="div"
        class="border-t-0"
        :list="resources"
        handle=".handle"
        @start="dragging = true"
        @end="onDragEnd"
      >
        <QuickLinkItem
          v-for="resource in resources"
          :key="resource.id"
          :item="resource"
          class="draggable"
        />
      </draggable>

      <a
        href="#add"
        class="button hollow mt-5 rounded-lg h-auto"
        :disabled="maxItemsReached"
        @click.prevent="showAddModal()"
      >
        <Icon size="24" icon="add" type="regular" :icons="iconLib" />
        {{ $t('CHAT_WIDGET_SETTINGS.ADD_ITEM_BUTTON') }}
      </a>

      <p class="text-black-600 mt-5">
        {{ $t('CHAT_WIDGET_SETTINGS.FLOW_BUILDER_HINT') }}
        <router-link :to="addAccountScoping('settings/workflows/list')">
          {{ $t('CHAT_WIDGET_SETTINGS.FLOW_BUILDER') }}  
        </router-link>
      </p>
    </ExpandableBox>

    <AddItemModal
      :show="showModal"
      :max-items="maxItemsToAdd"
      :inbox-id="inboxId"
      @closeModal="showModal = false"
    />
  </div>
</template>

<script>
const MAX_ITEMS = 8;

import { mapGetters } from 'vuex';

import draggable from 'vuedraggable';
import Icon from '../../../../../shared/components/FluentIcon/Icon.vue';
import icons from '../../../../../shared/components/FluentIcon/icons.json';
import accountMixin from '../../../../mixins/account';

import ExpandableBox from './components/ExpandableBox.vue';
import QuickLinkItem from './components/QuickLinkItem.vue';
import AddItemModal from './components/AddItemModal';

export default {
  components: {
    draggable,
    Icon,
    AddItemModal,
    QuickLinkItem,
    ExpandableBox,
  },
  mixins: [
    accountMixin,
  ],
  props: {
    inboxId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      showModal: false,
      expanded: false,
    };
  },
  created() {
    this.fetchResources();
  },
  watch: {
    '$route.params.inboxId': 'fetchResources'
  },
  computed: {
    ...mapGetters({
      resources: 'inboxChatResources/getResources',
    }),
    iconLib() {
      return icons;
    },
    maxItemsToAdd() {
      return MAX_ITEMS - this.resources.length;
    },
    maxItemsReached() {
      return this.resources.length >= MAX_ITEMS;
    },
  },
  methods: {
    onDragEnd() {
      this.$store.dispatch('inboxChatResources/updateSequence');
    },
    showAddModal() {
      this.showModal = true;
    },
    fetchResources() {
      this.$store.dispatch('inboxChatResources/get', this.inboxId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
