<template>
  <div class="px-6">
    <div v-if="isShopifyDataExist">
      <shopify-customer-info :customer="shopifyCustomer" />
    </div>
  </div>
</template>

<script>
import ShopifyCustomerInfo from './shopify/ShopifyCustomerInfo';

export default {
  components: {
    ShopifyCustomerInfo,
  },
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShopifyDataExist: false,
    };
  },
  computed: {
    shopifyCustomer() {
      return this.chat?.shopify_data?.customer || {};
    },
    hasShopifyData() {
      return Boolean(
        this.shopifyCustomer && Object.keys(this.shopifyCustomer).length
      );
    },
  },
  watch: {
    // re-render the ShopifyCustomerInfo
    shopifyCustomer(val) {
      if (val) {
        this.isShopifyDataExist = this.hasShopifyData;
      }
    },
  },
  mounted() {
    // re-render the ShopifyCustomerInfo when side panel is toggled
    this.isShopifyDataExist = this.hasShopifyData;
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .contact--profile {
    @apply pb-3 border-b border-solid border-slate-75 dark:border-slate-700;
  }
  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      @apply box-border pl-6;
    }
    .multiselect__element {
      span {
        @apply w-full;
      }
    }
  }
}
</style>
