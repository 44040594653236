<template>
  <div class="custom-table">
    <table>
      <thead>
        <tr>
          <th width="18%" />
          <th width="12%">Type</th>
          <th width="35%">Hostname</th>
          <th width="35%">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <block-list
              :is-inactive="isInactive"
              :is-verified="isVerified"
              :title="title"
              :is-loading="isLoading"
            />
          </td>
          <td>{{ type }}</td>
          <td>
            <copy-content :content="host" />
          </td>
          <td>
            <copy-content :content="value" />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <custom-callout
              v-if="isError"
              :is-danger="isError"
              :title="calloutTitle"
              :desc="calloutDesc"
              class="mt-4"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import CustomCallout from './CustomCallout.vue';
import BlockList from './BlockList.vue';
import CopyContent from './CopyContent.vue';

export default {
  components: {
    CustomCallout,
    BlockList,
    CopyContent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    host: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    isInactive: {
      type: Boolean,
      default: true,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    calloutTitle: {
      type: String,
      default: '',
    },
    calloutDesc: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-table {
  @apply p-4 border border-neutral-medium dark:border-neutral-dark-medium;

  table {
    @apply w-full;

    tr {
      @apply border-0;
    }

    th,
    td {
      @apply align-top text-sm py-0 px-2;
    }

    th {
      @apply font-bold pb-2 capitalize tracking-normal;
    }
  }
}
</style>
