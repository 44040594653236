<template>
  <div :class="emptyClassName">
    <woot-loading-state
      v-if="uiFlags.isFetching || loadingChatList"
      :message="loadingIndicatorMessage"
    />
    <!-- No inboxes attached -->
    <div
      v-if="!inboxesList.length && !uiFlags.isFetching && !loadingChatList"
      class="clearfix h-full"
    >
      <empty-state-message
        :message="$t('CONVERSATION.NO_INBOX_AGENT')"
        :img-src="emptyAssetLight"
        :img-src-dark="emptyAssetDark"
        :shortcuts="defaultShortcuts"
      />
    </div>

    <!-- Show empty state images if not loading -->
    <div
      v-else-if="!uiFlags.isFetching && !loadingChatList"
      class="flex flex-col items-center justify-center h-full"
    >
      <!-- No conversations available -->
      <empty-state-message
        v-if="!allConversations.length"
        :img-src="emptyAssetLight"
        :img-src-dark="emptyAssetDark"
        :message="combinedEmptyMessage"
        :shortcuts="defaultShortcuts"
      />
      <empty-state-message
        v-else-if="allConversations.length && !currentChat.id"
        :shortcuts="conversationShortcuts"
        :message="conversationMissingMessage"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';
import EmptyStateMessage from './EmptyStateMessage.vue';
import emptyChatImage from 'dashboard/assets/images/empty-chat.svg';
import emptyChatImageDark from 'dashboard/assets/images/empty-chat-dark.svg';
import { isMacOs } from 'shared/helpers/osCheck';

export default {
  components: {
    EmptyStateMessage,
  },
  mixins: [accountMixin, adminMixin],
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      uiFlags: 'inboxes/getUIFlags',
      loadingChatList: 'getChatListLoadingStatus',
    }),
    loadingIndicatorMessage() {
      if (this.uiFlags.isFetching) {
        return this.$t('CONVERSATION.LOADING_INBOXES');
      }
      return this.$t('CONVERSATION.LOADING_CONVERSATIONS');
    },
    conversationMissingMessage() {
      if (!this.isOnExpandedLayout) {
        return this.$t('CONVERSATION.SELECT_A_CONVERSATION');
      }
      return this.$t('CONVERSATION.404');
    },
    newInboxURL() {
      return this.addAccountScoping('settings/inboxes/new');
    },
    emptyClassName() {
      if (
        !this.inboxesList.length &&
        !this.uiFlags.isFetching &&
        !this.loadingChatList &&
        this.isAdmin
      ) {
        return 'h-full w-full overflow-auto';
      }
      return 'flex-1 min-w-0 px-0 flex flex-col items-center justify-center h-full';
    },
    combinedEmptyMessage() {
      return `${this.$t('CONVERSATION.NO_MESSAGE_1')}</br>${this.$t(
        'CONVERSATION.NO_MESSAGE_2'
      )}`;
    },
    emptyAssetLight() {
      return emptyChatImage;
    },
    emptyAssetDark() {
      return emptyChatImageDark;
    },
    isUseMacOs() {
      return isMacOs();
    },
    defaultShortcuts() {
      return [
        {
          combination: [
            {
              primary: this.isUseMacOs ? 'meta' : 'ctrl',
              secondary: 'k',
            },
          ],
          description: this.$t('CONVERSATION.EMPTY_STATE.CMD_BAR'),
        },
        {
          combination: [
            {
              primary: this.isUseMacOs ? 'meta' : 'ctrl',
              secondary: 'slash',
            },
          ],
          description: this.$t('CONVERSATION.EMPTY_STATE.KEYBOARD_SHORTCUTS'),
        },
      ];
    },
    conversationShortcuts() {
      return [
        {
          combination: [
            {
              primary: 'up',
              secondary: 'down',
            },
          ],
          description: this.$t('KEYBOARD_SHORTCUTS.TITLE.NAVIGATE'),
        },
        {
          combination: [
            {
              primary: 'left',
              secondary: 'right',
            },
          ],
          description: this.$t('KEYBOARD_SHORTCUTS.TITLE.CHANGE_TAB'),
        },
        {
          combination: [
            {
              primary: '',
              secondary: 'return',
            },
          ],
          description: this.$t('KEYBOARD_SHORTCUTS.TITLE.REPLY'),
        },
      ];
    },
  },
};
</script>
