export default {
  data() {
    return {
      isOsOnDarkMode: false,
      mediaQuery: null,
    };
  },
  mounted() {
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.isOsOnDarkMode = this.mediaQuery.matches;
    this.mediaQuery.addEventListener('change', this.handleMediaQueryChange);
  },
  beforeDestroy() {
    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener(
        'change',
        this.handleMediaQueryChange
      );
    }
  },
  methods: {
    handleMediaQueryChange(event) {
      this.isOsOnDarkMode = event.matches;
    },
  },
};
