import advancedFilters from './advancedFilters.json';
import agentBots from './agentBots.json';
import agentMgmt from './agentMgmt.json';
import attributesMgmt from './attributesMgmt.json';
import auditLogs from './auditLogs.json';
import automation from './automation.json';
import bulkActions from './bulkActions.json';
import campaign from './campaign.json';
import cannedMgmt from './cannedMgmt.json';
import chatlist from './chatlist.json';
import contact from './contact.json';
import shopify from './shopify.json';
import contactFilters from './contactFilters.json';
import conversation from './conversation.json';
import csatMgmt from './csatMgmt.json';
import emoji from './emoji.json';
import generalSettings from './generalSettings.json';
import helpCenter from './helpCenter.json';
import inboxMgmt from './inboxMgmt.json';
import integrationApps from './integrationApps.json';
import integrations from './integrations.json';
import labelsMgmt from './labelsMgmt.json';
import shopMgmt from './shopMgmt.json';
import workflowMgmt from './workflowMgmt.json';
import login from './login.json';
import macros from './macros.json';
import report from './report.json';
import resetPassword from './resetPassword.json';
import search from './search.json';
import setNewPassword from './setNewPassword.json';
import settings from './settings.json';
import signup from './signup.json';
import teamsSettings from './teamsSettings.json';
import whatsappTemplates from './whatsappTemplates.json';
import chatWidgetSettings from './chatWidgetSettings.json';
import agentRevenueReport from './agentRevenueReport.json';
import klaviyo from './klaviyo.json';

export default {
  ...advancedFilters,
  ...agentBots,
  ...agentMgmt,
  ...attributesMgmt,
  ...auditLogs,
  ...automation,
  ...bulkActions,
  ...campaign,
  ...cannedMgmt,
  ...chatlist,
  ...contact,
  ...shopify,
  ...contactFilters,
  ...conversation,
  ...csatMgmt,
  ...emoji,
  ...generalSettings,
  ...helpCenter,
  ...inboxMgmt,
  ...integrationApps,
  ...integrations,
  ...labelsMgmt,
  ...login,
  ...macros,
  ...report,
  ...resetPassword,
  ...search,
  ...setNewPassword,
  ...settings,
  ...signup,
  ...teamsSettings,
  ...whatsappTemplates,
  ...shopMgmt,
  ...workflowMgmt,
  ...chatWidgetSettings,
  ...agentRevenueReport,
  ...klaviyo,
};
