<template>
  <div>
    <div v-for="item in items" :key="item.name">
      <div class="order-article">
        <p class="order-article__name">
          {{ item.name }}
        </p>
        <p class="order-article__info">SKU: {{ item.sku }}</p>
        <p class="order-article__pricing">
          <span class="order-article__count">
            {{ getCurrency }}{{ item.price }} x {{ item.quantity }}
          </span>
          <span>
            {{ getCurrency }}{{ moneyFormat(item.price, getCurrency) }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import shopifyMixin from 'dashboard/mixins/shopifyMixin';

export default {
  mixins: [shopifyMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: '',
    },
  },
  computed: {
    getCurrency() {
      return `${this.currency_symbol(this.currency)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-article {
  @apply border-solid border-t border-slate-100 dark:border-slate-800 py-3 px-4 -mx-4;

  &__name {
    @apply text-primary dark:text-stategrey mb-0 text-sm leading-[20px];
  }
  &__info {
    @apply text-[#868E96] text-[13px] mb-0;
  }
  &__count {
    @apply text-[#868E96] text-sm mb-0 leading-[20px];
  }
  &__pricing {
    @apply flex place-content-between text-sm mb-0;
  }
}
</style>
