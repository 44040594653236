import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import ShopsAPI from '../../api/shops';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { SHOP_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getShops(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  revalidate: async function revalidate({ commit }, { newKey }) {
    try {
      const isExistingKeyValid = await ShopsAPI.validateCacheKey(newKey);
      if (!isExistingKeyValid) {
        const response = await ShopsAPI.refetchAndCommit(newKey);
        commit(types.SET_SHOPS, response.data.payload);
      }
    } catch (error) {
      // Ignore error
    }
  },

  get: async function getShops({ commit }) {
    commit(types.SET_SHOP_UI_FLAG, { isFetching: true });
    try {
      const response = await ShopsAPI.get(false);
      commit(types.SET_SHOPS, response.data.payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SHOP_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createShops({ commit }, shopObj) {
    commit(types.SET_SHOP_UI_FLAG, { isCreating: true });
    try {
      const response = await ShopsAPI.create(shopObj);
      AnalyticsHelper.track(SHOP_EVENTS.CREATE);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_SHOP_UI_FLAG, { isCreating: false });
    }
  },
  update: async function updateShop({ commit }, { id, messageSignature }) {
    try {
      const response = await ShopsAPI.update(id, { message_signature: messageSignature });
      commit(types.UPDATE_SHOP, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    }
  },
  delete: async function deleteShops({ commit }, id) {
    commit(types.SET_SHOP_UI_FLAG, { isDeleting: true });
    try {
      await ShopsAPI.delete(id);
      AnalyticsHelper.track(SHOP_EVENTS.DELETED);
      commit(types.DELETE_SHOP, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SHOP_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_SHOP_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_SHOPS]: MutationHelpers.set,
  [types.ADD_SHOP]: MutationHelpers.create,
  [types.DELETE_SHOP]: MutationHelpers.destroy,
  [types.UPDATE_SHOP]: MutationHelpers.setSingleRecord,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
