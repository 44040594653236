import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import WorkflowsAPI from '../../api/workflows';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { WORKFLOW_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getWorkflows(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  revalidate: async function revalidate({ commit }, { newKey }) {
    try {
      const isExistingKeyValid = await WorkflowsAPI.validateCacheKey(newKey);
      if (!isExistingKeyValid) {
        const response = await WorkflowsAPI.refetchAndCommit(newKey);
        commit(types.SET_WORKFLOWS, response.data.payload);
      }
    } catch (error) {
      // Ignore error
    }
  },

  get: async function getWorkflows({ commit }) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isFetching: true });
    try {
      const response = await WorkflowsAPI.get(false);
      commit(types.SET_WORKFLOWS, response.data.payload);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createWorkflows({ commit }, workflowObj) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isCreating: true });
    try {
      const response = await WorkflowsAPI.create(workflowObj);
      commit(types.ADD_WORKFLOW, response.data);
      AnalyticsHelper.track(WORKFLOW_EVENTS.CREATE);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isCreating: false });
    }
  },

  delete: async function deleteWorkflows({ commit }, id) {
    commit(types.SET_WORKFLOW_UI_FLAG, { isDeleting: true });
    try {
      await WorkflowsAPI.delete(id);
      AnalyticsHelper.track(WORKFLOW_EVENTS.DELETED);
      commit(types.DELETE_WORKFLOW, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_WORKFLOW_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_WORKFLOW_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_WORKFLOWS]: MutationHelpers.set,
  [types.ADD_WORKFLOW]: MutationHelpers.create,
  [types.DELETE_WORKFLOW]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
