<template>
  <div class="show-more">
    {{ expanded ? text : trimmedText }}
    <div v-if="isTextTrimmed" class="show-more__footer">
      <div
        class="show-more__text"
        :style="`fontSize: ${fontSize}`"
        @click="handleExpand"
      >
        {{
          expanded ? $t('CONVERSATION.SHOW_LESS') : $t('CONVERSATION.SHOW_MORE')
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    charLimit: {
      type: Number,
      default: 200,
    },
    fontSize: {
      type: String,
      default: '12px',
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.isExpanded,
    };
  },
  computed: {
    isTextTrimmed() {
      return this.text.length > this.charLimit;
    },
    trimmedText() {
      return this.isTextTrimmed
        ? this.text.substring(0, this.charLimit) + '...'
        : this.text;
    },
  },
  methods: {
    handleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.show-more {
  @apply overflow-hidden;

  &__footer {
    @apply inline-block;
  }

  &__text {
    @apply text-accent dark:text-accent-dark transition-all ease-in-out duration-200 inline-block select-none cursor-pointer;

    &:hover {
      @apply text-accent-hover dark:text-accent-dark-hover;
    }
  }
}
</style>
