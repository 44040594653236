<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ $t('WORKFLOW_MGMT.HEADER_BTN_TXT') }}
    </woot-button>
    <div class="flex flex-row gap-4 justify-center">
      <p
        v-if="!uiFlags.isFetching && !records.length"
        class="flex h-full items-center flex-col justify-center"
      >
        {{ $t('WORKFLOW_MGMT.LIST.404') }}
      </p>
      <woot-loading-state
        v-if="uiFlags.isFetching"
        :message="$t('WORKFLOW_MGMT.LOADING')"
      />

      <div v-if="!uiFlags.isFetching && records.length" class="w-full">
        <p class="text-secondary dark:text-secondary-dark pt-2 px-2.5 pb-2">
          {{ records.length }} {{ $t('WORKFLOW_MGMT.WORKFLOWS') }},
          {{ numLive }} {{ $t('WORKFLOW_MGMT.ACTIVE') }}
        </p>

        <v-table
          :data="records"
          :hide-sort-icons="true"
          class="workflows-table"
        >
          <thead slot="head">
            <v-th sort-key="name">
              {{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[0] }}

              <Icon size="16" icon="arrow-up" type="outline" :icons="iconLib" />
              <Icon
                size="16"
                icon="arrow-down"
                type="outline"
                :icons="iconLib"
              />
            </v-th>

            <th>{{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[1] }}</th>
            <v-th sort-key="status">
              {{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[2] }}

              <Icon size="16" icon="arrow-up" type="outline" :icons="iconLib" />
              <Icon
                size="16"
                icon="arrow-down"
                type="outline"
                :icons="iconLib"
              />
            </v-th>
            <v-th sort-key="updated_at">
              {{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[3] }}

              <Icon size="16" icon="arrow-up" type="outline" :icons="iconLib" />
              <Icon
                size="16"
                icon="arrow-down"
                type="outline"
                :icons="iconLib"
              />
            </v-th>
            <th>{{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[4] }}</th>
            <th>{{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[5] }}</th>
            <th class="text-right">
              {{ $t('WORKFLOW_MGMT.LIST.TABLE_HEADER')[6] }}
            </th>
          </thead>
          <tbody slot="body" slot-scope="{ displayData }">
            <tr v-for="workflow in displayData" :key="workflow.id">
              <td>
                <span class="overflow-hidden whitespace-nowrap text-ellipsis">
                  {{ workflow.name }}
                </span>
              </td>
              <td>
                <div class="type">
                  <div class="type__icon">
                    <fluent-icon
                      icon="conversation"
                      type="outline"
                      size="26"
                      view-box="0 0 26 26"
                    />
                  </div>
                </div>
              </td>
              <td>
                <span v-if="workflow.status === 'live'">
                  <i class="circle circle--live" />
                  {{ $t('WORKFLOW_MGMT.LIST.LIVE') }}
                </span>
                <span v-else-if="workflow.status === 'disabled'">
                  <i class="circle" />
                  {{ $t('WORKFLOW_MGMT.LIST.DISABLED') }}
                </span>
              </td>
              <td>
                {{ moment(workflow.updated_at).format('MM/DD/YYYY') }}
              </td>
              <td />
              <td />
              <td class="button-wrapper">
                <!-- action button -->
                <woot-button
                  v-if="isAdmin"
                  v-tooltip.top="$t('WORKFLOW_MGMT.FORM.EDIT')"
                  variant="smooth"
                  size="tiny"
                  icon="edit"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  @click="openEdit(workflow)"
                />
                <woot-button
                  v-if="isAdmin"
                  v-tooltip.top="$t('WORKFLOW_MGMT.FORM.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  :is-loading="loading[workflow.id]"
                  @click="openDeletePopup(workflow, index)"
                />
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>

    <woot-modal
      :show.sync="showAddPopup"
      :on-close="hideAddPopup"
      size="!w-[37.5rem]"
    >
      <add-workflow @close="hideAddPopup" />
    </woot-modal>

    <woot-modal
      :on-close="closeDeletePopup"
      :show.sync="showDeleteConfirmationPopup"
      size="!w-[37.5rem]"
    >
      <woot-modal-header class="!p-4">
        <h5 class="pb-3 text-primary dark:text-primary-dark">
          {{ $t('WORKFLOW_MGMT.DELETE.CONFIRM.TITLE') }}
        </h5>
        <p class="mt-3">
          {{ $t('WORKFLOW_MGMT.DELETE.CONFIRM.MESSAGE') }}
          <strong>{{ selectedResponse.name }}</strong>
          {{ $t('WORKFLOW_MGMT.DELETE.CONFIRM.MESSAGE2') }}
        </p>
      </woot-modal-header>
      <div class="modal-footer mt-7 !py-4 !px-4">
        <woot-button
          variant="clear"
          class="action-button"
          @click="closeDeletePopup"
        >
          {{ $t('WORKFLOW_MGMT.DELETE.CONFIRM.NO') }}
        </woot-button>
        <woot-button
          class-names="action-button bg-buttonred rounded-lg"
          color-scheme="none"
          @click="confirmDeletion"
        >
          {{ $t('WORKFLOW_MGMT.DELETE.CONFIRM.TITLE') }}
        </woot-button>
      </div>
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AddWorkflow from './AddWorkflow';
import adminMixin from '../../../../mixins/isAdmin';
import alertMixin from 'shared/mixins/alertMixin';
import Icon from '../../../../../shared/components/FluentIcon/Icon.vue';
import icons from '../../../../../shared/components/FluentIcon/icons.json';
import moment from 'moment';

const { typebotURL } = window.chatwootConfig;

export default {
  components: {
    AddWorkflow,
    Icon,
  },
  mixins: [adminMixin, alertMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'workflows/getWorkflows',
      uiFlags: 'workflows/getUIFlags',
    }),
    // Delete Modal
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
    iconLib() {
      return icons;
    },
    numLive() {
      return this.records.filter(workflow => workflow.status === 'live').length;
    },
  },
  watch: {
    '$route.name': 'fetchWorkflows',
  },
  created() {
    this.fetchWorkflows();
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup(workflowId) {
      this.showAddPopup = false;

      if (workflowId) {
        window.open(
          typebotURL + '/typebots/create?commslayer_id=' + workflowId,
          '_blank',
          'noreferrer'
        );
      }
    },
    openEdit(workflow) {
      window.open(
        typebotURL + '/typebots/' + workflow.typebot_id + '/edit',
        '_blank',
        'noreferrer'
      );
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteLabel(this.selectedResponse.id);
    },
    deleteLabel(id) {
      this.$store
        .dispatch('workflows/delete', id)
        .then(() => {
          this.showAlert(this.$t('WORKFLOW_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(() => {
          this.showAlert(this.$t('WORKFLOW_MGMT.DELETE.API.ERROR_MESSAGE'));
        })
        .finally(() => {
          this.loading[this.selectedResponse.id] = false;
        });
    },
    moment(date) {
      return moment(date);
    },
    fetchWorkflows() {
      if (this.$route.name === 'workflows_list') {
        this.$store.dispatch('workflows/get');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.workflows-table {
  @apply text-primary dark:text-primary-dark text-sm leading-5;

  th,
  td {
    @apply pt-2 px-2.5 pb-2;
  }

  th {
    @apply font-bold;

    svg {
      @apply hidden text-accent dark:text-accent-dark -mt-[5px];
    }

    &[aria-sort='ascending'] svg:first-of-type,
    &[aria-sort='descending'] svg:last-of-type {
      @apply inline-block;
    }
  }
}

.type {
  @apply bg-tertiary-faint dark:bg-tertiary-dark-faint rounded-[0.3125rem] relative w-[24px] h-[24px];

  &__icon {
    @apply text-tertiary-medium dark:text-tertiary-dark-medium;
  }
}

.circle {
  @apply inline-block mr-[5px] w-[8px] h-[8px] rounded-[8px] bg-secondary relative top-[-0.5px];

  &--live {
    @apply bg-success;
  }
}

.button-wrapper {
  @apply flex flex-row min-w-[12.5rem] gap-1;
}
</style>
