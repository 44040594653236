<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      :href="href"
      class="nav-item"
      :class="{
        'nav-item-active': isActive || isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <fluent-icon
        :icon="icon"
        type="outline"
        :class="{
          'nav-item-icon-active': isActive || isChildMenuActive,
        }"
        :view-box="viewBox"
      />
      <span class="sr-only">{{ name }}</span>
      <span v-if="count" class="count-badge">
        {{ count }}
      </span>
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
    viewBox: {
      type: String,
      default: '0 0 24 24',
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  @apply text-slate-200 dark:text-slate-200 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-violet-700 dark:hover:bg-violet-700 dark:hover:text-slate-100 hover:text-slate-100 relative;
}
.nav-item-active {
  @apply bg-violet-700 dark:bg-violet-700 text-slate-100 hover:bg-violet-700;
}
.count-badge {
  @apply text-black-900 bg-yellow-500 absolute -top-1 -right-1;
}
.nav-item-icon-active {
  @apply text-slate-100;
}
</style>
