<template>
  <div class="widget-preview-container">
    <div v-if="isWidgetVisible" class="screen-selector">
      <input-radio-group
        name="widget-screen"
        :items="widgetScreens"
        :action="handleScreenChange"
      />
    </div>
    <!-- Widget wrapper -->
    <div
      v-if="isWidgetVisible"
      class="widget-wrapper"
      :style="{ background: backgroundGradient }"
    >
      <WidgetHead class="sticky top-0 z-[60]" :config="getWidgetHeadConfig" />
      <div class="widget-wrapper__body">
        <WidgetBody :config="getWidgetBodyConfig" />
        <WidgetFooter :config="getWidgetFooterConfig" />
        <div class="branding">
          <a class="branding-link">
            <img class="branding-image" :src="globalConfig.logoThumbnail" />
            <span>
              {{
                useInstallationName(
                  $t('INBOX_MGMT.WIDGET_BUILDER.BRANDING_TEXT'),
                  globalConfig.installationName
                )
              }}
            </span>
          </a>
        </div>
      </div>
    </div>
    <!-- Widget bubble -->
    <div class="widget-bubble">
      <div class="widget-bubble__body" :style="getBubblePositionStyle">
        <button
          class="bubble"
          :class="getBubbleTypeClass"
          :style="{ background: color }"
          @click="toggleWidget"
        >
          <img
            v-if="!isWidgetVisible"
            src="~dashboard/assets/images/bubble-logo.svg"
            alt=""
          />
          <div>
            {{ getWidgetBubbleLauncherTitle }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetHead from './WidgetHead.vue';
import WidgetBody from './WidgetBody.vue';
import WidgetFooter from './WidgetFooter.vue';
import InputRadioGroup from 'dashboard/routes/dashboard/settings/inbox/components/InputRadioGroup.vue';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { mapGetters } from 'vuex';
import { widgetGradient } from '../../../../sdk/sdk';

export default {
  name: 'Widget',
  components: {
    WidgetHead,
    WidgetBody,
    WidgetFooter,
    InputRadioGroup,
  },
  mixins: [globalConfigMixin],
  props: {
    welcomeHeading: {
      type: String,
      default: '',
    },
    welcomeTagline: {
      type: String,
      default: '',
    },
    websiteName: {
      type: String,
      default: '',
      required: true,
    },
    logo: {
      type: String,
      default: '',
    },
    isOnline: {
      type: Boolean,
      default: true,
    },
    replyTime: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    widgetGradientColor: {
      type: String,
      default: '',
    },
    widgetBubblePosition: {
      type: String,
      default: '',
    },
    widgetBubbleLauncherTitle: {
      type: String,
      default: '',
    },
    widgetBubbleType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      widgetScreens: [
        {
          id: 'default',
          title: this.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_SCREEN.DEFAULT'),
          checked: true,
        },
        {
          id: 'chat',
          title: this.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_SCREEN.CHAT'),
          checked: false,
        },
      ],
      isDefaultScreen: true,
      isWidgetVisible: true,
    };
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    getWidgetHeadConfig() {
      return {
        welcomeHeading: this.welcomeHeading,
        welcomeTagline: this.welcomeTagline,
        websiteName: this.websiteName,
        logo: this.logo,
        isDefaultScreen: this.isDefaultScreen,
        isOnline: this.isOnline,
        replyTime: this.replyTimeText,
        color: this.color,
        widgetGradientColor: this.widgetGradientColor,
      };
    },
    getWidgetBodyConfig() {
      return {
        welcomeHeading: this.welcomeHeading,
        welcomeTagline: this.welcomeTagline,
        isDefaultScreen: this.isDefaultScreen,
        isOnline: this.isOnline,
        replyTime: this.replyTimeText,
        color: this.color,
        logo: this.logo,
      };
    },
    getWidgetFooterConfig() {
      return {
        isDefaultScreen: this.isDefaultScreen,
        color: this.color,
      };
    },
    replyTimeText() {
      switch (this.replyTime) {
        case 'in_a_few_minutes':
          return this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.REPLY_TIME.IN_A_FEW_MINUTES'
          );
        case 'in_a_day':
          return this.$t('INBOX_MGMT.WIDGET_BUILDER.REPLY_TIME.IN_A_DAY');
        default:
          return this.$t('INBOX_MGMT.WIDGET_BUILDER.REPLY_TIME.IN_A_FEW_HOURS');
      }
    },
    getBubblePositionStyle() {
      return {
        justifyContent: this.widgetBubblePosition === 'left' ? 'start' : 'end',
      };
    },
    getBubbleTypeClass() {
      return {
        'bubble-close': this.isWidgetVisible,
        'bubble-expanded':
          !this.isWidgetVisible && this.widgetBubbleType === 'expanded_bubble',
      };
    },
    getWidgetBubbleLauncherTitle() {
      return this.isWidgetVisible || this.widgetBubbleType === 'standard'
        ? ' '
        : this.widgetBubbleLauncherTitle;
    },
    backgroundGradient() {
      return widgetGradient(this.widgetGradientColor);
    },
  },
  methods: {
    handleScreenChange(item) {
      this.isDefaultScreen = item.id === 'default';
    },
    toggleWidget() {
      this.isWidgetVisible = !this.isWidgetVisible;
      this.isDefaultScreen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables.scss';

.widget-preview-container {
  @apply relative h-[764px] w-[400px];
}

.screen-selector {
  @apply flex flex-col items-center;
}

.widget-wrapper {
  @apply h-[640px] rounded-[16px] shadow-widget overflow-y-auto;
  // reset scrollbar color
  scrollbar-color: #c1c1c1 #f8fafa;

  @media only screen and (min-width: 1340px) {
    @apply w-[400px];
  }

  @media only screen and (max-width: 1339px) {
    @apply w-[320px];
  }

  &__body {
    @apply flex flex-col justify-end min-h-[560px];
  }

  .branding {
    @apply px-0 py-3 flex justify-center;

    .branding-link {
      @apply text-secondary cursor-pointer inline-flex transition-all ease-in-out duration-200 grayscale opacity-[0.9] no-underline text-xs;

      &:hover {
        @apply text-primary;
      }

      .branding-image {
        @apply relative top-[2px] mr-1 w-3 h-3;
      }
    }
  }
}
.widget-bubble {
  @apply absolute bottom-0 w-full;

  &__body {
    @apply w-full flex flex-row mt-0;
  }

  .bubble {
    @apply flex items-center rounded-full w-16 h-16 relative cursor-pointer;

    img {
      @apply h-6 w-6 mt-2.5 mr-2.5 mb-2.5 ml-5;
    }

    div {
      @apply pr-5;
    }

    .bubble-expanded {
      img {
        @apply h-8 w-8;
      }
    }
  }

  .bubble-close::before,
  .bubble-close::after {
    @apply bg-white absolute inline w-0.5 h-6 left-8 content-[''];
  }

  .bubble-close::before {
    @apply rotate-[45deg];
  }

  .bubble-close::after {
    @apply rotate-[-45deg];
  }

  .bubble-expanded {
    @apply text-base font-medium text-white w-auto h-12;
  }
}

::v-deep {
  .radio-group-item .radio-group-item-label {
    @apply text-secondary;

    .radio-input {
      @apply appearance-none bg-neutral w-[12px] h-[12px] border border-accent rounded-[50%] inline-block relative outline-none cursor-pointer align-middle;

      &:checked {
        &:after {
          @apply bg-accent w-full h-full rounded-[8px] absolute content-[''] border-[2px] border-neutral;
        }
      }
    }
  }
}
</style>
