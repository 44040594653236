<template>
  <search-result-section
    :title="$t('SEARCH.SECTION.CUSTOMERS')"
    :empty="!customers.length"
    :query="query"
    :show-title="showTitle"
    :is-fetching="isFetching"
  >
    <ul v-if="customers.length" class="search-list">
      <li v-for="customer in customers" :key="customer.id">
        <a :href="customer.external_url"
           target="_blank"
           class="cursor-pointer flex items-center p-2 rounded-sm hover:bg-slate-25 dark:hover:bg-slate-800"
        >
          <Icon size="24" icon="customer" type="regular" :icons="iconLib" />

          <div class="ml-2">
            <h5 class="m-0 text-block-title name text-slate-800 dark:text-slate-200">
              {{ customer.first_name }} {{ customer.last_name }}

              <span v-if="multipleResultsForEmail(customer.email)">
                ({{ customer.shop_name }})
              </span>
            </h5>

            <div class="text-slate-800 dark:text-slate-200">
              {{ customer.email }}
            </div>
          </div>
        </a>
      </li>
    </ul>
  </search-result-section>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '../../../../shared/components/FluentIcon/Icon.vue';
import icons from '../../../../shared/components/FluentIcon/icons.json';

import SearchResultSection from './SearchResultSection.vue';

export default {
  components: {
    SearchResultSection,
    Icon,
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: '',
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    iconLib() {
      return icons;
    },
  },
  methods: {
    multipleResultsForEmail(email) {
      return this.customers.filter(customer => customer.email === email).length > 1;
    },
  },
};
</script>
