export const REPLY_POLICY = {
  FACEBOOK:
    'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/',
  TWILIO_WHATSAPP:
    'https://www.twilio.com/docs/whatsapp/tutorial/send-whatsapp-notification-messages-templates#sending-non-template-messages-within-a-24-hour-session',
};

export const SHOPIFY = {
  ADMIN_URL: 'https://admin.shopify.com',
};

export const KLAVIYO = {
  PROFILE: 'https://klaviyo.com/profiles/?search=',
};
