export default {
  computed: {
    getShopDomainErrorMessage() {
      let errorMessage = '';
      if (!this.$v.shopDomain.$error) {
        errorMessage = '';
      } else if (!this.$v.shopDomain.required) {
        errorMessage = this.$t('SHOP_MGMT.FORM.SHOP_DOMAIN.REQUIRED_ERROR');
      } else if (!this.$v.shopDomain.validLabelCharacters) {
        errorMessage = this.$t('SHOP_MGMT.FORM.SHOP_DOMAIN.VALID_ERROR');
      }
      return errorMessage;
    },
  },
};
