<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%] h-auto">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AIRCALL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.AIRCALL.DESC')"
    />

    <a :href="airCallUrl" class="aircall-action">
      <div class="aircall-action__image">
        <img src="/assets/images/dashboard/channels/aircall.png" alt="">
      </div>
      <div class="aircall-action__text">
        {{ $t('INBOX_MGMT.ADD.AIRCALL.ACTION_TEXT') }}
      </div>
    </a>
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },
  computed: {
    airCallUrl() {
      return `${window.chatwootConfig.hostURL}/install/aircall`;
    }
  }
};
</script>

<style lang="scss" scoped>
  .aircall-action {
    @apply inline-flex items-center justify-center border border-neutral-medium border-solid dark:border-neutral-dark-medium rounded-md h-[44px] px-4 py-3 cursor-pointer transition-all ease-in-out duration-200;

    &:hover {
      @apply border-accent dark:border-accent-dark shadow-medium;
    }

    &__image {
      @apply w-[20px] h-auto mr-2;
    }

    &__text {
      @apply text-sm text-secondary dark:text-secondary-dark;
    }
  }
</style>
