<template>
  <div>
    <div class="message-text--metadata">
      <span
        class="time"
        :class="{
          'has-status-icon':
            showSentIndicator || showDeliveredIndicator || showReadIndicator,
        }"
      >
        {{ readableTime }}
      </span>
      <span v-if="externalError" class="read-indicator-wrap">
        <fluent-icon
          v-tooltip.top="externalError"
          icon="error-circle"
          class="action--icon"
          size="14"
        />
      </span>
      <span v-if="showReadIndicator" class="read-indicator-wrap">
        <fluent-icon
          v-tooltip.top="$t('CHAT_LIST.MESSAGE_READ')"
          icon="checkmark-double"
          class="action--icon read-tick read-indicator"
          size="14"
        />
      </span>
      <span v-else-if="showDeliveredIndicator" class="read-indicator-wrap">
        <fluent-icon
          v-tooltip.top="$t('CHAT_LIST.DELIVERED')"
          icon="checkmark-double"
          class="action--icon read-tick"
          size="14"
        />
      </span>
      <span v-else-if="showSentIndicator" class="read-indicator-wrap">
        <fluent-icon
          v-tooltip.top="$t('CHAT_LIST.SENT')"
          icon="checkmark"
          class="action--icon read-tick"
          size="14"
        />
      </span>
      <fluent-icon
        v-if="isEmail"
        v-tooltip.top="$t('CHAT_LIST.RECEIVED_VIA_EMAIL')"
        icon="mail"
        class="action--icon"
        size="16"
      />
      <fluent-icon
        v-if="isPrivate"
        v-tooltip.top="$t('CONVERSATION.VISIBLE_TO_AGENTS')"
        icon="lock-closed"
        class="action--icon lock--icon--private"
        size="16"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
      />
      <a
        v-if="isATweet && (isOutgoing || isIncoming) && linkToTweet"
        :href="linkToTweet"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <fluent-icon
          v-tooltip.top="$t('CHAT_LIST.VIEW_TWEET_IN_TWITTER')"
          icon="open"
          class="cursor-pointer action--icon"
          size="16"
        />
      </a>
    </div>

    <!-- Comment type (FB or IG) -->
    <div v-if="isMetaComment" class="comment-status">
      <div class="comment-status__item">
        <img
          class="comment-status__badge"
          :src="`/integrations/channels/badges/${badgeSrc}.png`"
          :alt="commentMetaData"
        />
        <div class="comment-status__text">
          {{
            $t('CONVERSATION.ACTION.COMMENT.TEXT', { source: commentMetaData })
          }}.
          <a :href="commentPostUrl" target="_blank">
            {{ $t('CONVERSATION.ACTION.COMMENT.LINK') }}
          </a>
        </div>
      </div>
      <div class="comment-status__item">
        <div
          v-if="isFacebook"
          v-tooltip.top="
            !isLoading
              ? isCommentLiked
                ? $t('CONVERSATION.ACTION.UNLIKE')
                : $t('CONVERSATION.ACTION.LIKE')
              : undefined
          "
          class="comment-status__icon"
          :class="cursorClass || 'cursor-pointer'"
          @click="handleLikeComment"
        >
          <spinner
            v-if="loadingState('like')"
            size="tiny"
            class="comment-status__icon-item comment-status__icon-item--spinner"
          />
          <fluent-icon
            v-else
            icon="thumb-up"
            type="solid"
            size="16"
            view-box="0 0 16 16"
            class="comment-status__icon-item"
            :class="
              isCommentLiked ? 'text-accent dark:text-accent-dark' : undefined
            "
          />
        </div>
        <div
          v-tooltip.top="
            !isLoading
              ? isCommentHidden
                ? $t('CONVERSATION.ACTION.UNHIDE')
                : $t('CONVERSATION.ACTION.HIDE')
              : undefined
          "
          class="comment-status__icon"
          :class="cursorClass || 'cursor-pointer'"
          @click="handleHideComment"
        >
          <spinner
            v-if="loadingState('hide')"
            size="tiny"
            class="comment-status__icon-item comment-status__icon-item--spinner"
          />
          <fluent-icon
            v-else
            :icon="isCommentHidden ? 'eye-hide' : 'eye-show'"
            type="solid"
            size="16"
            view-box="0 0 16 16"
            class="comment-status__icon-item"
            :class="
              isCommentHidden ? 'text-accent dark:text-accent-dark' : undefined
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  MESSAGE_TYPE,
  MESSAGE_STATUS,
  META_COMMENT_ICON,
  META_COMMENT_TITLE,
  META_COMMENT_TYPE_FACEBOOK,
} from 'shared/constants/messages';
import inboxMixin from 'shared/mixins/inboxMixin';
import timeMixin from '../../../../mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner';

export default {
  components: {
    Spinner,
  },
  mixins: [inboxMixin, timeMixin, alertMixin],
  props: {
    sender: {
      type: Object,
      default: () => ({}),
    },
    createdAt: {
      type: Number,
      default: 0,
    },
    storySender: {
      type: String,
      default: '',
    },
    externalError: {
      type: String,
      default: '',
    },
    storyId: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    isPrivate: {
      type: Boolean,
      default: true,
    },
    isATweet: {
      type: Boolean,
      default: true,
    },
    messageType: {
      type: Number,
      default: 1,
    },
    messageStatus: {
      type: String,
      default: '',
    },
    sourceId: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    commentType: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: '',
    },
    commentPostUrl: {
      type: String,
      default: '',
    },
    conversationId: {
      type: [String, Number],
      default: '',
    },
    isMetaComment: {
      type: Boolean,
      default: false,
    },
    isCommentLiked: {
      type: Boolean,
      default: false,
    },
    isCommentHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLiked: this.isCommentLiked,
      isHidden: this.isCommentHidden,
      isLoading: false,
      loadingType: null,
      cursorClass: null,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    formatCommentType() {
      if (!this.commentType) return '';
      return (
        this.commentType.charAt(0).toUpperCase() + this.commentType.slice(1)
      );
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    isIncoming() {
      return MESSAGE_TYPE.INCOMING === this.messageType;
    },
    isOutgoing() {
      return MESSAGE_TYPE.OUTGOING === this.messageType;
    },
    isTemplate() {
      return MESSAGE_TYPE.TEMPLATE === this.messageType;
    },
    isDelivered() {
      return MESSAGE_STATUS.DELIVERED === this.messageStatus;
    },
    isRead() {
      return MESSAGE_STATUS.READ === this.messageStatus;
    },
    isSent() {
      return MESSAGE_STATUS.SENT === this.messageStatus;
    },
    readableTime() {
      return this.messageTimestamp(this.createdAt, 'LLL d, h:mm a');
    },
    screenName() {
      const { additional_attributes: additionalAttributes = {} } =
        this.sender || {};
      return additionalAttributes?.screen_name || '';
    },
    linkToTweet() {
      if (!this.sourceId || !this.inbox.name) {
        return '';
      }
      const { screenName, sourceId } = this;
      return `https://twitter.com/${
        screenName || this.inbox.name
      }/status/${sourceId}`;
    },
    linkToStory() {
      if (!this.storyId || !this.storySender) {
        return '';
      }
      const { storySender, storyId } = this;
      return `https://www.instagram.com/stories/direct/${storySender}_${storyId}`;
    },
    showStatusIndicators() {
      if ((this.isOutgoing || this.isTemplate) && !this.isPrivate) {
        return true;
      }
      return false;
    },
    showSentIndicator() {
      if (!this.showStatusIndicators) {
        return false;
      }
      // Messages will be marked as sent for the Email channel if they have a source ID.
      if (this.isAnEmailChannel) {
        return !!this.sourceId;
      }

      if (
        this.isAWhatsAppChannel ||
        this.isATwilioChannel ||
        this.isAFacebookInbox ||
        this.isASmsInbox ||
        this.isATelegramChannel
      ) {
        return this.sourceId && this.isSent;
      }
      // All messages will be mark as sent for the Line channel, as there is no source ID.
      if (this.isALineChannel) {
        return true;
      }

      return false;
    },
    showDeliveredIndicator() {
      if (!this.showStatusIndicators) {
        return false;
      }
      if (
        this.isAWhatsAppChannel ||
        this.isATwilioChannel ||
        this.isASmsInbox ||
        this.isAFacebookInbox
      ) {
        return this.sourceId && this.isDelivered;
      }
      // All messages marked as delivered for the web widget inbox and API inbox once they are sent.
      if (this.isAWebWidgetInbox || this.isAPIInbox) {
        return this.isSent;
      }
      if (this.isALineChannel) {
        return this.isDelivered;
      }

      return false;
    },
    showReadIndicator() {
      if (!this.showStatusIndicators) {
        return false;
      }
      if (
        this.isAWhatsAppChannel ||
        this.isATwilioChannel ||
        this.isAFacebookInbox
      ) {
        return this.sourceId && this.isRead;
      }

      if (this.isAWebWidgetInbox || this.isAPIInbox) {
        return this.isRead;
      }

      return false;
    },
    badgeSrc() {
      return META_COMMENT_ICON[this.commentType] || undefined;
    },
    commentMetaData() {
      return META_COMMENT_TITLE[this.commentType] || undefined;
    },
    isFacebook() {
      return this.commentType === META_COMMENT_TYPE_FACEBOOK;
    },
  },
  methods: {
    setLoadingState(type) {
      this.isLoading = true;
      this.loadingType = type;
    },
    resetLoadingState() {
      this.isLoading = false;
      this.loadingType = null;
      this.cursorClass = null;
    },
    loadingState(type) {
      return this.isLoading && this.loadingType === type;
    },
    async handleLikeComment() {
      if (this.isLoading) return;
      this.cursorClass = 'pointer-events-none';

      this.isLiked = !this.isLiked;
      this.setLoadingState('like');

      const actionName = this.isLiked ? 'likeComment' : 'unlikeComment';
      const successMessage = this.isLiked
        ? 'CONVERSATION.ACTION.ALERT_LIKED'
        : 'CONVERSATION.ACTION.ALERT_UNLIKED';

      try {
        const data = await this.$store.dispatch(actionName, {
          conversationId: this.conversationId,
          messageId: this.id,
        });
        const isCommentLiked = data?.content_attributes?.comment.like;

        if (
          (this.isLiked && isCommentLiked) ||
          (!this.isLiked && !this.isCommentLiked)
        ) {
          this.showAlert(
            this.$t(successMessage, { type: this.formatCommentType })
          );
        }
      } catch (error) {
        this.showAlert(`${error}`);
      } finally {
        this.resetLoadingState();
      }
    },
    async handleHideComment() {
      if (this.isLoading) return;
      this.cursorClass = 'pointer-events-none';

      this.isHidden = !this.isHidden;
      this.setLoadingState('hide');

      const actionName = this.isHidden
        ? 'hideMetaComment'
        : 'unhideMetaComment';
      const successMessage = this.isHidden
        ? 'CONVERSATION.ACTION.ALERT_HIDDEN'
        : 'CONVERSATION.ACTION.ALERT_UNHIDDEN';

      try {
        const isCommentHidden = await this.$store.dispatch(actionName, {
          conversationId: this.conversationId,
          messageId: this.id,
        });

        if (
          (this.isHidden && isCommentHidden) ||
          (!this.isHidden && !isCommentHidden)
        ) {
          this.showAlert(
            this.$t(successMessage, { type: this.formatCommentType })
          );
        }
      } catch (error) {
        this.showAlert(`${error}`);
      } finally {
        this.resetLoadingState();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  .message-text--metadata {
    @apply items-center;
    .time {
      @apply text-woot-100 dark:text-woot-100;
    }

    .action--icon {
      @apply text-white dark:text-white;

      &.read-tick {
        @apply text-violet-100 dark:text-violet-100;
      }

      &.read-indicator {
        @apply text-green-200 dark:text-green-200;
      }
    }

    .lock--icon--private {
      @apply text-slate-400 dark:text-slate-400;
    }
  }
}

.left {
  .message-text--metadata {
    .time {
      @apply text-secondary dark:text-secondary-dark;
    }
  }
}

.message-text--metadata {
  @apply items-start flex;

  .time {
    @apply mr-2 block text-xxs leading-[1.8];
  }

  .action--icon {
    @apply mr-2 ml-2 text-slate-900 dark:text-slate-100;
  }

  a {
    @apply text-slate-900 dark:text-slate-100;
  }
}

.activity-wrap {
  .message-text--metadata {
    .time {
      @apply ml-2 rtl:mr-2 rtl:ml-0 flex text-center text-xxs text-secondary dark:text-secondary-dark;
    }
  }
}

.is-image,
.is-video {
  .message-text--metadata {
    .time {
      @apply bottom-1 text-white dark:text-slate-50 absolute right-2 whitespace-nowrap;

      &.has-status-icon {
        @apply right-8 leading-loose;
      }
    }
    .read-tick {
      @apply absolute bottom-2 right-2;
    }
  }
}

.is-private {
  .message-text--metadata {
    @apply items-center;

    .time {
      @apply text-slate-400 dark:text-slate-400;
    }

    .icon {
      @apply text-slate-400 dark:text-slate-400;
    }
  }

  &.is-image,
  &.is-video {
    .time {
      position: inherit;
      @apply pl-2.5;
    }
  }
}

.delivered-icon {
  @apply ml-4;
}

.read-indicator-wrap {
  @apply leading-none flex items-center;
}

.comment-status {
  @apply flex items-center mt-1;

  &__item {
    @apply flex items-center w-1/2;

    &:last-child {
      @apply justify-end;
    }
  }

  &__badge {
    @apply w-[12px] h-auto mr-1;
  }

  &__text {
    @apply text-xxs text-secondary dark:text-secondary-dark transition-all ease-in-out duration-200;

    a {
      @apply text-xxs underline text-secondary dark:text-secondary-dark hover:text-primary hover:dark:text-neutral transition-all ease-in-out duration-200;
    }
  }

  &__icon {
    @apply relative w-[16px] h-[16px] text-secondary dark:text-secondary-dark transition-all ease-in-out duration-200 hover:text-primary hover:dark:text-neutral mr-2.5;

    &:last-child {
      @apply mr-0;
    }
  }

  &__icon-item {
    @apply absolute top-[50%] left-[56%] transform -translate-y-1/2 -translate-x-1/2;
  }

  &__icon-item--spinner {
    @apply left-[initial] -translate-x-[0] -right-[3px];
  }
}
</style>
