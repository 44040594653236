<template>
  <div v-if="!isError" class="image message-text__wrap">
    <img
      ref="image"
      :src="url"
      class="rounded-[4px]"
      @click="onClick"
      @error="$emit('error')"
      @load="handleImageLoad"
    />
    <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
      <img :src="url" class="modal-image skip-context-menu" />
    </woot-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      isError: false,
    };
  },
  mounted() {
    this.$refs.image.onerror = () => {
      const image = this.$refs.image;

      if (image) {
        this.isError = true;
        image.style.display = 'none';
        image.style.height = '0';
        image.style.width = '0';
      }
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
    handleImageLoad() {
      const image = this.$refs.image;
      // get image width to decided it is portrait or landscape
      this.$emit('load', {
        width: image.naturalWidth,
      });
    },
  },
};
</script>
