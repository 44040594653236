/* global axios */
import ApiClient from './ApiClient';

class ShopContactAgentSalesApi extends ApiClient {
  constructor() {
    super('shop_contact_agent_sales', { accountScoped: true });
  }

  get() {
    // TODO: in case we want to get a list of sales
  }

  getAgentRevenueReport({ from, to }) {
    return axios.get(`${super.baseUrl()}/agent_revenue_report?from=${from}&to=${to}`);
  }
}

export default new ShopContactAgentSalesApi();
