<template>
  <div :class="copyClass">
    <div class="copy-content__body">
      {{ content }}
    </div>
    <div
      v-tooltip.top="$t('INBOX_MGMT.COPY.TEXT')"
      class="copy-content__foot"
      @click="handleCopy(content)"
    >
      <fluent-icon icon="copy-new" type="solid" size="20" />
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  name: 'CopyContent',
  mixins: [alertMixin],
  props: {
    content: {
      type: String,
      default: '',
    },
    asField: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    copyClass() {
      let baseClass = ['copy-content'];

      if (this.asField) {
        baseClass.push('copy-content--as-field');
      }

      if (this.fullWidth) {
        baseClass.push('copy-content--full-width');
      }

      return baseClass;
    },
  },
  methods: {
    async handleCopy(val) {
      await copyTextToClipboard(val);
      this.showAlert(this.$t('INBOX_MGMT.COPY.SUCCESS'));
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-content {
  @apply flex items-start gap-2;

  &__body {
    @apply max-w-[250px] break-words text-primary dark:text-primary-dark;
  }
  &__foot {
    @apply text-neutral-high dark:text-neutral-dark-high transition-all ease-in-out duration-200 cursor-pointer flex justify-end relative top-[1px];

    &:hover {
      @apply text-accent;
    }
  }

  // used inside /settings/general to copy the acountId
  &--as-field {
    .copy-content__body {
      @apply border border-slate-200 dark:border-slate-600 p-2 rounded-[0.3125rem] bg-secondary-low dark:bg-secondary-dark-low cursor-not-allowed select-none font-mono;
    }

    .copy-content__foot {
      @apply text-accent dark:text-accent-dark;
    }
  }

  &--full-width {
    .copy-content__body {
      @apply max-w-[initial] w-full;
    }
  }
}
</style>
