<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <div class="flex flex-col h-[calc(100%-100px)]">
      <div class="w-[400px] px-2 m-auto">
        <dns-card>
          <template #content>
            <div class="mb-6 text-center">
              <div class="text-success dark:text-success-dark mb-4">
                <fluent-icon
                  icon="checkmark-circle"
                  type="solid"
                  size="48"
                  view-box="0 0 16 16"
                  class="mx-auto"
                />
              </div>
              <div
                class="text-xl text-primary dark:text-primary-dark mb-2 font-medium"
              >
                {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.FINISH.TITLE') }}
              </div>
              <div class="text-sm text-secondary dark:text-secondary mb-4">
                {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.FINISH.DESC') }}
              </div>
            </div>
          </template>
          <template #action>
            <div class="flex items-center justify-center gap-2.5">
              <router-link :to="inboxUrl">
                <woot-button is-expanded>
                  {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.BACK') }}
                </woot-button>
              </router-link>
              <router-link :to="moreUrl">
                <woot-button is-expanded variant="hollow">
                  {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.MORE') }}
                </woot-button>
              </router-link>
            </div>
          </template>
        </dns-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DnsCard from './components/DnsCard.vue';
import { frontendURL } from '../../../../../../javascript/dashboard/helper/URLHelper';

export default {
  components: {
    DnsCard,
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      inbox: 'inboxes/getInbox',
    }),
    inboxId() {
      return this.$route.params.inbox_id;
    },
    currentInbox() {
      return this.inbox(this.inboxId);
    },
    inboxUrl() {
      return frontendURL(`accounts/${this.accountId}/inbox/${this.currentInbox.id}`);
    },
    moreUrl() {
      return frontendURL(
        `accounts/${this.accountId}/settings/inboxes/${this.currentInbox.id}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .button .button__content {
    text-align: center !important;
  }
}
</style>
