<template>
  <div class="flex flex-col flex-1">
    <section
      class="conversation-page bg-white dark:bg-slate-900"
      :class="bottomSpace"
    >
      <chat-list
        :show-conversation-list="showConversationList"
        :conversation-inbox="inboxId"
        :label="label"
        :team-id="teamId"
        :conversation-type="conversationType"
        :folders-id="foldersId"
        :is-on-expanded-layout="isOnExpandedLayout"
        @conversation-load="onConversationLoad"
      >
        <pop-over-search
          :is-on-expanded-layout="isOnExpandedLayout"
          @toggle-conversation-layout="toggleConversationLayout"
        />
      </chat-list>
      <conversation-box
        v-if="showMessageView"
        :inbox-id="inboxId"
        :is-contact-panel-open="isContactPanelOpen"
        :is-shopify-panel-open="isShopifyPanelOpen"
        :is-on-expanded-layout="isOnExpandedLayout"
        @contact-panel-toggle="onToggleContactPanel"
        @shopify-panel-toggle="onToggleShopifyPanel"
      />
    </section>
    <keyboard-shortcut-hints
      v-if="isShowKeyboard"
      :context="inputContext"
      :is-sidebar-open="isSecondarySidebarOpen"
      :is-in-conversation-lobby="!isChatDetailPage"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ChatList from '../../../components/ChatList.vue';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox.vue';
import PopOverSearch from './search/PopOverSearch.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import wootConstants from 'dashboard/constants/globals';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import * as kb from 'shared/helpers/KeyboardHelpers';
import KeyboardShortcutHints from './KeyboardShortcutHints.vue';

export default {
  components: {
    ChatList,
    ConversationBox,
    PopOverSearch,
    KeyboardShortcutHints,
  },
  mixins: [uiSettingsMixin, eventListenerMixins],
  provide() {
    return {
      uiCommandBus: this.uiCommandBus,
    };
  },
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
    conversationType: {
      type: String,
      default: '',
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      showSearchModal: false,
      uiCommandBus: new Vue(),
      inputContext: 'default',
      isSmallScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
    }),
    showConversationList() {
      return this.isOnExpandedLayout ? !this.conversationId : true;
    },
    showMessageView() {
      return this.conversationId ? true : !this.isOnExpandedLayout;
    },
    isOnExpandedLayout() {
      const {
        LAYOUT_TYPES: { CONDENSED },
      } = wootConstants;
      const { conversation_display_type: conversationDisplayType = CONDENSED } =
        this.uiSettings;
      return conversationDisplayType !== CONDENSED;
    },
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const { is_contact_sidebar_open: isContactSidebarOpen } =
          this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
    isShopifyPanelOpen() {
      if (this.currentChat.id) {
        const { is_shopify_sidebar_open: isShopifySidebarOpen } =
          this.uiSettings;
        return isShopifySidebarOpen;
      }
      return false;
    },
    isChatDetailPage() {
      return Boolean(
        this.$route.params.conversation_id || this.$route.params.conversationId
      );
    },
    isSecondarySidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    bottomSpace() {
      let space = 'pb-0';

      if (
        (this.isChatDetailPage || this.isSmallScreen) &&
        !this.currentUser.hide_kb_hints
      ) {
        space = 'pb-[44px]';
      }

      return space;
    },
    isShowKeyboard() {
      if (!this.currentUser.hide_kb_hints && this.isChatDetailPage) {
        return true;
      }

      if (!this.currentUser.hide_kb_hints && this.isSmallScreen) {
        return true;
      }

      return false;
    },
  },
  watch: {
    conversationId() {
      this.fetchConversationIfUnavailable();
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
    this.uiCommandBus.$on('input-context-reset', () => {
      this.inputContext = 'default';
    });

    this.uiCommandBus.$on('input-context-set', context => {
      this.inputContext = context;
    });

    // handle resize
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    toggleConversationLayout() {
      const { LAYOUT_TYPES } = wootConstants;
      const {
        conversation_display_type:
          conversationDisplayType = LAYOUT_TYPES.CONDENSED,
      } = this.uiSettings;
      const newViewType =
        conversationDisplayType === LAYOUT_TYPES.CONDENSED
          ? LAYOUT_TYPES.EXPANDED
          : LAYOUT_TYPES.CONDENSED;
      this.updateUISettings({
        conversation_display_type: newViewType,
        previously_used_conversation_display_type: newViewType,
      });
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch('getConversation', this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const selectedConversation = this.findConversation();
        // If conversation doesn't exist or selected conversation is same as the active
        // conversation, don't set active conversation.
        if (
          !selectedConversation ||
          selectedConversation.id === this.currentChat.id
        ) {
          return;
        }
        const { messageId } = this.$route.query;
        this.$store
          .dispatch('setActiveChat', {
            data: selectedConversation,
            after: messageId,
          })
          .then(() => {
            bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE, { messageId });
          });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen,
      });
    },
    onToggleShopifyPanel() {
      this.updateUISettings({
        is_shopify_sidebar_open: !this.isShopifyPanelOpen,
      });
    },

    onSearch() {
      this.showSearchModal = true;
    },
    closeSearch() {
      this.showSearchModal = false;
    },
    handleKeyEvents(e) {
      const handleKeyboardShortcut = (evt, command) => {
        evt.preventDefault();
        this.uiCommandBus.$emit('command', command);
      };

      if (!kb.hasPressedModifiers(e)) {
        if (kb.hasPressedArrowDownKey(e))
          handleKeyboardShortcut(e, 'conversation-down');
        else if (kb.hasPressedArrowUpKey(e))
          handleKeyboardShortcut(e, 'conversation-up');
        else if (kb.hasPressedArrowLeftKey(e))
          handleKeyboardShortcut(e, 'conversations-tab-left');
        else if (kb.hasPressedArrowRightKey(e))
          handleKeyboardShortcut(e, 'conversations-tab-right');
        else if (kb.isEnter(e)) handleKeyboardShortcut(e, 'focus-reply-field');
        else if (e.keyCode === 69)
          handleKeyboardShortcut(e, 'resolve-conversation'); // E
        else if (e.keyCode === 72)
          handleKeyboardShortcut(e, 'snooze-conversation'); // H
        else if (e.keyCode === 82) handleKeyboardShortcut(e, 'ai-retry'); // R
        else if (e.keyCode === 73)
          handleKeyboardShortcut(e, 'ai-add-instruction'); // I
        else if (e.keyCode === 83) handleKeyboardShortcut(e, 'ai-skip'); // S
        else if (e.keyCode === 65) handleKeyboardShortcut(e, 'ai-apply'); // A
      } else if (
        kb.hasPressedCommandAndEnter(e) ||
        kb.hasPressedControlAndEnter(e)
      ) {
        handleKeyboardShortcut(e, 'submit-reply');
      }
    },
    handleResize() {
      const { SMALL_SCREEN_BREAKPOINT } = wootConstants;
      this.isSmallScreen = window.innerWidth <= SMALL_SCREEN_BREAKPOINT;
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
