/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Intents extends CacheEnabledApiClient {
  constructor() {
    super('intents', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'intent';
  }
}

export default new Intents();
