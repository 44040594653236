/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class Inboxes extends CacheEnabledApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox';
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  verifyDkimAndReturnPath(inboxId) {
    return axios
      .post(`${this.url}/${inboxId}/verify_dkim_and_return_path`)
      .then(response => response.data);
  }

  async resendSenderConfirmation(inboxId) {
    const response = await axios.post(
      `${this.url}/${inboxId}/resend_sender_confirmation`
    );

    // Check if the response status is 204 or the email address has been confirmed before
    if (response.status === 204) {
      return { status: 204, message: 'No Content' };
    }

    return response.data;
  }

  confirmSenderSignature(inboxId) {
    return axios
      .get(`${this.url}/${inboxId}/confirm_sender_signature`)
      .then(response => response.data);
  }

  setDnsProcessStep(inboxId, lastStep, flowType) {
    return axios.get(
      `${this.url}/${inboxId}/set_dns_process_step?last_step=${lastStep}&flow_type=${flowType}`
    );
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

  getAgentBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/agent_bot`);
  }

  setAgentBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_agent_bot`, {
      agent_bot: botId,
    });
  }
}

export default new Inboxes();
