<template>
  <div v-if="!config.isDefaultScreen" class="footer-wrap">
    <div class="chat-message-input is-focused">
      <input
        :rows="1"
        :aria-label="
          $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.CHAT_INPUT_PLACEHOLDER')
        "
        :placeholder="
          $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.CHAT_INPUT_PLACEHOLDER')
        "
        class="user-message-input is-focused"
      />
      <div class="button-wrap">
        <div class="button-wrap__item">
          <fluent-icon icon="attach" />
        </div>
        <div class="button-wrap__item">
          <fluent-icon icon="emoji" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetFooter',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userInput: '',
    };
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables.scss';

.footer-wrap {
  @apply px-5 w-full flex flex-col relative;

  .chat-message-input {
    @apply bg-white flex items-center rounded-[7px] px-3;

    &.is-focused {
      @apply shadow-focus;
    }
  }

  .button-wrap {
    @apply text-primary flex items-center cursor-pointer pl-2;

    &__item {
      @apply w-8 h-8 flex items-center justify-center;
    }
  }

  .user-message-input {
    @apply bg-neutral text-primary w-full border-0 h-8 py-1 my-2;
  }
}
</style>
