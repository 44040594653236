<template>
  <div class="dns-card">
    <div class="dns-card__wrap">
      <div v-if="title" class="dns-card__head">
        <div class="dns-card__title">
          {{ title }}
        </div>
        <capsule
          v-if="capsuleText"
          class="dns-card__hint"
          :text="capsuleText"
          :variant="capsuleVariant"
        />
      </div>
      <div class="dns-card__body">
        <div v-if="desc" v-dompurify-html="desc" class="dns-card__desc" />
        <slot name="content" />
      </div>
      <div class="dns-card__foot">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
import Capsule from './Capsule';
export default {
  name: 'DnsCard',
  components: {
    Capsule,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    capsuleText: {
      type: String,
      default: '',
    },
    capsuleVariant: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped>
.dns-card {
  @apply h-full shadow-card; // same height size

  &__wrap {
    @apply px-6 pt-6 pb-16 bg-neutral dark:bg-neutral-dark border border-neutral-medium dark:border-neutral-dark-medium h-full relative;
  }

  &__head {
    @apply relative;
  }

  &__hint {
    @apply absolute top-1/2 right-0 transform -translate-y-1/2;
  }

  &__body {
    @apply pb-4;
  }

  &__title {
    @apply text-xl text-primary dark:text-primary-dark mb-2 font-medium;
  }

  &__desc {
    @apply text-sm text-secondary dark:text-secondary mb-4;
  }

  &__foot {
    @apply absolute bottom-6 left-0 w-full px-6;
  }
}
</style>
