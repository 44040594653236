/* global axios */
import ApiClient from './ApiClient';

class GorgiasImporterAPI extends ApiClient {
  constructor() {
    super('gorgias_importer', { accountScoped: true });
  }

  startImport(params) {
    return axios.post(`${this.url}`, params);
  }

  getStatus() {
    return axios.get(`${this.url}/status`)
  }
}

export default new GorgiasImporterAPI();
