import Vue from "vue"

/**
 * AnalyticsHelper class to track user analytics
 * @class AnalyticsHelper
 */
export class AnalyticsHelper {
  /**
   * @constructor
   * @param {Object} [options={}] - options for analytics
   */
  constructor(options = {}) {
  }

  /**
   * Initialize analytics
   * @function
   */
  init(client) {
    this.analytics = client;
  }

  /**
   * Track any event
   * @function
   * @param {string} eventName - event name
   * @param {Object} [properties={}] - event properties
   */
  track(eventName, properties = {}) {
    if (!this.analytics) return;

    this.analytics.capture(eventName, properties);
  }

  /**
   * Track the page views
   * @function
   * @param {Object} params - Page view properties
   */
  page(_name, params) {
    if (!this.analytics) return;

    this.analytics.capture('$pageview', { $current_url: params.href || params.path });
  }

  /**
   * Resets analytics - future events are no longer associated with current user. Typically used on logout.
   * @function
   */
  reset() {
    this.analytics.reset();
  }
}

// This object is shared across, the init is called in app/javascript/packs/application.js
export default new AnalyticsHelper(window.analyticsConfig);
