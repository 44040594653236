import ConversationsAPI from '../../api/conversations';
import types from '../mutation-types';

export const state = {
  generatedIntentFields: {
    title: '',
    description: '',
  },
};

export const getters = {
  getGeneratedIntentFields(_state) {
    return _state.generatedIntentFields;
  },
};

export const actions = {
  async fetchGeneratedIntentFields({ commit }, { conversationId }) {
    try {
      const response =
        await ConversationsAPI.getGeneratedIntentFields(conversationId);

      if (response.status !== 204) {
        commit(types.SET_GENERATED_INTENT_FIELDS, response.data);
      }
    } catch (error) {
      console.error('Error fetching generated intent fields:', error);
      throw error;
    }
  },
};

export const mutations = {
  [types.SET_GENERATED_INTENT_FIELDS](_state, data) {
    _state.generatedIntentFields = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
