<template>
  <div class="outer">
    <span v-if="iconKey === 'esc'">esc</span>
    <span v-else-if="iconKey === 'ctrl'">ctrl</span>
    <Icon
      v-else
      size="10"
      :view-box="viewBox"
      :icon="`key-${iconKey}`"
      type="outline"
      :icons="iconLib"
    />
  </div>
</template>

<script>
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/dashboard-icons.json';

export default {
  components: {
    Icon,
  },
  props: {
    iconKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconLib() {
      return icons;
    },
    viewBox() {
      return {
        meta: '0 0 10 10',
        k: '0 0 8 10',
        up: '0 0 9 10',
        down: '0 0 9 10',
        left: '0 0 10 9',
        right: '0 0 10 9',
        return: '0 0 9 7',
        e: '0 0 7 10',
        h: '0 0 8 10',
        slash: '0 0 5 12',
        r: '-1 0 10 10',
        i: '-3 0 10 10',
      }[this.iconKey];
    },
  },
};
</script>

<style lang="scss" scoped>
.outer {
  @apply min-w-[28px] h-[24px] me-2 text-xs rounded-md border border-solid border-secondary text-secondary dark:text-secondary-dark dark:border-secondary-dark dark:bg-primary flex items-center justify-center bg-white;

  &:last-child {
    @apply me-0;
  }

  // Based on SMALL_SCREEN_BREAKPOINT on wootConstants
  @media (max-width: 1024px) {
    @apply min-w-[22px] h-[18px] me-1.5 text-[8px];
  }
}
</style>
