<template>
  <div
    class="flex flex-row overflow-auto p-4 h-full bg-slate-25 dark:bg-slate-800"
  >
    <woot-wizard
      class="hide-for-small-only"
      :global-config="globalConfig"
      :items="items"
    />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  mixins: [globalConfigMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    items() {
      const otherProviderRoutes = [
        'settings_inboxes_email_provider',
        'settings_inboxes_email_provider_auth',
        'settings_inboxes_email_provider_forward',
        'settings_inboxes_email_provider_finish',
      ];

      // AirCall wizard
      if (
        this.$route.params.sub_page === 'aircall' ||
        this.$route.name === 'settings_inboxes_aircall_success'
      ) {
        return this.$t('INBOX_MGMT.CREATE_AIRCALL_FLOW').map(item => ({
          ...item,
          body: this.useInstallationName(
            item.body,
            this.globalConfig.installationName
          ),
        }));
      }

      // Email provider wizard
      if (otherProviderRoutes.includes(this.$route.name)) {
        return this.$t('INBOX_MGMT.CREATE_EMAIL_FLOW').map(item => ({
          ...item,
          body: this.useInstallationName(
            item.body,
            this.globalConfig.installationName
          ),
        }));
      }

      // Default wizard
      return this.$t('INBOX_MGMT.CREATE_FLOW').map(item => ({
        ...item,
        body: this.useInstallationName(
          item.body,
          this.globalConfig.installationName
        ),
      }));
    },
  },
};
</script>
