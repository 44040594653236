<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <forward-to-option />
  </div>
</template>

<script>
import ForwardToOption from './channels/emailChannels/ForwardToOption.vue';

export default {
  components: {
    ForwardToOption,
  },
};
</script>
