<template>
  <div class="training-editor">
    <form @submit.prevent="handleSend">
      <div class="training-editor__body">
        <resizable-text-area
          ref="questionField"
          v-model="question"
          class="training-editor__textarea"
          :class="{
            'is-focused': focusedField === 'questionField',
          }"
          :placeholder="$t('SIDEBAR.TRAINING_GROUND.QUESTION_PLACEHOLDER')"
          @focus="onFocus('questionField')"
          @blur="onBlur"
        />
      </div>
      <div class="training-editor__footer">
        <div class="training-editor__footer-item">
          <input
            ref="emailField"
            v-model="email"
            class="training-editor__input"
            :class="{
              'is-focused': focusedField === 'emailField',
            }"
            type="email"
            name="data"
            :placeholder="$t('SIDEBAR.TRAINING_GROUND.EMAIL_PLACEHOLDER')"
            @focus="onFocus('emailField')"
            @blur="onBlur"
          />
        </div>
        <div class="training-editor__footer-item">
          <woot-button class="button" :is-disabled="isDisabled">
            {{ $t('SIDEBAR.TRAINING_GROUND.SEND_TEXT') }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ResizableTextArea from 'shared/components/ResizableTextArea.vue';
import emailValidator from 'vuelidate/lib/validators/email';

export default {
  components: {
    ResizableTextArea,
  },
  props: {},
  data() {
    return {
      question: '',
      email: '',
      focusedField: null,
    };
  },
  computed: {
    isDisabled() {
      return !this.question || !this.email || !this.validateEmail();
    },
  },
  methods: {
    validateEmail() {
      return emailValidator(this.email);
    },
    onFocus(fieldName) {
      this.focusedField = fieldName;
    },
    onBlur() {
      this.focusedField = null;
    },
    handleSend() {
      if (this.isDisabled) {
        return;
      }

      const value = {
        id: '2',
        content: this.question,
        created_at: Math.floor(Date.now() / 1000),
        message_type: 0,
        sender: {
          id: '1',
          email: this.email,
          name: 'John',
          available_name: 'John',
          avatar_url: '',
          type: 'user',
          thumbnail: '',
        },
      };

      this.$emit('send', value);

      // ux stuff
      this.question = '';
      this.email = '';
      this.$refs.questionField.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.training-editor {
  @apply p-4 bg-white dark:bg-primary rounded-xl overflow-hidden border border-secondary-medium dark:border-neutral-dark-medium mx-4 mb-8;

  &__footer {
    @apply flex items-center border-t border-secondary-medium dark:border-neutral-dark-medium pt-5 mt-5;

    &-item {
      @apply flex items-center w-1/2;
    }

    &-item:last-child {
      @apply justify-end;
    }
  }

  &__input {
    @apply m-0 border border-secondary-medium dark:border-neutral-dark-medium text-sm;

    &:focus {
      @apply transition-all duration-100 ease-in;
    }
  }

  &__textarea {
    @apply w-full bg-white dark:bg-primary min-h-[182px] max-h-[182px] resize-none text-sm p-0 px-2 pt-2 border border-transparent mb-0 transition-all duration-100 ease-in;
  }

  .is-focused {
    @apply border-accent dark:border-accent-dark;
  }
}
</style>
