<template>
  <div class="flex flex-col flex-1 p-4 overflow-y-auto">
    <report-filter-selector
      :show-agents-filter="false"
      :show-inbox-filter="false"
      :show-rating-filter="false"
      :show-team-filter="false"
      :show-business-hours-switch="false"
      @filter-change="onFilterChange"
    />

    <div class="bg-white dark:bg-slate-900 border border-solid border-1 border-black-200 dark:border-black-900 p-3">
      <h5 class="mb-3 text-slate-900 dark:text-slate-100">{{ $t('AGENT_REVENUE_REPORT.TABLE_HEADING') }}</h5>

      <ve-table
        :fixed-header="true"
        :columns="columns"
        :table-data="agentRevenueReportCurrentPage"
        :sort-option="sortOption"
        :border-around="false"
      />

      <div v-if="uiFlags.isFetching" class="text-center my-24">
        <spinner color-scheme="primary" />
      </div>

      <p v-else-if="noDataAvailable"
        class="text-slate-500 dark:text-slate-100 text-center my-24"
      >
        {{ $t('AGENT_REVENUE_REPORT.NO_RESULTS_MESSAGE') }}
      </p>

      <div v-if="!noDataAvailable" class="table-pagination">
        <ve-pagination
          :total="agentRevenueReport.length"
          :page-index="pageIndex"
          :page-size="pageSize"
          :layout="['total', 'prev', 'pager', 'next', 'jumper']"
          @on-page-number-change="onPageNumberChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable, VePagination } from 'vue-easytable';
import getSymbolFromCurrency from 'currency-symbol-map'
import rtlMixin from 'shared/mixins/rtlMixin';
import ReportFilterSelector from './components/FilterSelector.vue';
import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { AGENT_REVENUE_REPORT_DATE_RANGE_OPTIONS } from './constants';

const ROWS_PER_PAGE = 20;

export default {
  components: {
    ReportFilterSelector,
    VeTable,
    VePagination,
    Spinner,
  },
  mixins: [rtlMixin],
  data() {
    return {
      filter: {
        from: 0,
        to: 0,
      },
      pageIndex: 0,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      sortField: 'total_sales',
      sortOrder: 'desc',
    };
  },
  provide() {
    return {
      dateRangeOptions: AGENT_REVENUE_REPORT_DATE_RANGE_OPTIONS,
    };
  },
  computed: {
    ...mapGetters({
      agentRevenueReport: 'shopContactAgentSales/getAgentRevenueReport',
      syncedAt: 'shopContactAgentSales/syncedAt',
      uiFlags: 'shopContactAgentSales/getUIFlags',
    }),
    noDataAvailable() {
      return this.agentRevenueReport.reduce((sum, agent) => sum + agent.num_orders, 0) === 0;
    },
    columns() {
      return [
        {
          field: 'agent',
          key: 'agent.id',
          title: this.$t('AGENT_REVENUE_REPORT.COLUMNS.AGENT'),
          align: this.isRTLView ? 'right' : 'left',
          fixed: 'left',
          sortBy: '',
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <Thumbnail
                src={row.agent.avatar_url}
                size="32px"
                username={row.agent.name}
              />
              <div class="user-block">
                <h6 class="title overflow-hidden whitespace-nowrap text-ellipsis">
                  {row.agent.name}
                </h6>
                <span class="sub-title">{row.agent.email}</span>
              </div>
            </div>
          ),
        },
        {
          field: 'num_conversations',
          key: 'num_conversations',
          sortBy: '',
          title: this.$t('AGENT_REVENUE_REPORT.COLUMNS.NUM_CONVERSATIONS'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            return row.num_conversations || '-';
          },
        },
        {
          field: 'num_orders',
          key: 'num_orders',
          sortBy: '',
          title: this.$t('AGENT_REVENUE_REPORT.COLUMNS.NUM_ORDERS'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            return row.num_orders || '-';
          },
        },
        {
          field: 'total_sales',
          key: 'total_sales',
          sortBy: '',
          title: this.$t('AGENT_REVENUE_REPORT.COLUMNS.TOTAL_SALES'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.total_sales === null) return '-';

            return `${getSymbolFromCurrency(row.currency_code) || row.currency_code + ' '}${Math.floor(row.total_sales)}`;
          },
        },
        {
          field: 'conversion_rate',
          key: 'conversion_rate',
          sortBy: '',
          title: this.$t('AGENT_REVENUE_REPORT.COLUMNS.CONVERSION_RATE'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            if (row.num_conversations === 0 || row.num_orders === 0 || row.conversion_rate === null)
              return '-';

            return Math.floor(row.conversion_rate) + '%';
          },
        },
      ];
    },
    pageSize() {
      return ROWS_PER_PAGE;
    },
    sortedAgentRevenueReport() {
      if (this.sortField === 'agent') {
        if (this.sortOrder === 'asc') return this.agentRevenueReport.sort((a, b) => { return a.agent.name < b.agent.name ? -1 : 1; });
        else return this.agentRevenueReport.sort((a, b) => { return a.agent.name > b.agent.name ? -1 : 1; });
      } else {
        return this.agentRevenueReport.sort((a, b) => {
          if (this.sortOrder === 'asc')
            return a[this.sortField] < b[this.sortField] ? -1 : 1;
          else
            return a[this.sortField] > b[this.sortField] ? -1 : 1;
        });
      }
    },
    agentRevenueReportCurrentPage() {
      if (this.uiFlags.isFetching || this.noDataAvailable) return [];

      const start = this.pageIndex * this.pageSize;
      const end = start + this.pageSize;

      return (this.sortedAgentRevenueReport || []).slice(start, end);
    },
  },
  methods: {
    fetchReport() {
      this.$store.dispatch('shopContactAgentSales/fetchReportForAccount', this.filter);
    },
    onFilterChange(filter) {
      this.filter = filter;
      this.fetchReport();
    },
    onPageNumberChange(n) {
      this.pageIndex = n - 1;
    },
    sortChange(params) {
      this.sortField = Object.keys(params).find(key => params[key] !== '');
      this.sortOrder = params[this.sortField];
    },
  },
};
</script>

<style lang="scss" scoped>
.ve-table {
  &::v-deep {
    .ve-table-container {
      @apply overflow-y-visible #{!important};
    }

    table {
      @apply border-collapse #{!important};

      &, th, tr {
        @apply border border-solid border-stategrey dark:border-black-900;
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }

      .ve-table-sort {
        position: absolute !important;
        right: 16px;
        transform: translateY(-30%);
      }

      tbody {
        @apply border-none #{!important};

        tr:first-child {
          display: none;
        }

        tr {
          @apply border-l border-r border-solid border-stategrey dark:border-black-900 #{!important};
        }
      }
    }
  }
}

.ve-pagination {
  @apply bg-transparent dark:bg-transparent;
}

.row-user-block {
  @apply items-center flex text-left;

  .user-block {
    @apply items-start flex flex-col min-w-0 my-0 mx-2;

    .title {
      @apply text-sm m-0 leading-[1.2] text-slate-800 dark:text-slate-100;
    }
    .sub-title {
      @apply text-xs text-slate-600 dark:text-slate-200;
    }
  }
}

.table-pagination {
  @apply mt-4 text-right;
}
</style>
