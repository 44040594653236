<template>
  <div class="rounded-md p-2 outer mb-1" :class="{ outgoing: isOutgoing }">
    <audio
      ref="audio"
      :src="contentUrl"
      @durationchange="duration = $refs.audio.duration"
      @ended="playing = false"
      @loadstart="loading = true"
      @loadeddata="loading = false"
      @waiting="loading = true"
      @playing="loading = false; playing = true"
      @canplay="onCanPlay"
    ></audio>

    <div class="flex flex-row items-center">
      <div v-if="loading" class="spinner p-0" @click="loading = false"></div>

      <a v-else-if="!playing" href="#play" @click.prevent="onPlayButtonClicked" class="hover-button">
        <Icon size="10" icon="play" type="outline" :icons="iconLib" view-box="0 0 8 10" />
      </a>

      <a v-else href="#pause" @click.prevent="pause()" class="hover-button">
        <Icon size="10" icon="pause" type="outline" :icons="iconLib" view-box="0 0 16 16" />
      </a>

      <div class="ms-3 me-2 font-mono control">{{ moment.utc(this.time * 1000).format('mm:ss') }}</div>

      <div
        @click="onProgressBarClicked"
        class="h-[8px] w-[100px] rounded progressbar overflow-hidden cursor-pointer"
      >
        <div
          class="bg-control h-full progress"
          :style="{ width: (time / duration) * 100 + '%' }">
        </div>
      </div>

      <div class="ms-2 me-2 font-mono control">-{{ moment.utc((this.duration - this.time) * 1000).format('mm:ss') }}</div>

      <a href="#" @click.prevent="toggleMuted()" class="hover-button">
        <Icon size="16" :icon="muted ? 'volume-off' : 'volume-on'" type="outline" :icons="iconLib" view-box="0 0 16 16" />
      </a>

      <a v-if="contentUrl" ref="download" :href="contentUrl" target="_blank" class="ms-2 hover-button">
        <Icon size="16" icon="attachment-download" type="outline" :icons="iconLib" view-box="0 0 16 16" />
      </a>
      <a v-else @click.prevent="downloadRemoteMedia" href="#download" class="ms-2 hover-button">
        <Icon size="16" icon="attachment-download" type="outline" :icons="iconLib" view-box="0 0 16 16" />
      </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/icons.json';
import MessageAPI from 'dashboard/api/inbox/message.js';
import { MESSAGE_TYPE } from '../../widget/helpers/constants';

export default {
  components: {
    Icon,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    src: {
      type: String,
    },
  },
  data() {
    return {
      time: 0,
      duration: 0,
      muted: false,
      playing: false,
      loading: false,
      contentUrl: this.src,
    };
  },
  computed: {
    iconLib() {
      return icons;
    },
    moment() {
      return moment;
    },
    filename() {
      if (!this.message || !this.message.call_ended_at) return 'recording.mp3';

      return moment(this.message.call_ended_at).format('YYYY-MM-DD_HH:mm:ss') + '.mp3';
    },
    isOutgoing() {
      return this.message.message_type === MESSAGE_TYPE.OUTGOING;
    },
  },
  methods: {
    toggleMuted() {
      this.muted = !this.muted;
      if (this.$refs.audio) this.$refs.audio.muted = this.muted;
    },
    isRemoteMedia() {
      return this.message.id;
    },
    async play() {
      this.$refs.audio.play();
      this.startInteval();
    },
    pause() {
      this.playing = false;
      this.$refs.audio.pause();
      clearInterval(this.playbackInterval);
    },
    startInteval() {
      this.playbackInterval = setInterval(this.updateProgress, 333);
    },
    updateProgress() {
      this.time = this.$refs.audio?.currentTime;
    },
    onProgressBarClicked(event) {
      const target = event.target.classList.contains('progress') ? event.target.parentNode : event.target;

      if (this.$refs.audio?.duration) {
        this.time = (event.offsetX / target.offsetWidth) * this.$refs.audio.duration;
        this.$refs.audio.currentTime = this.time;
      }
    },
    onPlayButtonClicked() {
      if (this.isRemoteMedia && !this.contentUrl)
        this.playRemoteMedia();
      else
        this.play();
    },
    async fetchContentUrl() {
      this.loading = true;
      const response = await MessageAPI.getRemoteMediaURL(this.message.conversation_id, this.message.id);
      this.contentUrl = response.data.url;
    },
    async playRemoteMedia() {
      await this.fetchContentUrl();
      this.play();
    },
    async downloadRemoteMedia() {
      await this.fetchContentUrl();
      this.$refs.download.click();
    },
    onCanPlay(event) {
      if (this.playing) this.$refs.audio.play();
    },
  },
};
</script>

<style lang="scss" scoped>
  a, .control {
    color: #868E96 !important;
  }

  .bg-control {
    @apply bg-secondary;
  }

  .outer {
    @apply bg-neutral-subtle dark:bg-[#1C1E20];

    &.outgoing {
      @apply bg-[#51ACFF] dark:bg-[#1C1E20];
    }
  }

  .hover-button {
    @apply p-2 rounded-md;

    &:hover {
      @apply bg-secondary-low text-secondary-dark dark:bg-secondary-dark-medium;
    }
  }

  .progressbar {
    @apply bg-[#DEE2E6] dark:bg-[#2B2B2B];
  }

  .outgoing {
    a, .control {
      @apply text-white dark:text-secondary #{!important};
    }

    .bg-control {
      @apply bg-white dark:bg-secondary;
    }

    .hover-button:hover {
      @apply bg-[#78BEFF] dark:bg-secondary-dark-medium text-white;
    }

    .progressbar {
      @apply bg-[#76B9FD] dark:bg-[#2B2B2B];
    }
  }

  .spinner {
    max-height: 26px;
    margin-right: 2px;

    &::before {
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
    }
  }
</style>
