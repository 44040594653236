const state = {
  featureFlags: {}
};

export const getters = {
  getFeatureFlags(_state) {
    return new Proxy(
      _state,
      {
        get: function (target, prop) {
          if (window.chatwootConfig.posthogFeatureFlagsAlwaysOn === 'true') return true;
          else return target.featureFlags[prop];
        },
      }
    );
  },
};

export const actions = {};

export const mutations = {
  setFeatureFlags: (_state, newFeatureFlags) => {
    if (newFeatureFlags) _state.featureFlags = newFeatureFlags;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
