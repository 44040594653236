<template>
  <div v-if="isATwilioChannel" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE')"
      :sub-title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE')"
    >
      <copy-content full-width as-field :content="inbox.callback_webhook_url" />
    </settings-section>
  </div>
  <div v-else-if="isALineChannel" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.TITLE')"
      :sub-title="$t('INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE')"
    >
      <copy-content full-width as-field :content="inbox.callback_webhook_url" />
    </settings-section>
  </div>
  <div v-else-if="isAPIInbox" class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT')"
    >
      <copy-content full-width as-field :content="inbox.inbox_identifier" />
    </settings-section>

    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION')"
    >
      <copy-content full-width as-field :content="inbox.hmac_token" />
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_VERIFICATION')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_MANDATORY_DESCRIPTION')"
    >
      <div class="enter-to-send--checkbox">
        <input
          id="hmacMandatory"
          v-model="hmacMandatory"
          type="checkbox"
          @change="handleHmacFlag"
        />
        <label for="hmacMandatory">
          {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }}
        </label>
      </div>
    </settings-section>
  </div>
  <div v-else-if="isAnEmailChannel || isAnGmailChannel || isAWebWidgetInbox">
    <div v-if="isAnEmailChannel" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.FORWARD_EMAIL_SUB_TEXT')"
      >
        <copy-content full-width as-field :content="inbox.forward_to_email" />
      </settings-section>
    </div>
    <imap-settings v-if="false" :inbox="inbox" />
    <smtp-settings v-if="inbox.imap_enabled" :inbox="inbox" />
    <microsoft-reauthorize
      v-if="inbox.microsoft_reauthorization"
      :inbox="inbox"
    />
    <div class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CSAT')"
        class="items-center"
      >
        <select v-model="csatSurveyEnabled">
          <option :value="true">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.ENABLED') }}
          </option>
          <option :value="false">
            {{ $t('INBOX_MGMT.EDIT.ENABLE_CSAT.DISABLED') }}
          </option>
        </select>
      </settings-section>

      <settings-section
        v-if="csatSurveyEnabled"
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.CSAT_MESSAGE')"
        class="items-center"
      >
        <editor
          v-model="csatMessage"
          class="input message-editor"
          :min-height="5"
          :enable-variables="true"
          :variables="messageVariables"
          :allow-signature="false"
          :channel-type="inbox.channel_type"
          :enable-suggestions="true"
          signature=""
        />
      </settings-section>

      <settings-section title=" ">
        <woot-button @click="updateInbox">
          {{
            $t(
              'INBOX_MGMT.EDIT.SENDER_NAME_SECTION.BUSINESS_NAME.SAVE_BUTTON_TEXT'
            )
          }}
        </woot-button>
      </settings-section>
    </div>
  </div>
  <div v-else-if="isAWhatsAppChannel && !isATwilioChannel">
    <div v-if="inbox.provider_config" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_SUBHEADER')"
      >
        <copy-content
          full-width
          as-field
          :content="inbox.provider_config.webhook_verify_token"
        />
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_SUBHEADER')"
      >
        <copy-content
          full-width
          as-field
          :content="inbox.provider_config.api_key"
        />
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_TITLE')"
        :sub-title="
          $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_SUBHEADER')
        "
      >
        <div class="whatsapp-settings--content">
          <woot-input
            v-model.trim="whatsAppInboxAPIKey"
            type="text"
            class="input"
            :placeholder="
              $t(
                'INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_PLACEHOLDER'
              )
            "
          />
          <woot-button
            :disabled="$v.whatsAppInboxAPIKey.$invalid"
            @click="updateWhatsAppInboxAPIKey"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_SECTION_UPDATE_BUTTON') }}
          </woot-button>
        </div>
      </settings-section>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from 'shared/mixins/inboxMixin';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import ImapSettings from '../ImapSettings.vue';
import SmtpSettings from '../SmtpSettings.vue';
import MicrosoftReauthorize from '../channels/microsoft/Reauthorize.vue';
import { required } from 'vuelidate/lib/validators';
import CopyContent from '../components/CopyContent';
import Editor from 'dashboard/components/widgets/WootWriter/Editor.vue';

export default {
  components: {
    SettingsSection,
    ImapSettings,
    SmtpSettings,
    MicrosoftReauthorize,
    CopyContent,
    Editor,
  },
  mixins: [inboxMixin, alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hmacMandatory: false,
      whatsAppInboxAPIKey: '',
      csatSurveyEnabled: false,
      csatMessage: '',
    };
  },
  validations: {
    whatsAppInboxAPIKey: { required },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  computed: {
    messageVariables() {
      return Object.fromEntries([
        'contact.name',
        'contact.first_name',
        'contact.last_name',
        'contact.email',
        'contact.phone',
        'contact.id',
        'conversation.id',
        'agent.name',
        'agent.first_name',
        'agent.last_name',
        'agent.email',
        'order.id',
        'order.name',
        'order.created_at',
        'order.tracking_url',
        'order.tracking_number',
        'order.delivery_status',
        'order.status_url',
        'order.shipping_date',
        'order.country',
        'order.shipping_address',
      ].map(variable => [variable, '']));
    },
  },
  methods: {
    setDefaults() {
      this.hmacMandatory = this.inbox.hmac_mandatory || false;
      this.csatSurveyEnabled = this.inbox.csat_survey_enabled || false;
      this.csatMessage = this.inbox.csat_message;
    },
    handleHmacFlag() {
      this.updateInbox();
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          csat_survey_enabled: this.csatSurveyEnabled,
          csat_message: this.csatMessage,
          channel: {
            hmac_mandatory: this.hmacMandatory,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async updateWhatsAppInboxAPIKey() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          channel: {},
        };

        payload.channel.provider_config = {
          ...this.inbox.provider_config,
          api_key: this.whatsAppInboxAPIKey,
        };

        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.whatsapp-settings--content {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: var(--space-small);

  .input {
    flex: 1;
    margin-right: var(--space-small);
    ::v-deep input {
      margin-bottom: 0;
    }
  }
}

.settings--content {
  @apply mx-0;

  & > :last-child {
    border-bottom: none;
  }
}

.settings--content:first-child {
  margin-top: 0;

  & > *:first-child {
    padding-top: 0;
  }
}

.settings--content:last-child {
  border-bottom: none;
}

.hmac-link-to-docs {
  margin-top: var(--space-small);
}

.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px];
    }
  }
}
</style>
