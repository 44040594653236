<template>
  <div class="mb-2 flex items-center" :class="{ outgoing: isOutgoing }">
    <div class="bg-call-icon rounded-md p-2 me-2">
      <Icon
        :icon="icon"
        :icons="iconLib"
        size="24"
        type="outline"
        :view-box="isVoicemail ? '0 0 14 7' : '0 0 20 20'"
        :class="{ [icon]: true }"
      />
    </div>

    <div>
      <div class="font-medium">{{ callHeading }}</div>
      <div class="flex items-center text-xs subheading">
        <div>{{ callSubHeading }}</div>

        <span class="dot" v-if="showCallBackLink"></span>
        <a v-if="showCallBackLink" :href="callContactUrl" target="_blank" class="flex items-center text-xs">
          <Icon
            icon="call-incoming"
            :icons="iconLib"
            type="outline"
            view-box="0 0 20 20"
            size="10"
            class="inline-block me-1"
          />

          {{ $t('CONVERSATION.CALL_RECORDINGS.CALL_BACK') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import icons from "../../../../../shared/components/FluentIcon/icons.json";
import Icon from "../../../../../shared/components/FluentIcon/Icon";
import { MESSAGE_TYPE } from '../../../../../widget/helpers/constants';

const CALL_RECORDING = 'call_recording';
const VOICEMAIL = 'voicemail';
const MISSED_CALL = 'missed_call';

export default {
  components: { Icon },
  props: {
    data: {
      Object,
      required: true,
    }
  },
  computed: {
    icon() {
      return {
        [CALL_RECORDING]: {
          [MESSAGE_TYPE.INCOMING]: 'call-incoming',
          [MESSAGE_TYPE.OUTGOING]: 'call-outgoing',
        },
        [MISSED_CALL]: {
          [MESSAGE_TYPE.INCOMING]: 'call-missed',
          [MESSAGE_TYPE.OUTGOING]: 'call-missed',
        },
        [VOICEMAIL]: {
          [MESSAGE_TYPE.INCOMING]: 'voicemail',
          [MESSAGE_TYPE.OUTGOING]: 'voicemail',
        },
      }[this.data.content_type][this.data.message_type];
    },
    iconLib() {
      return icons;
    },
    callHeading() {
      return {
        [CALL_RECORDING]: this.$t('CONVERSATION.CALL_RECORDINGS.VOICE_CALL'),
        [VOICEMAIL]: this.$t('CONVERSATION.CALL_RECORDINGS.VOICEMAIL'),
        [MISSED_CALL]: this.$t('CONVERSATION.CALL_RECORDINGS.VOICE_CALL'),
      }[this.data.content_type];
    },
    callDuration() {
      if (this.data.content_type === CALL_RECORDING || this.data.content_type === VOICEMAIL) {
        return moment(this.data.call_ended_at) - moment(this.data.call_started_at);
      }
    },
    formattedDuration() {
      if (this.callDuration >= 1 * 60 * 60 *  1000) {
        return moment.utc(this.callDuration).format('HH:mm:ss');
      } else {
        return moment.utc(this.callDuration).format('mm:ss');
      }
    },
    callSubHeading() {
      if (this.data.content_type === MISSED_CALL) {
        if (this.data.message_type === MESSAGE_TYPE.INCOMING) {
          return this.$t('CONVERSATION.CALL_RECORDINGS.MISSED_CALL');
        } else {
          return this.$t('CONVERSATION.CALL_RECORDINGS.NO_ANSWER');
        }
      } else if (this.callDuration) {
        return this.formattedDuration;
      }
    },
    callContactUrl() {
      if (this.data.source_id) {
        const [call_id, number_id] = this.data.source_id.split('.');
        return `https://phone.aircall.io/calls/${call_id}/numbers/${number_id}`;
      }
    },
    isOutgoing() {
      return this.data.message_type === MESSAGE_TYPE.OUTGOING;
    },
    showCallBackLink() {
      return this.data.message_type === MESSAGE_TYPE.INCOMING && this.data.content_type === MISSED_CALL;
    },
    isVoicemail() {
      return this.data.content_type === VOICEMAIL;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .call-missed {
    color: #CD2B31;
  }

  .call-incoming, .call-outgoing, .voicemail {
    @apply text-[#151718] dark:text-[#D7DBDF];
  }

  .bg-call-icon {
    @apply bg-magnolia dark:bg-[#1C1E20];
  }

  .subheading {
    @apply text-secondary;
  }

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    @apply mx-2 inline-block bg-secondary;
  }

  .outgoing {
    .call-missed {
      color: #CD2B31;
    }

    .call-incoming, .call-outgoing, .voicemail {
      @apply text-white;
    }

    .bg-call-icon {
      @apply bg-[#51ACFF] dark:bg-[#1C1E20];
    }

    .subheading {
      @apply text-white dark:text-secondary;
    }
  }
</style>
