/* global axios */
import ApiClient from '../ApiClient';

class GMailChannel extends ApiClient {
  constructor() {
    super('google_indicators', { accountScoped: true });
  }

  create(params) {
    return axios.post(
      `${this.url.replace(this.resource, '')}callbacks/register_gmail_account`,
      params
    );
  }

  reauthorizeGMail(params) {
    return axios.post(`${this.baseUrl()}/callbacks/reauthorize_gmail`, params);
  }
}

export default new GMailChannel();
